import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilPuzzle, cilSpeedometer } from '@coreui/icons'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

import User from '@views/privileges/User'
import UserForm from '@views/privileges/User/Form'
import Role from '@views/privileges/Role'
import Permission from '@views/privileges/Permission'
import PermissionCreate from '@views/privileges/Permission/Form'
import Address from '@views/privileges/Address'
import ErrorLog from '@views/privileges/ErrorLog'

const route = [
    {
        name: 'Privileges',
        to: '/privileges',
        icon: <FaIcons.FaCogs className="nav-icon" />,
        dev: true,
        items: [
            {
                to: '/privileges-user',
                name: 'User',
                component: User,
                exact: true,
                actions: [
                    {
                        to: '/privileges-user/create',
                        name: 'Create',
                        component: UserForm,
                        exact: true
                    },
                    {
                        to: '/privileges-user/:id/edit',
                        name: 'Edit',
                        component: UserForm,
                        exact: true
                    }
                ]
            },
            {
                to: '/privileges-role',
                name: 'Role',
                component: Role,
                exact: true,
            },
            {
                to: '/privileges-permission',
                name: 'Permission',
                component: Permission,
                exact: true,
                actions: [
                    {
                        to: '/privileges-permission/create',
                        name: 'Create',
                        component: PermissionCreate,
                        exact: true
                    }
                ]
            },
            {
                to: '/privileges-error-log',
                name: 'Error Log',
                component: ErrorLog,
                exact: true,
            },
            {
                to: '/privileges-address',
                name: 'Address',
                component: Address,
                exact: true,
            },
        ],
    },
]

export default route
