import Axios from 'axios'
import CryptoJS from 'crypto-js'
import store from '@src/store'
import { osName, osVersion } from "react-device-detect";
import XsrfService from './xsrf.service';

const api_url = window.location.origin+'/api/';

const login = async (data = {}) => {
    const { auth } = store.getState();

    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;

    return await Axios.post(api_url + "login", { ...data, device: osName+' '+osVersion })
    .then((response) => {
        const token = CryptoJS.AES.encrypt(JSON.stringify(response.data), auth.keys).toString()

        XsrfService.setItem(auth.xsrf, token)

        return token;
    });
};

const logout = async () => {
    const { auth } = store.getState();

    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;

    return await Axios.post(api_url + "logout", {}, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => {
        XsrfService.removeItem(auth.xsrf)

        return response;
    });
};

export default { login, logout };
