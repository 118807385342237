import React, { useEffect, useState } from 'react'
import { CFormFeedback, CFormLabel } from '@coreui/react';
import Select from 'react-select';

const RFormSelect = ({ options, value, setValue, invalid }) => {
    const { id, label, value: thisValue, required, requiredLabel, disabled, ...props } = value
    const setRequiredLabel = requiredLabel ?? true

    const [option, setOption] = useState(options)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(false)
        setOption(options)
    }, [options])

    useEffect(() => {
        if (disabled !== undefined) {
            if (!disabled) {
                setLoading(true)
            }
        }
    }, [disabled])

    const styles = {
        control: (provided) => {
            return {
                ...provided,
                backgroundColor: disabled ? '#b1b7c1' : 'white',
                minHeight: '29px',
                borderColor: invalid[id] ? '#e55353' : '#b1b7c1',
                boxShadow: null,
                borderRadius: '0.2rem',
                ':hover': {
                    ...provided[':hover'],
                    borderColor: invalid[id] ? '#e55353' : '#b1b7c1',
                },
            }
        },
        option: (provided, {options, innerProps, ...state}) => {
            return {
                ...provided,
                padding: '0.25rem 0.5rem',
                borderBottom: 'solid 1px #b1b7c1',
                fontSize: '0.875rem'
            };
        },
        valueContainer: (provided, state) => {
            return {
                ...provided,
                padding: '0.25rem 0.5rem',
            }
        },
        indicatorsContainer: (provided, state) => {
            return {
                ...provided,
                padding: 0,
            }
        },
        dropdownIndicator: (provided, state) => {
            return {
                ...provided,
                padding: '0 0.25rem',
            }
        },
        clearIndicator: (provided, state) => {
            return {
                ...provided,
                padding: '0 0.25rem',
            }
        },
        singleValue: (provided, state) => {
            return {
                ...provided,
                fontSize: '0.875rem',
            }
        },
        input: (provided, state) => {
            return {
                ...provided,
                padding: 0,
                margin: 0,
                fontSize: '0.875rem'
            }
        },
        placeholder: (provided, state) => {
            return {
                ...provided,
                color: disabled ? 'white' : provided.color,
                fontSize: '0.875rem'
            }
        },
        menu: (provided, state) => {
            return {
                ...provided,
                margin: 0,
                // borderRadius: '0.2rem 0.2rem 0 0'
            }
        },
        menuList: (provided, state) => {
            return {
                ...provided,
                // paddingBottom: 0,
            }
        },
        noOptionsMessage: (provided) => {
            return {
                ...provided,
                padding: "3px 0",
                color: '#000',
                fontSize: '0.875rem'
            }
        },
    };

    return (
        <>
            <CFormLabel className='mb-0' htmlFor={id ?? ''}>{label}{required && setRequiredLabel ? <span className="text-danger"> *</span> : ''}</CFormLabel>
            <Select
                maxMenuHeight={135}
                menuPlacement="auto"
                isClearable
                isDisabled={disabled ?? false}
                isLoading={loading}
                styles={styles}
                options={option}
                value={thisValue}
                noOptionsMessage={() => "Tidak ada pilihan"}
                onChange={(e) => {
                    setValue({
                        ...value,
                        value: e
                    })
                }}
                className={invalid[id] ? 'is-invalid' : ''}
                {...props}
                />
            <CFormFeedback invalid>{invalid[id]}</CFormFeedback>
        </>
    )
}

export default RFormSelect
