import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCol, CContainer, CRow, CSpinner } from '@coreui/react';

const RLoadingData = ({ children, result }) => {
    const [response, setResponse] = useState(null)

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (result) {
                setResponse(result)
            }
        }
        return () => mounted = false
    }, [result]);

    if (response) {
        if (response.status === 200) {
            return children;
        } else {
            if (response.response) {
                return (
                    <CCard>
                        <CCardBody>
                            <CContainer>
                                <CRow className="justify-content-center">
                                    <CCol xs="auto">
                                        <div className="clearfix">
                                            <h1 className="float-start display-3 me-4">{response.response && response.response.status ? response.response.status  : '404'}</h1>
                                            <h4 className="pt-3">Oops! {response.response.data.message ?? "You're lost"}.</h4>
                                        </div>
                                        <p className="text-medium-emphasis float-start">Halaman yang anda cari tidak ditemukan.</p>
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </CCardBody>
                    </CCard>
                );
            } else {
                return (
                    <CCard>
                        <CCardBody>
                            <CContainer>
                                <CRow className="justify-content-center">
                                    <CCol xs="auto">
                                        <div className="clearfix">
                                            <h1 className="float-start display-3 me-4">{response.status ?? '404'}</h1>
                                            <h4 className="pt-3">Oops! {response.data && response.data.message ? response.data.message : "You're lost"}.</h4>
                                        </div>
                                        <p className="text-medium-emphasis float-start">Halaman yang anda cari tidak ditemukan.</p>
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </CCardBody>
                    </CCard>
                );
            }
        }
    } else {
        return (
            <CCard>
                <CCardBody>
                    <div className="d-flex justify-content-center align-items-center">
                        <CRow>
                            <CCol xs="auto" className='my-5 d-flex align-items-center'>
                                <CSpinner component="span" color="dark" aria-hidden="true" />
                                &nbsp; &nbsp;
                                <span className='text-lg'>Loading ...</span>
                            </CCol>
                        </CRow>
                    </div>
                </CCardBody>
            </CCard>
        )
    }
}

export default RLoadingData
