import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from '@components/base/table'
import { dataGet, dataError } from '@actions/data.service'
import LoadingData from '@components/loading/LoadingData'
import colProvinces from './colProvinces'
import colCities from './colCities'
import colDistricts from './colDistricts'
import colVillages from './colVillages'


const Address = ({ showToastAlert }) => {
    const url = '/privileges-address'
    const paginate = 10

    const [dataProvince, setDataProvince] = useState(null);
    const [dataCity, setDataCity] = useState(null);
    const [dataDistrict, setDataDistrict] = useState(null);
    const [dataVillage, setDataVillage] = useState(null);

    const [attrProvince, setAttrProvince] = useState({
        url : url,
        paginate: paginate,
        reloadTable: (url, paginate) => handleTableProvince(url, paginate)
    });
    const [attrCity, setAttrCity] = useState({
        url : url,
        paginate: paginate,
        reloadTable: (url, paginate) => handleTableCity(url, paginate)
    });
    const [attrDistrict, setAttrDistrict] = useState({
        url : url,
        paginate: paginate,
        reloadTable: (url, paginate) => handleTableDistrict(url, paginate)
    });
    const [attrVillage, setAttrVillage] = useState({
        url : url,
        paginate: paginate,
        reloadTable: (url, paginate) => handleTableVillage(url, paginate)
    });


    useEffect(() => {
        handleTableProvince(url, paginate)
        handleTableCity(url, paginate)
        handleTableDistrict(url, paginate)
        handleTableVillage(url, paginate)
    }, [])

    const convertData = (data) => {
        let result = []
        data.map((v) => {
            result.push({ ...v, meta: `https://maps.google.com/?q=${JSON.parse(v.meta).lat},${JSON.parse(v.meta).long}`});
        })
        return result;
    }

    const handleTableProvince = (url, params) => {
        dataGet(url, { ...params, from: 'provinces' }).then(({data}) => {
            setDataProvince(convertData(data.data));
            setAttrProvince({
                ...attrProvince,
                links: data.links,
                meta: data.meta,
            })
        }).catch(err => {
            dataError(err).then((resErr) => {
                setDataProvince([])
                showToastAlert(toastDanger(resErr.message))
            })
        })
    }

    const handleTableCity = (url, params) => {
        dataGet(url, { ...params, from: 'cities' }).then(({data}) => {
            setDataCity(convertData(data.data));
            setAttrCity({
                ...attrCity,
                links: data.links,
                meta: data.meta,
            })
        }).catch(err => {
            dataError(err).then((resErr) => {
                setDataCity([])
                showToastAlert(toastDanger(resErr.message))
            })
        })
    }

    const handleTableDistrict = (url, params) => {
        dataGet(url, { ...params, from: 'districts' }).then(({data}) => {
            setDataDistrict(convertData(data.data));
            setAttrDistrict({
                ...attrDistrict,
                links: data.links,
                meta: data.meta,
            })
        }).catch(err => {
            dataError(err).then((resErr) => {
                setDataDistrict([])
                showToastAlert(toastDanger(resErr.message))
            })
        })
    }

    const handleTableVillage = (url, params) => {
        dataGet(url, { ...params, from: 'villages' }).then(({data}) => {
            setDataVillage(convertData(data.data));
            setAttrVillage({
                ...attrVillage,
                links: data.links,
                meta: data.meta,
            })
        }).catch(err => {
            dataError(err).then((resErr) => {
                setDataVillage([])
                showToastAlert(toastDanger(resErr.message))
            })
        })
    }

    return (
        <>
            <LoadingData data={dataProvince}>
                <CCard className='border-success border-top-success border-top-3'>
                    <CCardHeader className='p-2'>Provinsi</CCardHeader>

                    <CCardBody className='p-2'>
                        <DataTable columns={colProvinces} data={dataProvince} attr={attrProvince} />
                    </CCardBody>
                </CCard>
            </LoadingData>

            <br />

            <LoadingData data={dataCity}>
                <CCard className='border-info border-top-info border-top-3'>
                    <CCardHeader className='p-2'>Kota / Kabupaten</CCardHeader>

                    <CCardBody className='p-2'>
                        <DataTable columns={colCities} data={dataCity} attr={attrCity} />
                    </CCardBody>
                </CCard>
            </LoadingData>

            <br />

            <LoadingData data={dataDistrict}>
                <CCard className='border-warning border-top-warning border-top-3'>
                    <CCardHeader className='p-2'>Kecamatan</CCardHeader>

                    <CCardBody className='p-2'>
                        <DataTable columns={colDistricts} data={dataDistrict} attr={attrDistrict} />
                    </CCardBody>
                </CCard>
            </LoadingData>

            <br />

            <LoadingData data={dataVillage}>
                <CCard className='border-danger border-top-danger border-top-3'>
                    <CCardHeader className='p-2'>Kelurahan</CCardHeader>

                    <CCardBody className='p-2'>
                        <DataTable columns={colVillages} data={dataVillage} attr={attrVillage} />
                    </CCardBody>
                </CCard>
            </LoadingData>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        showToastAlert: (typeAlert) => dispatch({ type: "LAYOUT_TOAST_ALERT", toastAlert: typeAlert })
    }
}

export default connect( null, mapDispatchToProps )(Address);
