import React, { useMemo } from 'react';
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CPagination, CPaginationItem, CRow } from '@coreui/react';
import { useTable, useSortBy } from 'react-table'
import LayoutTable from './LayoutTable';

const DataTable = ({ columns, data, attr, searching, url, show, search, sort, order, reloadTable }) => {
    const getData = useMemo(() => data ?? [], [data]);
    const getColumns = useMemo(() => columns, [columns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns: getColumns,
        data: getData,
        manualSortBy: true,
    }, useSortBy)

    const handleSortBy = (columnHeader) => {
        if (!columnHeader.disableSortBy && columnHeader.orderBy) {
            const newSort = columns.filter((v) => v.accessor == columnHeader.id).shift();
            if (!newSort.orderBy.asc && !newSort.orderBy.desc) {
                reloadTable({
                    newSort: newSort,
                    url: url,
                    paginate: show,
                    search: search,
                    orderByColumn: newSort.accessor,
                    orderByDirection: 'asc'
                })
            } else {
                if (newSort.orderBy.asc) {
                    reloadTable({
                        newSort: newSort,
                        url: url,
                        paginate: show,
                        search: search,
                        orderByColumn: newSort.accessor,
                        orderByDirection: 'desc'
                    })
                } else {
                    reloadTable({
                        newSort: newSort,
                        url: url,
                        paginate: show,
                        search: search,
                        orderByColumn: newSort.accessor,
                        orderByDirection: 'asc'
                    })
                }
            }
        }
    }


    return (
        <>
            <TopTable
                data={data}
                attr={attr}
                show={show}
                search={search}
                url={url}
                sort={sort}
                order={order}
                reloadTable={reloadTable}
                />

            <LayoutTable
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                page={rows}
                prepareRow={prepareRow}
                columns={columns}
                searching={searching}
                sortBy={(data) => handleSortBy(data)}
                />

            <BottomTable
                data={data}
                attr={attr}
                show={show}
                search={search}
                sort={sort}
                order={order}
                reloadTable={reloadTable}
                />
        </>
    );
};

const TopTable = ({ data, attr, show, search, url, sort, order, reloadTable }) => {
    if (data !== null && attr.meta) {
        return (
            <CRow>
                <CCol sm={3} md={5} className='mb-2 d-flex flex-row align-items-center'>
                    <span className='fs-7'>Show</span>
                    <CFormSelect
                        aria-label="Show"
                        id='show'
                        className='w-auto mx-1 shadow-none'
                        size='sm'
                        value={show}
                        onChange={(e) => {
                            reloadTable({
                                newSort: sort,
                                url: url,
                                paginate: e.target.value,
                                search: search,
                                orderByColumn: order ? order.orderByColumn : '',
                                orderByDirection: order ? order.orderByDirection : '',
                            })
                        }}
                        options={[
                            { label: '10', value: '10' },
                            { label: '25', value: '25' },
                            { label: '50', value: '50' },
                            { label: '100', value: '100' }
                        ]}
                    />
                    <span className='fs-7'>entries</span>
                </CCol>

                <CCol sm={9} md={7} className='mb-2 d-flex justify-content-sm-end'>
                    <div className='d-flex align-items-center'>
                        <CFormLabel htmlFor="search" className='mb-0 fs-7'>Search :</CFormLabel>
                        <CFormInput
                            type="text"
                            id="search"
                            size='sm'
                            className='w-auto ms-1'
                            value={search}
                            onChange={(e) => {
                                reloadTable({
                                    newSort: sort,
                                    url: url,
                                    paginate: show,
                                    search: e.target.value,
                                    orderByColumn: order ? order.orderByColumn : '',
                                    orderByDirection: order ? order.orderByDirection : '',
                                })
                            }}
                            />
                    </div>

                    <CButton
                        onClick={() => {
                            reloadTable({
                                newSort: null,
                                url: attr.url,
                                paginate: attr.paginate,
                                search: '',
                                orderByColumn:  '',
                                orderByDirection: '',
                            })
                        }}
                        color="success"
                        type="button"
                        size='sm'
                        className='text-light ms-1'>
                        Refresh
                    </CButton>
                </CCol>
            </CRow>
        )
    }

    return '';
}

const BottomTable = ({ data, attr, show, search, sort,  order, reloadTable }) => {
    if (data !== null && attr.meta) {
        const labelPageItem = (value) => {
            const parser = new DOMParser();
            return parser.parseFromString(`<!doctype html><body>${value}`, 'text/html').body.textContent;
        }

        const changeBody = (value) => {
            reloadTable({
                newSort: sort,
                url: value.split("/api")[1],
                paginate: show,
                search: search,
                orderByColumn: order ? order.orderByColumn : '',
                orderByDirection: order ? order.orderByDirection : '',
            })
        }

        return (
            <CRow className='mt-2'>
                <CCol sm={6} className='mb-2 d-flex flex-row align-items-center fs-7'>
                    Showing {attr.meta.from} to {attr.meta.to} of {attr.meta.total} entries
                </CCol>

                <CCol sm={6} className="mb-2">
                    <CPagination className='mb-0' align="end" size='sm' aria-label="Page navigation table">
                        {attr.meta.links.map((vlink, keylink) => {
                            return <CPaginationItem onClick={() => changeBody(vlink.url)} className='user-select-none' active={vlink.active} disabled={vlink.url ? false : true} key={keylink}>{labelPageItem(vlink.label)}</CPaginationItem>
                        })}
                    </CPagination>
                </CCol>
            </CRow>
        );
    }

    return '';
}

export default DataTable;
