import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { RLoadingData } from '@components/loading'
import ResourceService from '@services/resource.service'
import Form from './Form'
import Loading from './Loading'
import Listing from './Listing'

const Dashboard = () => {
    const [params, setParams] = useState('');
    const [search, setSearch] = useState(false);
    const [result, setResult] = useState(null);
    const [filter, setFilter] = useState({});
    const [minLuasTanahMax, setMinLuasTanahMax] = useState(1);
    const [minHargaMax, setMinHargaMax] = useState(1);
    const [select, setSelect] = useState({});
    const [listing, setListing] = useState(null);

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            ResourceService.get('/dashboard', { first: true })
            .then(
                (res) => {
                    if (mounted) {
                        setResult(res)
                        setSelect(res.data)
                    }
                },
                (err) => mounted && setResult(err)
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [])

    useEffect(() => {
        if (search) {
            let mounted = true;
            const getData = () => {
                ResourceService.get('/dashboard'+params, { ...filter, paginate: 12 })
                .then(
                    (res) => mounted && setListing(res),
                    (err) => mounted && setListing(err)
                )
                .finally(() => setSearch(false))
            }
            getData()
            return () =>  {
                mounted = false
            }
        }
    }, [search])

    return (
        <RLoadingData result={result}>
            <Box>
                <Form
                    filter={filter}
                    setFilter={setFilter}
                    minLuasTanahMax={minLuasTanahMax}
                    setMinLuasTanahMax={setMinLuasTanahMax}
                    minHargaMax={minHargaMax}
                    setMinHargaMax={setMinHargaMax}
                    select={select}
                    setSearch={setSearch}
                    setListing={setListing}
                    setParams={setParams} />

                {search ? <Loading /> : (listing && <Listing result={listing?.data} setSearch={setSearch} setParams={setParams} />)}
            </Box>
        </RLoadingData>
    )
}

export default Dashboard
