import React from 'react'
import { CCard, CCardBody, CCol, CRow, CSpinner } from '@coreui/react'

const Loading = ({ content = 'Loading...', loading = true }) => {
    return (
        <CCard>
            <CCardBody>
                <div className="d-flex justify-content-center align-items-center">
                    <CRow>
                        <CCol xs="auto" className='my-5 d-flex align-items-center'>
                            {loading && <><CSpinner component="span" color="dark" aria-hidden="true" />
                            &nbsp; &nbsp;</>}
                            <span className='text-lg'>{content}</span>
                        </CCol>
                    </CRow>
                </div>
            </CCardBody>
        </CCard>
    )
}

export default Loading
