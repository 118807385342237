import React, { useEffect, useState } from 'react'
import { CAvatar, CBadge, CCol, CListGroup, CListGroupItem, CRow } from '@coreui/react';
import { useParams } from 'react-router-dom';
import { RAnchorSecondary } from '@components/anchor'
import { RButtonDanger } from '@components/button'
import { RLoadingData } from '@components/loading'
import { RCard, RCardBody, RCardFooter } from '@components/card'
import { capitalize } from '@hooks/textTransform'
import { fileDownload } from '@hooks/download'
import ResourceService from '@services/resource.service'
import imageUser from '@assets/images/user.png'

const Show = () => {
    let params = useParams();

    const url = '/master-pegawai'
    const [result, setResult] = useState(null);
    const [data, setData] = useState([]);
    const [biodata, setBiodata] = useState({});

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            ResourceService.get(`${url}/${params.id}/show`)
            .then(
                ({ data, ...res }) => {
                    if (mounted) {
                        const { data: thisData, biodata } = data
                        const { ops_action_row, ...getData } = thisData

                        setData(getData);
                        setBiodata(biodata)
                        setResult(res)
                    }
                },
                (error) => {
                    if (mounted) {
                        setResult(error)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [])

    return (
        <RLoadingData result={result}>
            <CRow>
                <CCol xl={4} md={5}>
                    <RCard>
                        <RCardBody>
                            <div className="mb-3">
                                <div className="d-flex align-items-center flex-column">
                                    <CAvatar src={imageUser} size="xl" shape="rounded-1" className="mt-3 mb-2" />
                                    <div className="text-center">
                                        <h4>{data.name}</h4>
                                        <CBadge color="secondary">{capitalize(data.role)}</CBadge>
                                    </div>
                                </div>
                            </div>

                            <h5 className="fw-bolder border-bottom pb-50 mb-3">Details</h5>
                            <CListGroup flush>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Username:</span>
                                    <span>{data.username}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Email:</span>
                                    <span>{data.email}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Status:</span>
                                    <span><CBadge color={data.status_id == 1 ? 'success' : 'danger'}>{data.status}</CBadge></span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Role:</span>
                                    <span>{data.role}</span>
                                </CListGroupItem>
                            </CListGroup>
                        </RCardBody>

                        <RCardFooter>
                            <div className="d-flex justify-content-center">
                                <RAnchorSecondary href={url}>Kembali</RAnchorSecondary>
                                &nbsp;
                                <RButtonDanger onClick={() => alert('segera')}>Inactive</RButtonDanger>
                            </div>
                        </RCardFooter>
                    </RCard>
                </CCol>

                <CCol xl={8} md={7}>
                    <RCard>
                        <RCardBody>
                            <h5 className="fw-bolder border-bottom pb-50 mb-3">Biodata</h5>

                            <CListGroup flush>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">ID:</span>
                                    <span>{data.id && data.id.toString().padStart(5, '0')}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Nama:</span>
                                    <span>{data.name}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Alamat / Korwil:</span>
                                    <span>{biodata ? biodata.alamat : '-'}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">No Hp:</span>
                                    <span>{biodata ? biodata.no_hp : '-'}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">No Rekening:</span>
                                    <span>{biodata ? biodata.no_rek : '-'}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Jenis Bank:</span>
                                    <span>{biodata ? biodata.bank : '-'}</span>
                                </CListGroupItem>

                                {biodata.path_document && <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <RButtonDanger onClick={() => fileDownload(window.location.origin+biodata.path_document+'/'+biodata.berkas, biodata.berkas)}>Download Berkas</RButtonDanger>
                                </CListGroupItem>}
                            </CListGroup>
                        </RCardBody>
                    </RCard>
                </CCol>
            </CRow>
        </RLoadingData>
    )
}

export default Show
