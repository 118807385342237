import React from 'react'
import { CForm } from '@coreui/react'

const RForm = ({ children, ...props }) => {
    return (
        <CForm noValidate {...props}>
            <div>{children}</div>
        </CForm>
    )
}

export default RForm
