const httpStatusCode = (res) => {
    switch (res.status) {
        case 404:
            return {
                ...res.data,
                title: res.statusText,
                errors: {}
            }
        case 422:
            return {
                ...res.data
            }
        case 500:
            return {
                title: res.statusText,
                message: res.data.message,
                errors: {}
            }

        default:
            return res
    }
}

export default httpStatusCode
