import Axios from 'axios'
import store from '@src/store'

const api_url = window.location.origin+'/api';

const table = async (url, data = {}) => {
    const { auth } = store.getState();

    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;

    return await Axios.post(api_url + url, {
        ...data,
        paginate: data.paginate ?? 10
    }, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response);
}

const get = async (url, data = {}) => {
    const { auth } = store.getState();

    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;

    const params = new FormData();

    for (const [key, value] of Object.entries(data)) {
        params.append(key, value)
    }

    return await Axios.post(api_url + url, params, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response);
}

const post = async (url, data = {}) => {
    const { auth } = store.getState();

    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;

    const params = new FormData();

    for (const [key, value] of Object.entries(data)) {
        params.append(key, value)
    }

    return await Axios.post(api_url + url, params, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response);
}

const put = async (url, data = {}) => {
    const { auth } = store.getState();

    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;

    const params = new FormData();

    params.append("_method", 'PUT');
    for (const [key, value] of Object.entries(data)) {
        params.append(key, value)
    }

    return await Axios.post(api_url + url, params, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response);
}

const destroy = async (url, data = {}) => {
    const { auth } = store.getState();

    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;

    const params = new FormData();

    params.append("_method", 'DELETE');
    for (const [key, value] of Object.entries(data)) {
        params.append(key, value)
    }

    return await Axios.post(api_url + url, params, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response);
}

export default { table, get, post, put, destroy }
