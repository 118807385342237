import React, { useEffect, useState }  from 'react'
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom";
import { CButton } from '@coreui/react';
import { pascalCase } from '@hooks/case'
import ResourceService from '@services/resource.service'
import SetSimpleTable from "./SimpleTable";
import SetDataTable from "./DataTable"

// -------------- function --------------

const costumColumn = (data, refreshTable, show, columns, history, attrTable, defaultTable, sortActive) => {
    let setColumn = [
        {
            Header: '#',
            accessor: 'no',
            Cell: ({row}) => row.index + (attrTable.meta ? attrTable.meta.from : 0),
            disableSortBy: true,
            disableFilters: true,
            minWidth: 2
        },
    ];

    if (columns !== undefined) {
        columns.map((vc) => {
            if (vc.isLink) {
                return (
                    setColumn.push({
                        Header: vc.th,
                        accessor: vc.td,
                        Cell: ({ value }) => {
                            return <CButton component='a' href={value} target={vc.targetLink ?? ''} color="link" className='fs-7 px-1 py-0'>{vc.titleLink ?? value}</CButton>
                        },
                        disableSortBy: true,
                        disableFilters: true,
                        minWidth: vc.minWidth ?? 0,
                    })
                )
            } else {
                if (vc.sortBy) {
                    if (sortActive) {
                        if (vc.td == sortActive.accessor) {
                            if (!sortActive.orderBy.asc && !sortActive.orderBy.desc) {
                                return (
                                    setColumn.push({
                                        Header: vc.th,
                                        accessor: vc.td,
                                        disableSortBy: false,
                                        minWidth: vc.minWidth ?? 0,
                                        orderBy: {
                                            asc: true,
                                            desc: false
                                        }
                                    })
                                )
                            } else {
                                if (sortActive.orderBy.asc) {
                                    return (
                                        setColumn.push({
                                            Header: vc.th,
                                            accessor: vc.td,
                                            disableSortBy: false,
                                            minWidth: vc.minWidth ?? 0,
                                            orderBy: {
                                                asc: false,
                                                desc: true
                                            }
                                        })
                                    )
                                } else {
                                    return (
                                        setColumn.push({
                                            Header: vc.th,
                                            accessor: vc.td,
                                            disableSortBy: false,
                                            minWidth: vc.minWidth ?? 0,
                                            orderBy: {
                                                asc: true,
                                                desc: false
                                            }
                                        })
                                    )
                                }
                            }
                        } else {
                            return (
                                setColumn.push({
                                    Header: vc.th,
                                    accessor: vc.td,
                                    disableSortBy: false,
                                    minWidth: vc.minWidth ?? 0,
                                    orderBy: {
                                        asc: false,
                                        desc: false
                                    }
                                })
                            )
                        }
                    } else {
                        return (
                            setColumn.push({
                                Header: vc.th,
                                accessor: vc.td,
                                disableSortBy: false,
                                minWidth: vc.minWidth ?? 0,
                                orderBy: {
                                    asc: false,
                                    desc: false
                                }
                            })
                        )
                    }
                } else {
                    return (
                        setColumn.push({
                            Header: vc.th,
                            accessor: vc.td,
                            disableSortBy: true,
                            minWidth: vc.minWidth ?? 0,
                        })
                    )
                }
            }
        })
    }

    if (attrTable.ops_action_row && data.length > 0) {
        setColumn.push({
            Header: '',
            accessor: 'actions',
            Cell: ({ row }) => {
                const { original } = row;
                const { ops_action_row, ...originalData } = original;

                if (ops_action_row) {
                    const { ops_show, ops_edit, ops_delete, ...ops_other } = ops_action_row;
                    const { url } = defaultTable

                    const convertOtherAction = (dataOther) => {
                        let otherShow = []
                        for (const [key, value] of Object.entries(dataOther)) {
                            otherShow.push({[key]: value})
                        }
                        return otherShow
                    }

                    return (
                        <div className="d-flex flex-row justify-content-center">
                            {convertOtherAction(ops_other).length > 0 && convertOtherAction(ops_other).map((voo, key) => {
                                return <ButtonOther key={key} url={url} dataRow={originalData} history={history} attr={attrTable} values={Object.values(voo).shift()} keys={Object.keys(voo).shift()} />
                            })}
                            {ops_show && ops_show.length > 0 ? <ButtonShow access={ops_show} url={url} dataRow={originalData} history={history} attr={attrTable} /> : ""}
                            {ops_edit && ops_edit.length > 0 ? <ButtonEdit access={ops_edit} url={url} dataRow={originalData} history={history} attr={attrTable} /> : ""}
                            {ops_delete && ops_delete.length > 0 ? <ButtonDelete access={ops_delete} url={url} dataRow={originalData} show={show} refreshTable={refreshTable} /> : ""}
                        </div>
                    )
                } else {
                    return '';
                }
            },
            disableSortBy: true,
            disableFilters: true,
            minWidth: 'auto'
        })
    }

    return setColumn;
}

// -------------- component --------------

const ButtonShow = ({ access, url, dataRow, history, attr }) => {
    const acs   = access[0] ?? false;
    const color = acs === true ? 'info' : 'secondary';
    const href  = url+'/'+dataRow.id+'/show'
    const { modalShow } = attr

    const onClick = (e) => {
        e.preventDefault();

        if (acs === true) {
            if (modalShow && typeof modalShow === 'function') {
                modalShow(dataRow)
            } else {
                history.push(href)
            }
        }
    }

    return <CButton component='a' onClick={onClick} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={!acs}>Detail</CButton>
}

const ButtonEdit = ({ access, url, dataRow, history, attr }) => {
    const acs   = access[0] ?? false;
    const color = acs === true ? 'warning' : 'secondary';
    const href  = url+'/'+dataRow.id+'/edit'
    const { modalEdit } = attr

    const onClick = (e) => {
        e.preventDefault();

        if (acs === true) {
            if (modalEdit && typeof modalEdit === 'function') {
                modalEdit(dataRow)
            } else {
                history.push(href)
            }
        }
    }

    return <CButton component='a' onClick={onClick} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={!acs}>Ubah</CButton>
}

const ButtonDelete = ({ access, url, dataRow, show, refreshTable }) => {
    const acs   = access[0] ?? false;
    const color = acs === true ? 'danger' : 'secondary';
    const href  = url+'/'+dataRow.id+'/delete'

    const onClick = (e) => {
        e.preventDefault();

        if (acs === true) {
            Swal.fire({
                title: 'Apakah kamu yakin?',
                text: "Kamu akan menghapus data ini!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya!',
                cancelButtonText: 'Batal',
                confirmButtonColor: '#e55353',
                focusCancel: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return ResourceService.destroy(href)
                    .then(
                        (response) => {
                            if (response.status === 200) {
                                let data = {}

                                data["url"] = url
                                data["params"] = {
                                    paginate: show,
                                }

                                refreshTable(data)
                            }

                            if (response.status !== 200) {
                                throw new Error(response.statusText)
                            }

                            return response
                        },
                        (error) => {
                            Swal.showValidationMessage(
                                `Request failed: ${error}`
                            )
                        }
                    )
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const { value } = result

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: value.data && value.data.message ? value.data.message : 'Data yang kamu pilih telah dihapus.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
    }

    // return <CButton component='a' href={url+'/'+dataRow.id+'/delete'} onClick={(e) => whenDelete(e)} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={disabled}>Delete</CButton>

    return <CButton component='a' onClick={onClick} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={!acs}>Hapus</CButton>;
}

const ButtonOther = ({ url, dataRow, history, attr, values, keys }) => {
    if (values.length > 0) {
        const da    = attr[keys] ?? {}
        const acs   = values[0] ?? false;
        const color = acs === true ? (da.color ?? 'secondary') : 'secondary';
        const href  = url+'/'+dataRow.id+'/'+keys.replace("ops_", "").replace("_", "-")
        const label = da.label ?? pascalCase(keys.replace("ops_", ""), " ")
        const func  = da['func']

        const onClick = (e) => {
            e.preventDefault();

            if (acs === true) {
                if (func && typeof func === 'function') {
                    func(dataRow)
                } else {
                    history.push(href)
                }
            }
        }

        return <CButton component='a' onClick={onClick} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={!acs}>{label}</CButton>
    } else {
        return ''
    }
}

// -------------- export --------------

const SimpleTable = (props) => {
    const prop = {...props, columns: costumColumn(props.columns, props.actions)};

    return <SetSimpleTable { ...prop } />
}

const RDataTable = ({ columns, data, table, setTable }) => {
    let history = useHistory();

    const [show, setShow] = useState(table.params.paginate ?? 10);
    const [sort, setSort] = useState(null)
    const [search, setSearch] = useState(table.params.search ?? '');
    const [searching, setSearching] = useState(false)
    const [column, setColumn] = useState(costumColumn(data, refreshTable, show, columns, history, table.attr, table.default))

    useEffect(() => {
        if (data) {
            setSearching(false)
            if (sort) {
                setColumn(costumColumn(data, refreshTable, show, columns, history, table.attr, table.default, sort))
            } else {
                setColumn(costumColumn(data, refreshTable, show, columns, history, table.attr, table.default))
            }
        }
    }, [data])

    const refreshTable = (props) => {
        setSearching(true)
        setTable({
            ...table,
            refresh: !table.refresh,
            url: props.url ?? table.url,
            params: {
                ...table.params,
                ...props.params
            }
        })
    }

    const props = {
        columns: column,
        data,
        show,
        setShow,
        search,
        setSearch,
        meta: table.attr.meta ?? {},
        searching,
        refreshTable,
        setSort,
        defaultTable: table.default
    }

    return <SetDataTable {...props} />
}

export { SimpleTable, RDataTable }
