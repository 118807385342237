const columns = [
    {
        th: 'Name',
        td: 'name',
        sortBy: true
    },
    {
        th: 'Permissions',
        td: 'permissions'
    },
]

export default columns
