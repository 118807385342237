import React from 'react'
import { useHistory } from "react-router-dom";
import { Link } from '@mui/material';

const MuiLink = ({ children, href, underline, color, variant, sx, target, data, ...props }) => {
    let history = useHistory();

    const handleOnClick = (event) => {
        if (target != '_blank') {
            event.preventDefault();

            if (!data) {
                history.push(event.target.closest("a").getAttribute('href'));
            } else {
                history.push({
                    pathname: event.target.closest("a").getAttribute('href'),
                    state: data
                })
            }
        }
    }

    return (
        <Link
            onClick={handleOnClick}
            variant={variant ?? 'inherit'}
            href={href ?? '/'}
            color={color ? color+'.main' : "primary.main"}
            underline={underline ?? "none"}
            target={target ?? ''}
            rel={target == "_blank" ? "noopener" : ''}
            sx={{
                ...sx,
                '&:hover': {
                    color: color ? color+'.dark' : "primary.dark"
                }
            }}
            {...props}>
            {children}
        </Link>
    )
}

export default MuiLink
