import React from 'react';
import { Box, Grid, PaginationItem, Paper, Typography } from '@mui/material';
import Loading from './Loading';
import ListingCard from './ListingCard';


const Listing = ({ result, setSearch, setParams }) => {
    return result.data.length < 1 ? <Loading content="Data Tidak Ditemukan" loading={false} /> : (
        <Box sx={{ flexGrow: 1, mt: 3 }}>
            <Grid container spacing={3}>
                {result.data.map((rdm, rdmKey) => (
                    <Grid item className="mb-3" xs={12} sm={6} md={4} key={rdmKey}>
                        <ListingCard data={rdm} />
                    </Grid>
                ))}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }} display="flex" justifyContent={{lg: "flex-start", xs: "center"}}>
                    <Typography>Showing {result.meta.from} to {result.meta.to} of {result.meta.total} entries</Typography>
                </Grid>

                <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }} display="flex" justifyContent={{lg: "flex-end", xs: "center"}}>
                    <Paper elevation={0} sx={{ py: '6px', px: '3px'}}>
                        {result && result.meta.links.map((vlink, keylink) => {
                            if (Number(vlink.label)) {
                                return (
                                    <PaginationItem
                                        key={keylink}
                                        aria-current={vlink.active == true ? "true" : undefined}
                                        aria-label={vlink.active == true ? `page ${vlink.label}` : `Go to page ${vlink.label}`}
                                        color="primary"
                                        disabled={false}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setParams('?page='+vlink.url.split("page=")[1])
                                            setSearch(true)
                                        }}
                                        page={Number(vlink.label)}
                                        selected={vlink.active == true ? true : false}
                                        variant={vlink.active == true ? "text" : "outlined"}
                                        shape="rounded"
                                        size="medium"
                                        type="page"
                                        />
                                )
                            } else {
                                if (keylink == 0) {
                                    return (
                                        <PaginationItem
                                            key={keylink}
                                            aria-label="Go to previous page"
                                            color="primary"
                                            disabled={vlink.url == null ? true : vlink.active}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setParams('?page='+vlink.url.split("page=")[1])
                                                setSearch(true)
                                            }}
                                            page={vlink.label}
                                            selected={vlink.active}
                                            variant="outlined"
                                            shape="rounded"
                                            size="medium"
                                            type="previous"
                                            />
                                    )
                                } else if (keylink+1 == result.meta.links.length) {
                                    return (
                                        <PaginationItem
                                            key={keylink}
                                            aria-label="Go to next page"
                                            color="primary"
                                            disabled={vlink.url == null ? true : vlink.active}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setParams('?page='+vlink.url.split("page=")[1])
                                                setSearch(true)
                                            }}
                                            page={vlink.label}
                                            selected={vlink.active}
                                            variant="outlined"
                                            shape="rounded"
                                            size="medium"
                                            type="next"
                                            />
                                    )
                                } else {
                                    return (
                                        <PaginationItem
                                            key={keylink}
                                            aria-label={`Go to page ${vlink.label}`}
                                            color="primary"
                                            disabled={true}
                                            page={'...'}
                                            selected={false}
                                            variant="text"
                                            shape="rounded"
                                            size="medium"
                                            type="page"
                                            />
                                    )
                                }
                            }
                        })}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Listing
