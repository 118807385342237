import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios'
import { CCard, CCardBody, CCol, CContainer, CRow, CSpinner } from '@coreui/react';
import { errorHandling } from '@hooks'

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.withCredentials = true;

const LoadingTable = ({ children, attr, data, result, setResult }) => {
    const api = window.location.origin+'/api';
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [newData, setNewData] = useState(data)

    useEffect(() => setNewData(data), [data])

    useEffect(() => {
        let mounted = true;

        const loadData = async () => {
            try {
                const response = await Axios.post(api + attr.url, {
                    ...attr.params,
                    paginate: attr.paginate ?? 10
                }, {
                    headers: {
                        'Authorization': auth.bearerToken
                    }
                });

                if (mounted) {
                    setResult(response);
                }
            } catch (error) {
                if (mounted) {
                    errorHandling(error)
                    .then((resErr) => {
                        setResult(resErr)
                        setNewData(resErr)
                    })
                    .catch(() => {})
                }
            }
        };

        loadData();

        return () =>  mounted = false;
    }, [attr.url, attr.params]);

    Axios.interceptors.response.use(response => {
        return response;
    }, error => {
        switch (error.response.status) {
            case 401: // not logged in
            case 419: // session expired
            case 503: // maintennce
                dispatch({
                    type: "AUTH_LOGOUT",
                })

                localStorage.removeItem(auth.xsrf);
                localStorage.removeItem(auth.xsrf+'_darkMode');
                break;

            default:
                return Promise.reject(error);
        }
    });

    if (!result || !newData) {
        return (
            <CCard>
                <CCardBody>
                    <div className="d-flex justify-content-center align-items-center">
                        <CRow>
                            <CCol xs="auto" className='my-5 d-flex align-items-center'>
                                <CSpinner component="span" color="dark" aria-hidden="true" />
                                &nbsp; &nbsp;
                                <span className='text-lg'>Loading ...</span>
                            </CCol>
                        </CRow>
                    </div>
                </CCardBody>
            </CCard>
        )
    } else {
        if (result.status !== 200) {
            return (
                <CCard>
                    <CCardBody>
                        <CContainer>
                            <CRow className="justify-content-center">
                                <CCol xs="auto">
                                    <div className="clearfix">
                                        <h1 className="float-start display-3 me-4">{result.status ?? '404'}</h1>
                                        <h4 className="pt-3">Oops! {result.message ?? "You're lost"}.</h4>
                                    </div>
                                    <p className="text-medium-emphasis float-start">Halaman yang anda cari tidak ditemukan.</p>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CCardBody>
                </CCard>
            );
        } else {
            return children;
        }
    }
}

export default LoadingTable
