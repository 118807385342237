import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import { DataTable } from '@components/base/table'
import { dataGet, dataError, dataLink } from '@actions/data.service'
import { toastDanger } from '@actions/toast.alert'
import LoadingData from '@components/loading/LoadingData'
import columns from './columns';

const User = ({ showToastAlert, history }) => {
    const [data, setData] = useState(null);
    const [attr, setAttr] = useState({
        url: "/privileges-user",
        paginate: 10,
        history: history,
        reloadTable: (url, paginate) => handleTable(url, paginate)
    });

    useEffect(() => handleTable(attr.url, attr.paginate), [])

    const handleTable = (url, params) => {
        dataGet(url, params).then(({data}) => {
            setData(data.data);
            setAttr({
                ...attr,
                links: data.links,
                meta: data.meta,
                actions: data.actions,
            })
        }).catch(err => {
            dataError(err).then((res) => {
                showToastAlert(toastDanger(res.message))
            })
        })
    }

    return (
        <LoadingData data={data}>
            <CCard>
                {attr.actions && attr.actions.opsCreate ? <CCardHeader className='p-2'>
                    <CButton component='a' href={attr.url+'/create'} onClick={(e) => dataLink(e, history)} color="primary" type="button" size='sm'>Create</CButton>
                </CCardHeader> : ''}

                <CCardBody className='p-2'>
                    <DataTable columns={columns} data={data} attr={attr} />
                </CCardBody>
            </CCard>
        </LoadingData>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        showToastAlert: (typeAlert) => dispatch({ type: "LAYOUT_TOAST_ALERT", toastAlert: typeAlert })
    }
}

export default React.memo(connect( null, mapDispatchToProps )(User))
