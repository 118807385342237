import { Flip, toast } from 'react-toastify';

const customId = "success-";

const toastSuccess = (message) => {
    return toast.success(message ?? "This is a toast message success.", {
        toastId: customId+message,
        theme: "colored",
        transition: Flip,
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    })
}

const successResponse = (res) => {
    if (res.data.message) {
        toastSuccess(res.data.message);
    } else {
        toastSuccess(res.statusText);
    }
}

export default successResponse
