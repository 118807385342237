const columns = [
    {
        th: 'Kode',
        td: 'kode',
        sortBy: true,
        minWidth: 50
    },
    {
        th: 'Judul',
        td: 'judul',
        sortBy: true,
        minWidth: 90
    },
    {
        th: 'Harga',
        td: 'harga',
        sortBy: true,
        minWidth: 90
    },
    {
        th: 'Jenis',
        td: 'jenis',
        sortBy: true,
        minWidth: 90
    },
    {
        th: 'Status',
        td: 'status_title',
        sortBy: true,
        minWidth: 90
    }
]

export default columns
