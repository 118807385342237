import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilPuzzle, cilSpeedometer } from '@coreui/icons'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

import Dashboard from '@views/pages/Dashboard'
import ListingAll from '@views/pages/listing/All'
import ListingMy from '@views/pages/listing/My'
import ListingMyForm from '@views/pages/listing/My/Form'
import ListingMyFoto from '@views/pages/listing/My/Foto'
import ListingRequest from '@views/pages/listing/Request'
import ListingVerification from '@views/pages/listing/Verification'
import ListingShow from '@views/pages/listing/Show'
import Pegawai from '@views/pages/master/Pegawai'
import PegawaiForm from '@views/pages/master/Pegawai/Form'
import PegawaiShow from '@views/pages/master/Pegawai/Show'


const route = [
    {
        to: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        exact: true,
        icon: <FaIcons.FaTachometerAlt className="nav-icon" />,
        actions: [
            {
                to: '/dashboard/:id/show',
                name: 'Show',
                component: ListingShow,
                exact: true
            },
        ]
    },
    {
        name: 'Listing',
        to: '/listing',
        icon: <FaIcons.FaCity className="nav-icon" />,
        items: [
            {
                to: '/listing-semua/:page?',
                name: 'Semua',
                component: ListingAll,
                exact: true,
                actions: [
                    {
                        to: '/listing-semua/:id/show',
                        name: 'Show',
                        component: ListingShow,
                        exact: true
                    },
                ]
            },
            {
                to: '/listing-saya',
                name: 'Saya',
                component: ListingMy,
                exact: true,
                actions: [
                    {
                        to: '/listing-saya/create',
                        name: 'Create',
                        component: ListingMyForm,
                        exact: true
                    },
                    {
                        to: '/listing-saya/:id/show',
                        name: 'Show',
                        component: ListingShow,
                        exact: true
                    },
                    {
                        to: '/listing-saya/:id/edit',
                        name: 'Edit',
                        component: ListingMyForm,
                        exact: true
                    },
                    {
                        to: '/listing-saya/:id/foto',
                        name: 'Foto',
                        component: ListingMyFoto,
                        exact: true
                    }
                ]
            },
            {
                to: '/listing-pengajuan',
                name: 'Pengajuan',
                component: ListingRequest,
                exact: true,
                actions: [
                    {
                        to: '/listing-pengajuan/:id/show',
                        name: 'Show',
                        component: ListingShow,
                        exact: true
                    },
                ]
            },
            {
                to: '/listing-verifikasi',
                name: 'Verifikasi',
                component: ListingVerification,
                exact: true,
                actions: [
                    {
                        to: '/listing-verifikasi/:id/show',
                        name: 'Show',
                        component: ListingShow,
                        exact: true
                    },
                ]
            },
        ],
    },
    {
        name: 'Master',
        to: '/master',
        icon: <FaIcons.FaDatabase className="nav-icon" />,
        items: [
            {
                to: '/master-pegawai',
                name: 'Pegawai',
                component: Pegawai,
                exact: true,
                actions: [
                    {
                        to: '/master-pegawai/create',
                        name: 'Create',
                        component: PegawaiForm,
                        exact: true
                    },
                    {
                        to: '/master-pegawai/:id/show',
                        name: 'Show',
                        component: PegawaiShow,
                        exact: true
                    },
                    {
                        to: '/master-pegawai/:id/edit',
                        name: 'Edit',
                        component: PegawaiForm,
                        exact: true
                    },
                ]
            }
        ],
    }
]

export default route
