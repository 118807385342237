import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { CAlert, CCardImage, CCarousel, CCarouselCaption, CCarouselItem, CCol, CImage, CRow } from '@coreui/react';
import { RAnchorSuccess } from '@components/anchor'
import { RButtonLoading } from '@components/button'
import { RForm, RFormInputFile, RFormSelect } from '@components/form'
import { RCard, RCardBody } from '@components/card'
import { RLoadingData } from '@components/loading'
import { formValidation } from '@hooks/validation'
import { errorResponse, successResponse } from '@hooks/response'
import ResourceService from '@services/resource.service'
import image from '@assets/images/image.png'

const Foto = () => {
    let params = useParams();
    let history = useHistory();
    const url = '/listing-saya'
    const imageHeight = 320
    const [data, setData] = useState([]);
    const [newFoto, setNewFoto] = useState([]);
    const [selectFoto, setSelectFoto] = useState([]);
    const [result, setResult] = useState(null);

    const [submit, setSubmit] = useState(false);
    const [submitDelete, setSubmitDelete] = useState(false);
    const [invalid, setInvalid] = useState({});

    const [fotos, setFotos] = useState({
        id: 'fotos',
        label: 'Unggah Foto',
        type: 'file',
        accept: 'image',
        required: true,
        multiple: true,
        size: 120480
    });

    const [deleteFoto, setDeleteFoto] = useState({
        id: 'delete_foto',
        label: 'Hapus Foto',
        required: true,
        placeholder: 'Hapus Foto'
    });

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            ResourceService.get(`${url}/${params.id}/edit`, {foto: true})
            .then(
                ({ data, ...res }) => {
                    if (mounted) {
                        setData(data.data)
                        handleSetFoto(data.foto)
                        setResult(res)
                    }
                },
                (error) => {
                    if (mounted) {
                        setResult(error)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [])

    const handleSetFoto = (fotos) => {
        setNewFoto(fotos)
        let settingSelectFoto = []
        fotos.map((foto, idx) => {
            settingSelectFoto.push({
                value: foto.id,
                label: 'Foto '+(idx+1)
            })
        })
        setSelectFoto(settingSelectFoto)
    }

    const handelUpload = (e) => {
        e.preventDefault();

        formValidation({ fotos })
        .then((res) => {
            setInvalid({})
            if (!submit) {
                setSubmit(true)
                ResourceService.put(url+'/'+params.id+'/update', {...res, foto: true})
                .then(
                    (res) => {
                        setFotos({ ...fotos, value: '' })
                        handleSetFoto(res.data.foto)
                        document.getElementById('fotos').value = null;
                        successResponse(res)
                    },
                    (error) => {
                        errorResponse(error, setInvalid)
                    }
                )
                .finally(() => setSubmit(false))
            }
        })
        .catch((err) => setInvalid(err))
    }

    const handelDelete = (e) => {
        e.preventDefault();

        formValidation({ deleteFoto })
        .then((res) => {
            setInvalid({})
            if (!submitDelete) {
                setSubmitDelete(true)
                setNewFoto([])
                ResourceService.destroy(url+'/'+params.id+'/delete', {...res, foto: true})
                .then(
                    (res) => {
                        setDeleteFoto({ ...deleteFoto, value: '' })
                        handleSetFoto(res.data.foto)
                        successResponse(res)
                    },
                    (error) => {
                        errorResponse(error, setInvalid)
                    }
                )
                .finally(() => setSubmitDelete(false))
            }
        })
        .catch((err) => setInvalid(err))

    }

    return (
        <RLoadingData result={result}>
            <CRow>
                <CCol>
                    <RCard className='mb-2'>
                        <RCardBody>
                            {newFoto.length < 1
                            ?
                            <CCardImage orientation="top" src={image} style={{ height: imageHeight }} />
                            :
                            <CCarousel
                                controls={newFoto.length > 1 ? true : false}
                                interval={false}>
                                {newFoto.map((vFoto, idxFoto) => {
                                    return (
                                        <CCarouselItem key={idxFoto}>
                                            <div className="d-flex justify-content-center">
                                                <CImage className="d-block" src={window.location.origin+vFoto.image} alt={`slide ${idxFoto}`} style={{ height: imageHeight }} />
                                            </div>
                                            <CCarouselCaption>
                                                <p className='mb-0 user-select-none text-dark rounded-pill' style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', bottom: '0.5rem' }}>Foto {idxFoto+1}</p>
                                            </CCarouselCaption>
                                        </CCarouselItem>
                                    )
                                })}
                            </CCarousel>
                            }
                        </RCardBody>
                    </RCard>
                </CCol>

                <CCol lg={{ span: 4, order: 'first' }}>

                    <CAlert color="primary" className='py-2 px-3' style={{ position: 'relative' }}>
                        <div className='alert-body font-small-2 mb-2'>
                            <p className='mb-1'>
                                <small className='me-50'>
                                    <span className='fw-bold'>Kode:</span> {data.kode}
                                </small>
                            </p>
                            <p className='mb-1'>
                                <small className='me-50'>
                                    <span className='fw-bold'>Judul:</span> {data.judul}
                                </small>
                            </p>
                        </div>

                        <RAnchorSuccess href={url+'/'+params.id+'/show'}>Detail</RAnchorSuccess>
                    </CAlert>

                    <RCard className='mb-2'>
                        <RForm onSubmit={handelUpload}>
                            <RCardBody>
                                <div className='mb-2'>
                                    <RFormInputFile value={fotos} setValue={setFotos} invalid={invalid} />
                                </div>

                                <RButtonLoading type='submit' loading={submit}>Unggah</RButtonLoading>
                            </RCardBody>
                        </RForm>
                    </RCard>

                    <RCard>
                        <RForm onSubmit={handelDelete}>
                            <RCardBody>
                                <div className='mb-2'>
                                    <RFormSelect options={selectFoto} value={deleteFoto} setValue={setDeleteFoto} invalid={invalid} />
                                </div>

                                <RButtonLoading type='submit' color='danger' loading={submitDelete} className='text-white'>Hapus</RButtonLoading>
                            </RCardBody>
                        </RForm>
                    </RCard>
                </CCol>
            </CRow>
        </RLoadingData>
    )
}

export default Foto
