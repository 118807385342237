const capitalize = (string, space = " ") => {
    if (string) {
        const words = string.match(/[a-z]+/gi);

        return words.map((word) => {
            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        }).join(space)
    } else {
        return '';
    }
}

export default capitalize
