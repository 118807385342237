import React, { useState } from 'react';
import Axios from 'axios'
import { routePages } from '@routes'

const handleRoute = (name) => {
    let result = 0;

    routePages.map(route => {
        if (route.component !== undefined && route.items === undefined) {
            if (route.to.substring(1) == name) {
                result += 1;
            }

            if (route.actions) {
                route.actions.map((routeAction) => {
                    if (routeAction.to.replace("/:id", "").substring(1).replace("/", "-") == name) {
                        result += 1;
                    }
                })
            }
        } else {
            if (route.items) {
                route.items.map((sub) => {
                    if (sub.component !== undefined) {
                        if (sub.to.substring(1) == name) {
                            result += 1;
                        }
                    }

                    if (sub.actions) {
                        sub.actions.map((subAction) => {
                            if (subAction.to.replace("/:id", "").substring(1).replace("/", "-") == name) {
                                result += 1;
                            }
                        })
                    }
                })
            }
        }
    })

    return result > 0 ? "OK" : "NULL";
}

export const dataGet = async (url, params = {}) => {
    try {
        const res = await Axios.post(url, {...params, paginate: params.paginate ?? 10 });

        if (url == '/privileges-permission') {
            let data = [];

            res.data.data.map((vData) => {
                data.push({ ...vData, route: handleRoute(vData.name) })
            })

            return Promise.resolve({
                ...res,
                data: {
                    ...res.data,
                    data: data
                }
            });
        }

        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const dataPost = async (urlData, data = {}, options = {}) => {

    try {
        const res = await Axios.post(urlData, data, options);

        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const dataShow = async (urlData, param) => {
    try {
        const res = await Axios.post(urlData+'/'+param);

        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const dataPut = async (urlData, data = {}, options = {}) => {
    try {
        const res = await Axios.put(urlData, data, options);

        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const dataDelete = async (urlData) => {
    try {
        const res = await Axios.delete(urlData);

        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const dataFind = async (urlData, data = {}) => {
    try {
        const res = await Axios.post(urlData, data);

        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const dataSelect = async (url, value, label, params = {}) => {
    try {
        let result = [];
        const res = await Axios.post(url, params);
        res.data.map((v) => {
            result.push({
                value: v[value],
                label: v[label]
            })
        })
        console.log(result);
        return Promise.resolve([]);
    } catch (error) {
        return Promise.reject(error);
    }
}

// --------------------- handle error ---------------------

export const dataError = (err) => {
    if (err.response && err.response.status !== 500) {
        if (err.response) {
            switch (err.response.status) {
                case 400:
                    return Promise.resolve({
                        status: err.response.status,
                        message: err.response.statusText
                    })
                case 403:
                    return Promise.resolve({
                        status: err.response.status,
                        message: err.response.data.statusText ?? err.response.statusText
                    })
                case 404:
                    return Promise.resolve({
                        status: err.response.status,
                        message: err.response.statusText
                    })
                case 405:
                    return Promise.resolve({
                        status: err.response.status,
                        message: err.response.statusText
                    })
                case 422:
                    return Promise.resolve({
                        status: err.response.status,
                        message: err.response.statusText,
                        errors: err.response.data.errors
                    })

                default:
                    return Promise.resolve({
                        status: err.response.status,
                        message: err.response.statusText,
                    })
            }
        } else {
            return Promise.resolve({
                status: 404,
                message: "Server error."
            })
        }
    }

    if (err.message) {
        return Promise.resolve({
            status:  err.response ? err.response.status : 404,
            message: err.message
        })
    }

    return Promise.reject(err);
}

// --------------------- button ---------------------

export const dataLink = (event, history, data = false) => {
    event.preventDefault();
    if (!data) {
        history.push(event.target.closest("a").getAttribute('href'));
    } else {
        history.push({
            pathname: event.target.closest("a").getAttribute('href'),
            state: data
        })
    }
}
