import React from 'react'
import { CAvatar, CCard, CCardBody, CCol, CImage, CRow } from '@coreui/react'

const SidebarUser = ({ name, roles, foto }) => {
    return (
        <CCard color='transparent' style={{ borderRadius: 0 }}>
            <CCardBody className="p-2">
                <CRow className="align-items-start">
                    <CCol sm={3}>
                        {
                            foto.length < 1
                            ?
                            <CAvatar className="bg-bravo" color="secondary" size="lg" status="success">{ name.charAt(0) }</CAvatar>
                            :
                            <CImage className="d-block rounded" src={window.location.origin+foto} style={{ height: 48, width: 48 }} />
                        }
                    </CCol>

                    <CCol>
                        <div>{ name.split(" ")[0] ?? '' }</div>
                        <div className="fs-7">{roles[0]}</div>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    )
}

export default SidebarUser
