import React from 'react';
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';

const LayoutTable = (props) => {
    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, columns, searching, sortBy } = props

    const HandleOrdeBy = ({ orderBy }) => {
        if (orderBy) {
            if (orderBy.asc) {
                return (
                    <>
                        <span>↑</span>
                        <span style={{ opacity: '.3'}}>↓</span>
                    </>
                )
            } else if (orderBy.desc) {
                return (
                    <>
                        <span style={{ opacity: '.3'}}>↑</span>
                        <span>↓</span>
                    </>
                )
            } else {
                return (
                    <>
                        <span style={{ opacity: '.3'}}>↑</span>
                        <span style={{ opacity: '.3'}}>↓</span>
                    </>
                )
            }
        } else {
            return ''
        }
    }

    return (
            <CTable {...getTableProps()} bordered hover responsive className={searching ? 'mb-0 loading' : 'mb-0'} style={{ width: '100%' }}>
                <CTableHead>
                    {headerGroups.map((headerGroup) => (
                        <CTableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((columnHeader) => {
                                if (sortBy) {
                                    return (
                                        <CTableHeaderCell {...columnHeader.getHeaderProps()}  onClick={() => sortBy(columnHeader)} className='py-2 fs-7 user-select-none' style={{ cursor: 'default', minWidth: columnHeader.minWidth }}>
                                            {
                                            columnHeader.disableSortBy
                                            ?
                                            columnHeader.render('Header')
                                            :
                                            <span className='d-flex'>
                                                <span className='me-auto'>{columnHeader.render('Header')}</span>
                                                {!columnHeader.disableSortBy && page.length > 0 && <HandleOrdeBy orderBy={columnHeader.orderBy} />}
                                            </span>
                                            }
                                        </CTableHeaderCell>
                                    )
                                } else {
                                    return (
                                        <CTableHeaderCell {...columnHeader.getHeaderProps()} className='py-2 fs-7' style={{ minWidth: columnHeader.minWidth }}>
                                            {columnHeader.render('Header')}
                                        </CTableHeaderCell>
                                    )
                                }
                            })}
                        </CTableRow>
                    ))}
                </CTableHead>

                <CTableBody {...getTableBodyProps()}>
                    {
                        page.length > 0
                        ?
                        page.map((row) => {
                            prepareRow(row)

                            let classBodyRow = ""
                            let styleBodyRow = {}

                            if (row.original.classBodyRow) {
                                classBodyRow += " "+row.original.classBodyRow
                            }
                            if (row.original.styleBodyRow) {
                                styleBodyRow = row.original.styleBodyRow
                            }

                            return (
                                <CTableRow {...row.getRowProps()} className={classBodyRow} style={styleBodyRow}>
                                    {row.cells.map((cellBody) => {
                                        let classBodyCell = ""
                                        let styleBodyCell = {}

                                        if (cellBody.value) {
                                            if (cellBody.row.original.classBodyCell && cellBody.row.original.classBodyCell[cellBody.column.id]) {
                                                classBodyCell += " "+cellBody.row.original.classBodyCell[cellBody.column.id]
                                            }
                                            if (cellBody.row.original.styleBodyCell && cellBody.row.original.styleBodyCell[cellBody.column.id]) {
                                                styleBodyCell = cellBody.row.original.styleBodyCell[cellBody.column.id]
                                            }
                                        }

                                        return (
                                            <CTableDataCell {...cellBody.getCellProps()} className={'py-1 align-middle fs-7'+classBodyCell} style={styleBodyCell}>
                                                {cellBody.render('Cell')}
                                            </CTableDataCell>
                                        )
                                    })}
                                </CTableRow>
                            )
                        })
                        :
                        <CTableRow>
                            <CTableDataCell colSpan={columns.length} className="py-5 text-center">
                                Data tidak ditemukan.
                            </CTableDataCell>
                        </CTableRow>
                    }
                </CTableBody>
            </CTable>
    );
};

export default LayoutTable;
