const columns = [
    {
        th: 'Name',
        td: 'name',
        sortBy: true
    },
    {
        th: 'Route',
        td: 'route'
    },
    {
        th: 'GN',
        td: 'guard_name'
    },
    {
        th: 'Creted At',
        td: 'created_at'
    },
    {
        th: 'Created By',
        td: 'created_by'
    }
]

export default columns
