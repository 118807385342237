const rupiahNumber = (value) => {
    if (value !== undefined) {
        let number   = value.toString().replace(/[^,\d]/g, '').toString(),
            split    = number.split(','),
            sisa     = split[0].length % 3,
            rupiah   = split[0].substr(0, sisa),
            ribuan   = split[0].substr(sisa).match(/\d{3}/gi);

            if (ribuan) {
                rupiah += sisa ? '.'+ribuan.join('.') : ribuan.join('.')
            }

            return rupiah
    } else {
        return value
    }
}

export default rupiahNumber
