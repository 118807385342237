import React from 'react'
import { Box, Card, Stack, Typography } from "@mui/material"
import httpStatusCode from "@configs/httpStatusCode"

const RenderOk = ({ children, response }) => {
    if (response.status === 200) {
        return children
    } else {
        return (
            <Card variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 4 }}>
                    <Stack direction="row" spacing={2}>
                        <Stack sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="h1" gutterBottom sx={{ mb: 0 }}>{response.status}</Typography>
                        </Stack>

                        <Stack sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="h6" gutterBottom component="div" sx={{ mb: 0 }}>Oops!</Typography>

                            <Typography variant="h5" gutterBottom component="div" sx={{ mb: 0 }}>
                                {httpStatusCode(response).title}
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom component="div" sx={{ mb: 0 }}>
                                {httpStatusCode(response).message}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Card>
        )
    }
}

export default RenderOk
