import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CContainer } from '@coreui/react'
import Page404 from '@views/errors/Page404'
import ContentTitle from './ContentTitle'
import Profile from '@views/account/Profile'
import UbahPassword from '@views/account/UbahPassword'
import UbahFoto from '@views/account/UbahFoto'

const Content = ({ router }) => {
    console.log(router)
    return (
        <>
            <ContentTitle router={router} />
            <CContainer lg className="px-0">
                <Switch>
                    {router.map(({ path, exact, name, component: Component }, idx) => {
                        return (
                            <Route
                                key={idx}
                                path={path}
                                exact={exact}
                                name={name}
                                render={(props) => <Component  {...props} />} />
                        )
                    })}
                    <Route path="/account-profile" name="Profile" component={Profile} />
                    <Route path="/ubah-password" name="Ubah Password" component={UbahPassword} />
                    <Route path="/ubah-foto" name="Ubah Foto" component={UbahFoto} />
                    <Route path="*" name="Page 404" component={Page404} />
                </Switch>
            </CContainer>
        </>
    )
}

export default React.memo(Content)
