import React, { useEffect, useState } from 'react'

import { CAvatar, CBadge, CCol, CImage, CListGroup, CListGroupItem, CRow } from '@coreui/react';
import { RCard, RCardBody, RCardFooter } from '@components/card'
import { RAnchorPrimary } from '@components/anchor'
import { LoadingPage } from '@components/loading'
import { capitalize } from '@hooks/textTransform'
import ResourceService from '@services/resource.service'

const Profile = () => {

    const [result, setResult] = useState(null);
    const [data, setData] = useState({});
    const [biodata, setBiodata] = useState({});
    const [foto, setFoto] = useState('')

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            ResourceService.get('/profile')
            .then(
                (res) => {
                    if (mounted) {
                        setData(res.data.data)
                        setBiodata(res.data.biodata)
                        setFoto(res.data.foto)
                        setResult(res)
                    }
                },
                (err) => {
                    if (mounted) {
                        alert(err.response.message)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [])

    return (
        <LoadingPage data={result}>
            <CRow>
                <CCol xl={4} md={5}>
                    <RCard>
                        <RCardBody>
                            <div className="mb-3">
                                <div className="d-flex align-items-center flex-column">
                                    {
                                        foto.length < 1
                                        ?
                                        <CAvatar className="bg-bravo" color="secondary" size="lg" status="success">{ data.name && data.name.charAt(0) }</CAvatar>
                                        :
                                        <CImage className="d-block" src={window.location.origin+foto} style={{ height: 64, width: 64 }} />
                                    }
                                    <div className="text-center">
                                        <h4>{data.name}</h4>
                                        <CBadge color="secondary">{capitalize(data.role)}</CBadge>
                                    </div>
                                </div>
                            </div>

                            <h5 className="fw-bolder border-bottom pb-50 mb-3">Details</h5>
                            <CListGroup flush>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Username:</span>
                                    <span>{data.username}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Email:</span>
                                    <span>{data.email}</span>
                                </CListGroupItem>
                            </CListGroup>
                        </RCardBody>
                    </RCard>
                </CCol>

                <CCol xl={8} md={7}>
                    <RCard>
                        <RCardBody>
                            <h5 className="fw-bolder border-bottom pb-50 mb-3">Biodata</h5>

                            <CListGroup flush>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">ID:</span>
                                    <span>{data.id && data.id.toString().padStart(5, '0')}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Nama:</span>
                                    <span>{data.name}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Alamat / Korwil:</span>
                                    <span>{biodata ? biodata.alamat : '-'}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">No Hp:</span>
                                    <span>{biodata ? biodata.no_hp : '-'}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">No Rekening:</span>
                                    <span>{biodata ? biodata.no_rek : '-'}</span>
                                </CListGroupItem>
                                <CListGroupItem className="fs-7 border-0 mb-2 px-0 py-0">
                                    <span className="fw-bolder me-2">Jenis Bank:</span>
                                    <span>{biodata ? biodata.bank : '-'}</span>
                                </CListGroupItem>
                            </CListGroup>
                        </RCardBody>

                        <RCardFooter>
                            <RAnchorPrimary href='/ubah-password'>Ubah Password</RAnchorPrimary>
                            &nbsp;
                            <RAnchorPrimary href='/ubah-foto'>Ubah Foto</RAnchorPrimary>
                        </RCardFooter>
                    </RCard>
                </CCol>
            </CRow>
        </LoadingPage>
    )
}

export default Profile
