import React, { useEffect, useState } from 'react'
import { CButton, CCloseButton, CForm, CFormFeedback, CFormInput, CFormLabel, CInputGroup, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react'
import { dataPost, dataPut, dataError } from '@actions/data.service'
import { toastDanger, toastSuccess } from '@actions/toast.alert'

const Form = ({ visible, setVisible, data = {}}) => {
    const [id, setId] = useState(0)
    const [name, setName] = useState('')

    const [invalidName, setInvalidName] = useState('')

    const [submit, setSubmit] = useState(false)

    const { title, url, reloadTable, showToastAlert, dataForm, dataRow } = data

    useEffect(() => {
        if (visible) {
            setId(0)
            setName('')

            setInvalidName('')

            setSubmit(false)

            if (dataRow) {
                setId(dataRow.id)
                setName(dataRow.name)
            }
        }
    }, [visible])

    const handleOnChange = (e, setValue, setInvalid) => {
        setValue(e.target.value)
        setInvalid('')
    }

    const validationRequire = (value, setInvalid) => {
        if (value.length < 1) {
            setInvalid('This field is required.')
            return Promise.reject("reject")
        }

        return Promise.resolve("resolve")
    }

    const catchOnSubmit = (err) => {
        dataError(err).then((res) => {
            if (res.errors) {
                Object.values(res.errors).shift()
            }

            showToastAlert(toastDanger(res.message))
            setSubmit(false)
        }).catch(() => {})
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        if (submit) return;

        setInvalidName('')

        validationRequire(name, setInvalidName)
        .then((res) => {
            if (!submit) {
                setSubmit(true)

                if (dataRow) {
                    dataPut(url+'/'+id+'/update', { name })
                    .then((res) => {
                        if (res && res.status === 200) {
                            setVisible(false)
                            reloadTable()
                            showToastAlert(toastSuccess("Data berhasil disimpan."))
                        }
                    })
                    .catch((err) => catchOnSubmit(err))
                } else {
                    dataPost(url+'/store', { name })
                    .then((res) => {
                        if (res && res.status === 200) {
                            setVisible(false)
                            reloadTable()
                            showToastAlert(toastSuccess("Data berhasil disimpan."))
                        }
                    })
                    .catch((err) => catchOnSubmit(err))
                }
            }
        })
        .catch(error => {
            // reject
        })
    }

    return (
        <CModal backdrop="static" alignment="top" scrollable visible={visible}>
            <CModalHeader className='p-2' closeButton={false}>
                <CModalTitle>{title}</CModalTitle>
                <CCloseButton onClick={() => setVisible(false)} className='me-0' />
            </CModalHeader>

            <CForm noValidate onSubmit={handleOnSubmit}>
                <CModalBody className='p-2'>
                    <CFormLabel htmlFor="name" className='mb-0'>Name</CFormLabel>
                    <CInputGroup size='sm' className="mb-3">
                        <CFormInput
                            type="text"
                            id="name"
                            placeholder='Enter name of permission'
                            invalid={invalidName ? true : false}
                            value={name}
                            onChange={(e) => handleOnChange(e, setName, setInvalidName)}
                            required
                            />
                        <CFormFeedback invalid>{invalidName}</CFormFeedback>
                    </CInputGroup>
                </CModalBody>

                <CModalFooter className='p-2 justify-content-start'>
                    <CButton color="secondary" size='sm' onClick={() => setVisible(false)}>Close</CButton>
                    <CButton color="primary" type="submit" size='sm'>
                        <div className="d-flex align-items-center">
                            <span>Save</span>
                            {submit ? <CSpinner color="light" size="sm" className='ms-1' /> : ''}
                        </div>
                    </CButton>
                </CModalFooter>
            </CForm>
        </CModal>
    )
}

export default Form
