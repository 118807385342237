import React from 'react'
import { connect } from 'react-redux'
import { CSidebar } from '@coreui/react'
import SidebarLogo from './SidebarLogo'
import SidebarUser from './SidebarUser'
import SidebarNavigation from './SidebarNavigation'


const Sidebar = ({ name, roles, permissions, sidebarShow, appName, foto }) => {
    return (
        <CSidebar position="fixed" className="bg-charlie" visible={sidebarShow}>
            <SidebarLogo appName={appName} />
            <SidebarUser name={name} roles={roles} foto={foto} />
            <SidebarNavigation permissions={permissions} />
        </CSidebar>
    )
}

const mapStateToProps = state => {
    return {
        name: state.auth.name,
        roles: state.auth.roles,
        foto: state.auth.foto,
        permissions: state.auth.permissions,
        sidebarShow: state.layout.sidebarShow,
        appName: state.layout.appName,
    };
};

export default React.memo(connect( mapStateToProps, null )(Sidebar))
