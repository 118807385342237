import rupiahNumberConvert from './rupiahNumberConvert'

const rupiahTextHalfConvert = (value, last = false) => {
    let result  = rupiahNumberConvert(value).split("."),
        display = ''

    switch (result.length) {
        case 1:
            display += result.join('')
            break;
        case 2:
            display += result.shift()+' Ribu';
            break;
        case 3:
            let juta = result.shift()
            let ribuJuta = Number(result.shift())

            if (ribuJuta < 1) {
                display += juta+' Juta'
            } else {
                display += juta+','+ribuJuta.toString().split("").shift()+' Juta'
            }
            break;
        case 4:
            let miliar = result.shift()
            let jutaMiliar = Number(result.shift())

            if (jutaMiliar < 1) {
                display += miliar+' Miliar'
            } else {
                display += miliar+','+jutaMiliar.toString().split("").shift()+' Miliar'
            }
            break;
        case 5:
            let triliun = result.shift()
            let miliarTriliun = Number(result.shift())

            if (miliarTriliun < 1) {
                display += triliun+' Triliun'
            } else {
                display += triliun+','+miliarTriliun.toString().split("").shift()+' Triliun'
            }
            break;

        default:
            // 0
            display += result.join('')
            break;
    }

    if (last) {
        return display + ' Rupiah'
    } else {
        return display
    }
}

export default rupiahTextHalfConvert
