const setItem = (xsrf, token) => {
    localStorage.setItem(xsrf, token);
    localStorage.setItem(xsrf+'_lang', 'id');
};

const removeItem = (xsrf) => {
    localStorage.removeItem(xsrf);
    localStorage.removeItem(xsrf+'_lang', 'id');
};

export default { setItem, removeItem };
