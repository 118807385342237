import React from 'react'
import { CFormFeedback, CFormInput, CFormLabel } from '@coreui/react'
import { rupiahNumberConvert } from '@hooks/convert'

const RFormInput = ({ value, setValue, invalid }) => {
    const { id, label, type, value: thisValue, valueType, required, requiredLabel, ...props } = value

    const setRequiredLabel = requiredLabel ?? true
    const setLabel = label

    return (
        <>
            <CFormLabel className='mb-0' htmlFor={id ?? ''}>{setLabel}{required && setRequiredLabel ? <span className="text-danger"> *</span> : ''}</CFormLabel>
            <CFormInput
                id={id ?? ''}
                size='sm'
                type={type ?? 'text'}
                value={thisValue ?? ''}
                invalid={invalid[id] ? true : false }
                onKeyPress={(e) => {
                    if (valueType == 'number') {
                        if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                        }
                    }
                }}
                onChange={(e) => {
                    setValue({
                        ...value,
                        value: valueType == 'rupiah' ? rupiahNumberConvert(e.target.value) :  e.target.value
                    })
                }}
                {...props}
                />
            <CFormFeedback invalid>{invalid[id]}</CFormFeedback>
        </>
    )
}

export default RFormInput
