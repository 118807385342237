import { CToast, CToastBody, CToastClose, CToastHeader } from "@coreui/react"

// --cui-primary: #321fdb;
//     --cui-secondary: #9da5b1;
//     --cui-success: #2eb85c;
//     --cui-info: #39f;
//     --cui-warning: #f9b115;
//     --cui-danger: #e55353;
//     --cui-light: #ebedef;
//     --cui-dark: #4f5d73;

const delay = 2500

const ToastHeader = ({ title, color }) => {
    return (
        <CToastHeader close="true">
            <svg
                className="rounded me-2"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                role="img">
                <rect width="100%" height="100%" fill={color}></rect>
            </svg>
            <strong className="me-auto">{title}</strong>

            <CToastClose className="me-0 m-auto h-auto" black="true" />
        </CToastHeader>
    )
}

export const toastSuccess = (content) =>  {
    return (
        <CToast autohide={true} visible={true} delay={delay}>
            <ToastHeader title="Success" color="#2eb85c" />

            <CToastBody className="bg-success text-light">
                {content != undefined ? content : "This is a toast message success."}
            </CToastBody>
        </CToast>
    )
}

export const toastDanger = (content) =>  {
    return (
        <CToast autohide={true} visible={true} delay={delay}>
            <ToastHeader title="Error" color="#e55353" />

            <CToastBody className="bg-danger text-light">
                {content != undefined ? content : "This is a toast message error."}
            </CToastBody>
        </CToast>
    )
}
