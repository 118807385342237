import React from 'react'
import { CAvatar, CHeaderBrand } from '@coreui/react'
import logo from '@assets/images/logo.png'

const HeaderBrand = ({ appName }) => {
  return (
    <CHeaderBrand className="mx-auto d-md-none" to="/">
        <div className="d-flex justify-content-between align-items-center">
            <CAvatar src={logo} size="sm" />
            <div className='ms-2'>{appName}</div>
        </div>
    </CHeaderBrand>
  )
}

export default HeaderBrand
