import React from 'react'
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { MuiButton } from '@components/mui'

const Form = ({ filter, setFilter, minLuasTanahMax, setMinLuasTanahMax, minHargaMax, setMinHargaMax, select, setSearch, setListing, setParams }) => {
    return (
        <Paper elevation={1} sx={{ mb: 2, p: 2, color: "secondary" }}>
            <Typography variant="subtitle1" gutterBottom component="div" align="center">FILTER LISTING</Typography>

            <hr style={{ marginTop: 0 }} />

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item className="mb-3" xs={6} md={3}>
                        <TextField
                            id="luas-tanah-min"
                            label="Luas Tanah Min"
                            type="number"
                            size="small"
                            placeholder="Silahkan isi"
                            fullWidth
                            value={filter.luasTanahMin ?? ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                }
                            }}
                            onChange={(e) => {
                                setMinLuasTanahMax(e.target.value)
                                setFilter({
                                    ...filter,
                                    luasTanahMin: e.target.value,
                                    luasTanahMax: parseInt(filter.luasTanahMax ?? 0) <= parseInt(e.target.value) ? '' : filter.luasTanahMax,
                                })
                            }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(0, 0, 0, 0.6) !important'
                                },
                                '& .MuiInputBase-root': {
                                    '& fieldset': {
                                        borderWidth: '1px !important',
                                        borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                    },
                                    '& legend': {
                                        float: 'unset !important'
                                    },
                                },
                            }} />
                    </Grid>

                    <Grid item className="mb-3" xs={6} md={3}>
                        <TextField
                            id="luas-tanah-max"
                            label="Luas Tanah Max"
                            type="number"
                            size="small"
                            placeholder="Silahkan isi"
                            fullWidth
                            value={filter.luasTanahMax ?? ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    min: minLuasTanahMax,
                                }
                            }}
                            onChange={(e) => {
                                setFilter({
                                    ...filter,
                                    luasTanahMax: e.target.value
                                })
                            }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(0, 0, 0, 0.6) !important'
                                },
                                '& .MuiInputBase-root': {
                                    '& fieldset': {
                                        borderWidth: '1px !important',
                                        borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                    },
                                    '& legend': {
                                        float: 'unset !important'
                                    },
                                },
                            }} />
                    </Grid>

                    <Grid item className="mb-3" xs={6} md={3}>
                        <TextField
                            id="harga-min"
                            label="Harga Min"
                            type="number"
                            size="small"
                            placeholder="Silahkan isi"
                            fullWidth
                            value={filter.hargaMin ?? ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                }
                            }}
                            onChange={(e) => {
                                setMinHargaMax(e.target.value)
                                setFilter({
                                    ...filter,
                                    hargaMin: e.target.value,
                                    hargaMax: parseInt(filter.hargaMax ?? 0) <= parseInt(e.target.value) ? '' : filter.hargaMax,
                                })
                            }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(0, 0, 0, 0.6) !important'
                                },
                                '& .MuiInputBase-root': {
                                    '& fieldset': {
                                        borderWidth: '1px !important',
                                        borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                    },
                                    '& legend': {
                                        float: 'unset !important'
                                    },
                                },
                            }} />
                    </Grid>

                    <Grid item className="mb-3" xs={6} md={3}>
                        <TextField
                            id="harga-max"
                            label="Harga Max"
                            type="number"
                            size="small"
                            placeholder="Silahkan isi"
                            fullWidth
                            value={filter.hargaMax ?? ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    min: minHargaMax,
                                }
                            }}
                            onChange={(e) => {
                                setFilter({
                                    ...filter,
                                    hargaMax: e.target.value
                                })
                            }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(0, 0, 0, 0.6) !important'
                                },
                                '& .MuiInputBase-root': {
                                    '& fieldset': {
                                        borderWidth: '1px !important',
                                        borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                    },
                                    '& legend': {
                                        float: 'unset !important'
                                    },
                                },
                            }} />
                    </Grid>

                    <Grid item className="mb-3" xs={6} md={3}>
                        <TextField
                            fullWidth
                            focused
                            value={filter.kode ?? ''}
                            label="Kode"
                            size="small"
                            placeholder="Silahkan isi"
                            onChange={(e) => {
                                setFilter({
                                    ...filter,
                                    kode: e.target.value,
                                })
                            }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(0, 0, 0, 0.6) !important'
                                },
                                '& .MuiInputBase-root': {
                                    '& fieldset': {
                                        borderWidth: '1px !important',
                                        borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                    },
                                    '& legend': {
                                        float: 'unset !important'
                                    },
                                },
                            }} />
                    </Grid>

                    <Grid item className="mb-3" xs={6} md={3}>
                        <Box>
                            <FormControl fullWidth size="small">
                                <InputLabel id="jenis-select-label">Jenis</InputLabel>
                                <Select
                                    labelId="jenis-select-label"
                                    id="jenis-select"
                                    value={filter.jenis_id ?? 0}
                                    name="jenis_id"
                                    label="Jenis"
                                    onChange={(event) => {
                                        setFilter({
                                            ...filter,
                                            [event.target.name]: event.target.value
                                        });
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 150
                                            }
                                        }
                                    }}
                                    sx={{
                                        '&.MuiInputBase-root': {
                                            '& legend': {
                                                float: 'unset !important'
                                            },
                                        },
                                    }}>
                                    <MenuItem value={0}>Semua</MenuItem>
                                    {select.jenis && select.jenis.map((sj, idsj) => {
                                        return <MenuItem value={sj.value} key={idsj}>{sj.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item className="mb-3" xs={6} md={3}>
                        <Box>
                            <FormControl fullWidth size="small">
                                <InputLabel id="akses-select-label">Akses</InputLabel>
                                <Select
                                    labelId="akses-select-label"
                                    id="akses-select"
                                    value={filter.akses_id ?? 0}
                                    name="akses_id"
                                    label="Akses"
                                    onChange={(event) => {
                                        setFilter({
                                            ...filter,
                                            [event.target.name]: event.target.value
                                        });
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 150
                                            }
                                        }
                                    }}
                                    sx={{
                                        '&.MuiInputBase-root': {
                                            '& legend': {
                                                float: 'unset !important'
                                            },
                                        },
                                    }}>
                                    <MenuItem value={0}>Semua</MenuItem>
                                    {select.akses && select.akses.map((sa, idsa) => {
                                        return <MenuItem value={sa.value} key={idsa}>{sa.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item className="mb-3" xs={6} md={3}>
                        <Box>
                            <FormControl fullWidth size="small">
                                <InputLabel id="legalitas-select-label">Legalitas</InputLabel>
                                <Select
                                    labelId="legalitas-select-label"
                                    id="legalitas-select"
                                    value={filter.legalitas_id ?? 0}
                                    name="legalitas_id"
                                    label="Legalitas"
                                    onChange={(event) => {
                                        setFilter({
                                            ...filter,
                                            [event.target.name]: event.target.value
                                        });
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                                    sx={{
                                        '&.MuiInputBase-root': {
                                            '& legend': {
                                                float: 'unset !important'
                                            },
                                        },
                                    }}>
                                    <MenuItem value={0}>Semua</MenuItem>
                                    {select.legalitas && select.legalitas.map((sl, idsl) => {
                                        return <MenuItem value={sl.value} key={idsl}>{sl.label}</MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item className="mb-3" xs={12}>
                        <TextField
                            fullWidth
                            focused
                            value={filter.alamat ?? ''}
                            label="Alamat"
                            size="small"
                            placeholder="Silahkan isi"
                            onChange={(e) => {
                                setFilter({
                                    ...filter,
                                    alamat: e.target.value,
                                })
                            }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(0, 0, 0, 0.6) !important'
                                },
                                '& .MuiInputBase-root': {
                                    '& fieldset': {
                                        borderWidth: '1px !important',
                                        borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                    },
                                    '& legend': {
                                        float: 'unset !important'
                                    },
                                },
                            }} />
                    </Grid>
                </Grid>
            </Box>

            <hr style={{ marginTop: 4 }} />

            <MuiButton color="info" disableElevation onClick={() => {
                setParams('')
                setSearch(true)
            }}>Cari</MuiButton>
            &nbsp;&nbsp;&nbsp;
            <MuiButton color="warning" disableElevation onClick={() => {
                setListing(null)
                setFilter({})
            }}>Ulangi</MuiButton>
        </Paper>
    )
}

export default Form
