import React from 'react'
import { CAvatar, CSidebarBrand } from '@coreui/react'
import logo from '@assets/images/logo.png'

const SidebarLogo = ({ appName }) => {
    return (
        <CSidebarBrand className="d-none d-md-flex bg-delta" to="/">
            <CAvatar src={logo} shape="rounded-0" size="md" /> &nbsp; {appName}
        </CSidebarBrand>
    )
}

export default SidebarLogo
