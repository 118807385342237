import React from 'react';
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';

const LayoutTable = (props) => {
    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, columns, loading, searching, sortBy } = props

    const HandleOrdeBy = ({ orderBy }) => {
        if (orderBy) {
            if (orderBy.asc) {
                return (
                    <>
                        <span>↑</span>
                        <span style={{ opacity: '.3'}}>↓</span>
                    </>
                )
            } else if (orderBy.desc) {
                return (
                    <>
                        <span style={{ opacity: '.3'}}>↑</span>
                        <span>↓</span>
                    </>
                )
            } else {
                return (
                    <>
                        <span style={{ opacity: '.3'}}>↑</span>
                        <span style={{ opacity: '.3'}}>↓</span>
                    </>
                )
            }
        } else {
            return ''
        }
    }

    return (
            <CTable {...getTableProps()} bordered hover responsive className={loading ? 'mb-0' : (searching ? 'mb-0 loading' : 'mb-0')} style={{ width: '100%' }}>
                <CTableHead>
                    {headerGroups.map((headerGroup) => (
                        <CTableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((columnHeader) => {
                                if (sortBy) {
                                    return (
                                        <CTableHeaderCell {...columnHeader.getHeaderProps()}  onClick={() => sortBy(columnHeader)} className='py-2 fs-7 user-select-none' style={{ cursor: 'default', minWidth: columnHeader.minWidth }}>
                                            {
                                            columnHeader.disableSortBy
                                            ?
                                            columnHeader.render('Header')
                                            :
                                            <span className='d-flex'>
                                                <span className='me-auto'>{columnHeader.render('Header')}</span>
                                                {!columnHeader.disableSortBy && <HandleOrdeBy orderBy={columnHeader.orderBy} />}
                                            </span>
                                            }
                                        </CTableHeaderCell>
                                    )
                                } else {
                                    return (
                                        <CTableHeaderCell {...columnHeader.getHeaderProps()} className='py-2 fs-7' style={{ minWidth: columnHeader.minWidth }}>
                                            {columnHeader.render('Header')}
                                        </CTableHeaderCell>
                                    )
                                }
                            })}
                        </CTableRow>
                    ))}
                </CTableHead>

                <CTableBody {...getTableBodyProps()}>
                    {
                        loading
                        ?
                            <CTableRow>
                                <CTableDataCell colSpan={columns.length} className="py-5 text-center">
                                    Processing data ...
                                </CTableDataCell>
                            </CTableRow>
                        : (
                            page.length
                            ?
                            page.map((row) => {
                                prepareRow(row)
                                return (
                                    <CTableRow {...row.getRowProps()}>
                                        {row.cells.map((cellBody) => (
                                            <CTableDataCell {...cellBody.getCellProps()} className='py-1 align-middle fs-7'>{cellBody.render('Cell')}</CTableDataCell>
                                        ))}
                                    </CTableRow>
                                )
                            })
                            :
                            <CTableRow>
                                <CTableDataCell colSpan={columns.length} className="py-5 text-center">
                                    Data tidak ditemukan.
                                </CTableDataCell>
                            </CTableRow>
                        )
                    }
                </CTableBody>
            </CTable>
    );
};

export default LayoutTable;
