import React, { Fragment, useState } from 'react'
import { connect } from "react-redux";
import {
    CAvatar,
    CBadge,
    CDropdown,
    CDropdownDivider,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CHeaderNav,
    CImage
} from '@coreui/react'
import Swal from 'sweetalert2'
import AuthService from '@services/auth.service';
import * as FaIcons from 'react-icons/fa';
import user from '@assets/images/user.png'

const HeaderUser = ({ history, roles, foto, handleLogout }) => {
    const [dropdownUser, setDropdownUser] = useState(false)

    const handleDropdownItem = (e) => {
        e.preventDefault();

        setDropdownUser(false)
        history.push(e.target.closest('a').getAttribute('href'))
    }

    return (
        <CHeaderNav className="me-2">
            <CDropdown variant="nav-item" visible={dropdownUser} onShow={() => setDropdownUser(true)} onHide={() => setDropdownUser(false)}>
                <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                    {
                        foto.length < 1
                        ?
                        <CAvatar src={user} size="md" />
                        :
                        <CImage className="d-block rounded" src={window.location.origin+foto} style={{ height: 40, width: 40 }} />
                    }
                </CDropdownToggle>

                <CDropdownMenu id='app-header-dropdown' className="pt-0" placement="bottom-end">
                    <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>

                    <CDropdownItem href='/account-profile' onClick={(e) => handleDropdownItem(e)}>
                        <FaIcons.FaUser className="icon me-2" size={15} /> Profile
                    </CDropdownItem>

                    {/* <CDropdownItem href='/ubah-password' onClick={(e) => handleDropdownItem(e)}>
                        <FaIcons.FaLock className="icon me-2" size={15} /> Ubah Password
                    </CDropdownItem> */}

                    {roles.some((role) => role == 'developer') ? <DropdownItemPrivileges handleDropdownItem={handleDropdownItem} /> : ''}

                    <CDropdownDivider />

                    {/* <CDropdownItem href="#">
                        <FaIcons.FaUserLock className="icon me-2" size={15} /> Lock Account
                    </CDropdownItem> */}

                    <CDropdownItem href="#" onClick={(e) => handleLogout(e)}>
                        <FaIcons.FaSignOutAlt className="icon me-2" size={15} /> Logout
                    </CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </CHeaderNav>
    )
}

const DropdownItemPrivileges = ({ handleDropdownItem }) => {
    return (
        <Fragment>
            <CDropdownHeader className="bg-light fw-semibold py-2">Privileges</CDropdownHeader>

            <CDropdownItem href='/privileges-address' onClick={(e) => handleDropdownItem(e)}>
                <FaIcons.FaAddressCard className="icon me-2" size={15} /> Address
            </CDropdownItem>

            <CDropdownItem href='/privileges-user' onClick={(e) => handleDropdownItem(e)}>
                <FaIcons.FaUsers className="icon me-2" size={15} /> User
            </CDropdownItem>

            <CDropdownItem href='/privileges-role' onClick={(e) => handleDropdownItem(e)}>
                <FaIcons.FaCogs className="icon me-2" size={15} /> Role
            </CDropdownItem>

            <CDropdownItem href='/privileges-permission' onClick={(e) => handleDropdownItem(e)}>
                <FaIcons.FaDungeon className="icon me-2" size={15} /> Permission
            </CDropdownItem>

            <CDropdownItem href='/privileges-error-log' onClick={(e) => handleDropdownItem(e)} className='d-flex align-items-center'>
                    <FaIcons.FaExclamationCircle className="icon me-2" size={15} />
                    <span>&nbsp;Log Error</span>
                    <CBadge color="danger" className="ms-auto p-1 rounded-circle">10</CBadge>
            </CDropdownItem>
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        handleLogout: (e) => {
            e.preventDefault();

            Swal.fire({
                title: 'Apakah kamu yakin?',
                text: "Kamu akan keluar dari aplikasi!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Ya, keluar!',
                cancelButtonText: 'Batal',
                confirmButtonColor: '#d33',
                focusCancel: true,
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    return AuthService.logout()
                    .then(
                        (data) => {
                            dispatch({
                                type: "AUTH_LOGOUT",
                            })
                        },
                        (error) => {
                            Swal.showValidationMessage('Logout gagal')
                        }
                    )
                },
            }).then((result) => {
                /* Read more about isConfirmed below */
                if (result.isConfirmed) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Terimakasih, selamat datang kembali.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
    }
}

export default connect( null, mapDispatchToProps )(HeaderUser);
