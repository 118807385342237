import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Avatar, Box, Chip, Grid, Paper, Slider, Skeleton, Stack, Typography, PaginationItem, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material'
import { MuiButton } from '@components/mui'
import { RenderIf, RenderOk } from '@utils/render'
import { date, rupiahTextHalf, rupiahNumber } from '@utils/conversion'
import * as FaIcons from 'react-icons/fa';
import ResourceService from '@services/resource.service'
import image from '@assets/images/image.png'

const CardListing = ({ data }) => {
    let history = useHistory();

    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name.split(' ')[0][0]
        };
    }

    return (
        <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ borderRadius: '16px' }}>
                {data ? (
                    data.foto
                    ?
                    <div className="d-flex justify-content-center">
                        <img src={window.location.origin+data.foto.url} alt={'Foto '+data.foto.id} style={{ height: 250, borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }} />
                    </div>
                    :
                    <img src={image} alt='Foto' style={{ width: '100%', height: 250, borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }} />
                ) : (
                    <Skeleton variant="rectangular" height={250} sx={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }} />
                )}

                <Stack direction="row" spacing={1} sx={{ pt: 1, pl: 1.5 }}>
                    <Chip color={data ? "primary" : "default"} label={data ? data.jenis : <Skeleton width={85} />} />

                    {data && <RenderIf isTrue={data.status_id == 4 || data.status_id == 5}>
                        <RenderIf isTrue={data.status_id == 4}>
                            <Chip color="success" label="Tersedia" variant="outlined" />
                        </RenderIf>
                        <RenderIf isTrue={data.status_id == 5}>
                            <Chip color="error" label="Closing" variant="outlined" />
                        </RenderIf>
                    </RenderIf>}
                </Stack>

                <Box sx={{ mb: 2, mt:1, pb: 2, px: 1.5 }}>
                    <Typography component="div" variant="h5" color="primary">
                        {data ? `Rp. ${rupiahTextHalf(data.harga)}` : <Skeleton width={125} />}
                    </Typography>

                    <Typography component="div" variant="subtitle1">
                        {data ? (
                            <>
                                {data.judul.substring(0, 20)}
                                <RenderIf isTrue={data.judul.length > 20}>...</RenderIf>
                            </>
                        ) : <Skeleton width={225} />}
                    </Typography>

                    <Typography component="div" variant="subtitle2">
                        {data ? (
                            <>
                                {data.lokasi}{', '}
                                Kel/Desa <span style={{ textTransform: 'capitalize' }}>{data.kelurahan && data.kelurahan.toLowerCase()}</span>{', '}
                                Kecamatan <span style={{ textTransform: 'capitalize' }}>{data.kecamatan && data.kecamatan.toLowerCase()}</span>{', '}
                                <span style={{ textTransform: 'capitalize' }}>{data.kokab && data.kokab.toLowerCase()}</span>{', '}
                                Provinsi <span style={{ textTransform: 'capitalize' }}>{data.provinsi && data.provinsi.toLowerCase()}</span>
                            </>
                        ) : <Skeleton />}
                    </Typography>

                    <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 0 }}>
                        <Typography component="div" variant="caption">
                            {data ? (
                                <>
                                    <FaIcons.FaBath /> : {data.kamar_mandi}
                                </>
                            ) : <Skeleton width={50} />}
                        </Typography>

                        <Typography component="div" variant="caption">
                            {data ? (
                                <>
                                    <FaIcons.FaBed /> : {data.kamar_tidur}
                                </>
                            ) : <Skeleton width={50} />}
                        </Typography>

                        <Typography component="div" variant="caption">
                            {data ? (
                                <>
                                    LT : {data.luas_tanah} m<sup>2</sup>
                                </>
                            ) : <Skeleton width={50} />}
                        </Typography>

                        <Typography component="div" variant="caption">
                            {data ? (
                                <>
                                    LB : {data.luas_bangunan} m<sup>2</sup>
                                </>
                            ) : <Skeleton width={50} />}
                        </Typography>
                    </Stack>

                    <hr style={{ marginTop: 0 }} />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Stack direction="row" spacing={1}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar {...stringAvatar(data ? data.created_by : '')}></Avatar>
                                </Box>

                            <Stack>
                                <Typography variant="caption" component="div">
                                    {data ? data.kode : <Skeleton width={200} />}
                                </Typography>

                                <Typography variant="caption" component="div">
                                    {data ? data.created_by : <Skeleton width={200} />}
                                </Typography>

                                <Typography variant="caption" component="div">
                                    {data ? `Tayang Sejak ${date(data.status_created_at)}` : <Skeleton width={150} />}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {data ? <MuiButton color="success" onClick={() => history.push(`/listing-semua/${data.id}/show`)}>Detail</MuiButton> : <Skeleton width={50} height="100%" />}
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Grid>
    )
}

const All = () => {
    let params = useParams();
    let history = useHistory();
    let paginate = 10

    const [result, setResult] = useState(null);
    const [searching, setSearching] = useState(false);
    const [search, setSearch] = useState({
        luas_tanah: [0, 1000000],
        harga: [0, 100000000000],
        kode: '',
        jenis_id: 0,
        akses_id: 0,
        alamat: '',
        legalitas_id: 0
    });

    const [selectJenis, setSelectJenis] = useState([]);
    const [selectAkses, setSelectAkses] = useState([]);
    const [selectLegalitas, setSelectLegalitas] = useState([]);

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            ResourceService.table("/listing-semua"+(params.page ? '?page='+params.page : ''), { ...search, paginate })
            .then(
                (res) => {
                    if (mounted) {
                        setSelectJenis(res.data.jenis)
                        setSelectAkses(res.data.akses)
                        setSelectLegalitas(res.data.legalitas)
                        if (params.page > res.data.meta.last_page) {
                            history.push('/listing-semua/'+res.data.meta.last_page)
                        } else {
                            setResult(res)
                        }
                        setSearching(false)
                    }
                },
                (error) => {
                    if (mounted) {
                        setResult(error.response)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [params.page, searching])

    const handleLuasTanah = (event, newValue) => {
        setSearch({
            ...search,
            luas_tanah: newValue
        })
    };

    const handleHarga = (event, newValue) => {
        setSearch({
            ...search,
            harga: newValue
        })
    };

    const handleSelect = (event) => {
        setSearch({
            ...search,
            [event.target.name]: event.target.value
        });
    };

    const handleSearch = () => {
        setResult(null)
        setSearching(true)
    }

    return (
        <Box>
            {/* <Paper elevation={1} sx={{ mb: 2, px: 1, pb: 1, color: "secondary" }}>
                <Typography variant="subtitle1" gutterBottom component="div" align="center">FILTER LISTING</Typography>

                <hr style={{ marginTop: 0 }} />

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography gutterBottom>Luas Tanah : {rupiahNumber(search.luas_tanah[0].toString())} - {rupiahNumber(search.luas_tanah[1].toString())}</Typography>
                            <Box sx={{ px: '10px' }}>
                                <Slider value={search.luas_tanah} min={0} max={50000} onChange={handleLuasTanah} />
                            </Box>

                            <Typography gutterBottom>Harga : {rupiahNumber(search.harga[0].toString())} - {rupiahNumber(search.harga[1].toString())}</Typography>
                            <Box sx={{ px: '10px' }}>
                                <Slider value={search.harga} min={0} max={10000000000} onChange={handleHarga} />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        fullWidth
                                        focused
                                        value={search.kode ?? ''}
                                        label="Kode"
                                        size="small"
                                        placeholder="Silahkan isi"
                                        onChange={(e) => {
                                            setSearch({
                                                ...search,
                                                kode: e.target.value,
                                            })
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                color: 'rgba(0, 0, 0, 0.6) !important'
                                            },
                                            '& .MuiInputBase-root': {
                                                '& fieldset': {
                                                    borderWidth: '1px !important',
                                                    borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                                },
                                                '& legend': {
                                                    float: 'unset !important'
                                                },
                                            },
                                        }} />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Box>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="jenis-select-label">Jenis</InputLabel>
                                            <Select
                                                labelId="jenis-select-label"
                                                id="jenis-select"
                                                value={search.jenis_id}
                                                name="jenis_id"
                                                label="Jenis"
                                                onChange={handleSelect}
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                                                sx={{
                                                    '&.MuiInputBase-root': {
                                                        '& legend': {
                                                            float: 'unset !important'
                                                        },
                                                    },
                                                }}>
                                                <MenuItem value={0}>Semua</MenuItem>
                                                {selectJenis.map((sj, idsj) => {
                                                    return <MenuItem value={sj.value} key={idsj}>{sj.label}</MenuItem>
                                                })}

                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Box>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="akses-select-label">Akses</InputLabel>
                                            <Select
                                                labelId="akses-select-label"
                                                id="akses-select"
                                                value={search.akses_id}
                                                name="akses_id"
                                                label="Akses"
                                                onChange={handleSelect}
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                                                sx={{
                                                    '&.MuiInputBase-root': {
                                                        '& legend': {
                                                            float: 'unset !important'
                                                        },
                                                    },
                                                }}>
                                                <MenuItem value={0}>Semua</MenuItem>
                                                {selectAkses.map((sa, idsa) => {
                                                    return <MenuItem value={sa.value} key={idsa}>{sa.label}</MenuItem>
                                                })}

                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        focused
                                        value={search.alamat ?? ''}
                                        label="Alamat"
                                        size="small"
                                        placeholder="Silahkan isi"
                                        onChange={(e) => {
                                            setSearch({
                                                ...search,
                                                alamat: e.target.value,
                                            })
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                color: 'rgba(0, 0, 0, 0.6) !important'
                                            },
                                            '& .MuiInputBase-root': {
                                                '& fieldset': {
                                                    borderWidth: '1px !important',
                                                    borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                                },
                                                '& legend': {
                                                    float: 'unset !important'
                                                },
                                            },
                                        }} />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="legalitas-select-label">Legalitas</InputLabel>
                                            <Select
                                                labelId="legalitas-select-label"
                                                id="legalitas-select"
                                                value={search.legalitas_id}
                                                name="legalitas_id"
                                                label="Legalitas"
                                                onChange={handleSelect}
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                                                sx={{
                                                    '&.MuiInputBase-root': {
                                                        '& legend': {
                                                            float: 'unset !important'
                                                        },
                                                    },
                                                }}>
                                                <MenuItem value={0}>Semua</MenuItem>
                                                {selectLegalitas.map((sl, idsl) => {
                                                    return <MenuItem value={sl.value} key={idsl}>{sl.label}</MenuItem>
                                                })}

                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>


                <hr style={{ marginTop: 4 }} />

                <MuiButton disabled={result ? false : true} color="info" onClick={() => handleSearch()}>Cari</MuiButton>
            </Paper> */}

            <RenderIf isTrue={result === null}>
                <Grid container spacing={3}>
                    <CardListing />
                    <CardListing />
                </Grid>
            </RenderIf>

            <RenderIf isTrue={result !== null}>
                <RenderOk response={result}>
                    {result && (
                        <>
                            <Grid container spacing={3}>
                                {result.data.data.map((value, idx) => (
                                    <CardListing key={idx} data={value}/>
                                ))}

                                <RenderIf isTrue={result.data.data.length < 1}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ py: 3 }}>
                                            <Typography align="center">Data tidak ditemukan.</Typography>
                                        </Paper>
                                    </Grid>
                                </RenderIf>
                            </Grid>

                            <hr />

                            {result && <Grid container spacing={2}>
                                <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }} display="flex" justifyContent={{lg: "flex-start", xs: "center"}}>
                                    <Typography>Showing {result.data.meta.from} to {result.data.meta.to} of {result.data.meta.total} entries</Typography>
                                </Grid>

                                <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }} display="flex" justifyContent={{lg: "flex-end", xs: "center"}}>
                                    <Paper elevation={0} sx={{ py: '6px', px: '3px'}}>
                                        {result && result.data.meta.links.map((vlink, keylink) => {
                                            if (Number(vlink.label)) {
                                                return (
                                                    <PaginationItem
                                                        key={keylink}
                                                        aria-current={vlink.active == true ? "true" : undefined}
                                                        aria-label={vlink.active == true ? `page ${vlink.label}` : `Go to page ${vlink.label}`}
                                                        color="primary"
                                                        disabled={false}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            setResult(null)
                                                            history.push('/listing-semua/'+vlink.url.split("page=")[1])
                                                        }}
                                                        page={Number(vlink.label)}
                                                        selected={vlink.active == true ? true : false}
                                                        variant={vlink.active == true ? "text" : "outlined"}
                                                        shape="rounded"
                                                        size="medium"
                                                        type="page"
                                                        />
                                                )
                                            } else {
                                                if (keylink == 0) {
                                                    return (
                                                        <PaginationItem
                                                            key={keylink}
                                                            aria-label="Go to previous page"
                                                            color="primary"
                                                            disabled={vlink.url == null ? true : vlink.active}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                setResult(null)
                                                                history.push('/listing-semua/'+vlink.url.split("page=")[1])
                                                            }}
                                                            page={vlink.label}
                                                            selected={vlink.active}
                                                            variant="outlined"
                                                            shape="rounded"
                                                            size="medium"
                                                            type="previous"
                                                            />
                                                    )
                                                } else if (keylink+1 == result.data.meta.links.length) {
                                                    return (
                                                        <PaginationItem
                                                            key={keylink}
                                                            aria-label="Go to next page"
                                                            color="primary"
                                                            disabled={vlink.url == null ? true : vlink.active}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                setResult(null)
                                                                history.push('/listing-semua/'+vlink.url.split("page=")[1])
                                                            }}
                                                            page={vlink.label}
                                                            selected={vlink.active}
                                                            variant="outlined"
                                                            shape="rounded"
                                                            size="medium"
                                                            type="next"
                                                            />
                                                    )
                                                } else {
                                                    return (
                                                        <PaginationItem
                                                            key={keylink}
                                                            aria-label={`Go to page ${vlink.label}`}
                                                            color="primary"
                                                            disabled={true}
                                                            page={'...'}
                                                            selected={false}
                                                            variant="text"
                                                            shape="rounded"
                                                            size="medium"
                                                            type="page"
                                                            />
                                                    )
                                                }
                                            }
                                        })}
                                    </Paper>
                                </Grid>
                            </Grid>}

                            {/* <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <RenderIf isTrue={result.data.meta.to != result.data.meta.total ? true : false}>
                                    <MuiButton color="success" onClick={() => handleNext()}>{loading ? 'Loading ...' : 'Tampilkan Lainnya'}</MuiButton>
                                </RenderIf>

                                <RenderIf isTrue={result.data.meta.per_page != perPage ? true : false}>
                                    <MuiButton variant="outlined" onClick={() => handlePrev()}>Ke Semula</MuiButton>
                                </RenderIf>
                            </Stack> */}
                        </>
                    )}
                </RenderOk>
            </RenderIf>
        </Box>
    )
}

export default All
