import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const SelectMulti = (props) => {
    const [controlBorderColor, setControlBorderColor] = useState('#b1b7c1')
    const [options, setOptions] = useState([])
    const [isInvalid, setIsInvalid] = useState('')
    const [value, setValue] = useState([])

    useEffect(() => {
        if (props.options.length) {
            setOptions(props.options)
        }

        if (props.invalid) {
            setIsInvalid('is-invalid')
            setControlBorderColor('#e55353')
        } else {
            setIsInvalid('')
            setControlBorderColor('#b1b7c1')
        }

        if (props.value) {
            setValue(props.value)
        }
    }, [props.options, props.invalid, props.value])

    const styles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: 'white',
            minHeight: '29px',
            // height: '31px',
            borderColor: controlBorderColor,
            boxShadow: state.isFocused ? null : null,
            borderRadius: '3.2px 0 0 3.2px'
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                padding: '3px 4px',
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? data.color
                : isFocused
                ? '#A2DBFA'
                : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                ? 'red'
                ? 'white'
                : 'black'
                : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                ? isSelected
                    ? data.color
                    : 'blue'
                : undefined,
            },
            };
        },

        valueContainer: (provided, state) => ({
            ...provided,
            // height: '31px',
            padding: '4px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            padding: '0px 6px',
            fontSize: '0.875rem'
        }),

        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '29px',
        }),

        multiValue: (base, state) => {
            return {
                ...base,
                backgroundColor: state.data.isFixed ? 'grey' : '#D8DBE0',
                margin: '0',
                marginRight: '2px'
            };
        },

        multiValueLabel: (base, state) => ({
            ...base,
            color: state.data.isFixed ? 'white' : state.data.color,
            padding: 0,
            paddingRight: 6
        }),

        // multiValueRemove: (styles, { data }) => {
        //     return {
        //         ...styles,
        //         color: data.color,
        //         ':hover': {
        //             backgroundColor: data.color,
        //             color: 'red',
        //         },
        //     }
        // },

        multiValueRemove: (base, state) => {
            return {
                ...base,
                display: state.data.isFixed ? 'none' : 'flex'
            }
        },

        menu: (provided, state) => {
            return {
                ...provided,
                padding: 0,
                margin: 0,
                borderRadius: '3.2px 0 3.2px 3.2px'
            }
        },

        menuList: (provided, state) => {
            return {
                ...provided,
                padding: '2px 4px 4px 4px',
                marginTop: '4px',
                fontSize: '0.875rem',
                maxHeight: 100
            }
        },

        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                padding: '0 4px',
                fontSize: '0.875rem'
            }
        },

        noOptionsMessage: (provided) => {
            return {
                ...provided,
                padding: "4px 0"
            }
        }
    };

    return  <Select
                isMulti
                value={value}
                styles={styles}
                options={options}
                components={animatedComponents}
                onChange={(e) => props.onChange(e)}
                isClearable={value.some((v) => !v.isFixed)}
                className={`basic-multi-select ${isInvalid}`}
                classNamePrefix="select"
                placeholder={props.placeholder ?? "Please select"}
                />
}

export default SelectMulti
