import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { CCollapse, CListGroup } from '@coreui/react'
import { Element } from 'react-scroll'
import { routePages } from '@routes'
import routeName from '@layout/routeName'
import routePermission from '@layout/routePermission'
import * as FaIcons from 'react-icons/fa';


const SidebarNavigation = ({ permissions, sidebarShow, onClickBars }) => {
    let navs = [];
    let location = useLocation()

    const [linkActive, setLinkActive] = useState(location.pathname)

    routePages.map((route) => {
        if (route.items) {
            let items = [];
            route.items.map((sub) => {
                // check nav in permission
                if (routePermission(permissions, sub.to) && sub.component) {
                    items.push({
                        ...sub,
                        name: routeName(permissions, sub.to).split(" ").filter((vf) => vf != route.name).join(" ") ?? sub.name
                    });
                }
            })

            if (items.length > 0) {
                navs.push({ ...route, items });
            }
        } else {
            // check nav in permission
            if (routePermission(permissions, route.to) && route.component) {
                navs.push({
                    ...route,
                    name: routeName(permissions, route.to) ?? route.name
                });
            }
        }
    })

    return (
        <Element className="sidebar-nav">
            <CListGroup>
                {navs.map((nav, i) => <Navigation nav={nav} linkActive={linkActive} setLinkActive={setLinkActive} sidebarShow={sidebarShow} onClickBars={onClickBars} key={i} />)}
            </CListGroup>
        </Element>
    )
}

const Navigation = ({ nav, linkActive, setLinkActive, sidebarShow, onClickBars }) => {
    return nav.items ? <NavGroup nav={nav} linkActive={linkActive} setLinkActive={setLinkActive} sidebarShow={sidebarShow} onClickBars={onClickBars} /> : <NavItem nav={nav} setLinkActive={setLinkActive} sidebarShow={sidebarShow} onClickBars={onClickBars} />;
}

const NavItem = ({ nav, setLinkActive, sidebarShow, onClickBars }) => {
    return (
        <li className="nav-item">
            <NavLink
                to={nav.to.replace("/:page", "")}
                onClick={() => {
                    setLinkActive(nav.to)
                    if (window.innerWidth < 768) {
                        onClickBars(sidebarShow)
                    }
                }}
                className="nav-link user-select-none">
                {nav.icon ?? <FaIcons.FaRegCircle className="nav-icon" />}
                <span style={{ fontSize: 14 }}>{nav.name}</span>
            </NavLink>
        </li>
    )
}

const NavGroup = ({ nav, linkActive, setLinkActive, sidebarShow, onClickBars  }) => {
    let showFirst = false
    let location = useLocation()
    const [show, setShow] = useState(false)

    if (nav.items) {
        nav.items.map((vsf) => {
            let match = matchPath(linkActive, {
                path: vsf.to,
            });

            if (match !== null) {
                showFirst  = true
            }
        })
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (nav.to == linkActive) {
                setShow(true)
            } else {
                if (nav.items) {
                    let itemActive = 0
                    nav.items.map((vsf) => {
                        let match = matchPath(location.pathname, {
                            path: vsf.to,
                        });

                        if (match !== null) {
                            itemActive  += 1
                        }
                    })
                    setShow(itemActive === 0 ? false : true)
                } else {
                    setShow(false)
                }
            }
        }
        return () => mounted = false
    }, [showFirst, linkActive, location.pathname])

    const handleShow = () => {
        if (showFirst) {
            setShow(false)
            setLinkActive('')
        } else {
            if (!show) {
                setLinkActive(nav.to)
            }

            setShow(!show)
        }
    }

    return (
        <li className={showFirst || show ? "nav-group show" : "nav-group mb-0"}>
            <a className={`nav-link nav-group-toggle user-select-none${showFirst || show ? ' active' : ''}`} onClick={() => handleShow()}>
                {nav.icon}
                <span style={{ fontSize: 14 }}>{nav.name}</span>
            </a>

            <CCollapse className="nav-group-item" visible={showFirst || show}>
                <CListGroup>
                    {nav.items.map((sub, idx) => <NavItem nav={sub} setLinkActive={setLinkActive} sidebarShow={sidebarShow} onClickBars={onClickBars} key={idx} />)}
                </CListGroup>
            </CCollapse>
        </li>
    )
}

const mapStateToProps = ({ layout }) => {
    return {
        sidebarShow: layout.sidebarShow,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClickBars: (sidebarShow) => dispatch({ type: "LAYOUT_SIDEBAR_SHOW", sidebarShow: !sidebarShow }),
    }
}

export default React.memo(connect( mapStateToProps, mapDispatchToProps )(SidebarNavigation))
