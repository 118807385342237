import React from 'react'
import { connect } from "react-redux";
import { CContainer, CHeader, CHeaderDivider, CHeaderToggler } from '@coreui/react'
import * as FaIcons from 'react-icons/fa';
import AppBreadcrumb from './AppBreadcrumb'
import HeaderBrand from './HeaderBrand';
import HeaderInfo from './HeaderInfo';
import HeaderUser from './HeaderUser';

const Header = ({ history, roles, foto, sidebarShow, appName, onClickBars }) => {
    return (
        <CHeader position="sticky" className="mb-3">
            <CContainer fluid>
                <CHeaderToggler className="ps-1 me-auto" onClick={() => onClickBars(sidebarShow)}>
                    <FaIcons.FaBars className='icon icon-lg' />
                </CHeaderToggler>

                <HeaderBrand appName={appName} />

                <HeaderInfo />

                <HeaderUser history={history} roles={roles} foto={foto} />
            </CContainer>

            {/* <CHeaderDivider />

            <CContainer fluid>
                <AppBreadcrumb />
            </CContainer> */}
        </CHeader>
    )
}

const mapStateToProps = state => {
    return {
        roles: state.auth.roles,
        foto: state.auth.foto,
        sidebarShow: state.layout.sidebarShow,
        appName: state.layout.appName,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClickBars: (sidebarShow) => dispatch({ type: "LAYOUT_SIDEBAR_SHOW", sidebarShow: !sidebarShow }),
    }
}

export default React.memo(connect( mapStateToProps, mapDispatchToProps )(Header))
