// const color1 = '#8196AB'
// const color2 = '#57728F'
// const color3 = '#3E5073'
// const color4 = '#24405C'
// const color5 = '#1B3045'

// const color1 = '#D5E5F5'
// const color2 = '#AECEF4'
// const color3 = '#75A4DA'
// const color4 = '#488FDD'
// const color5 = '#3781E3'

const color1 = '#E6ECEB'
const color2 = '#A0C5CF'
const color3 = '#63CDD7'
const color4 = '#0093B7'
const color5 = '#005F89'

export {
    color1,
    color2,
    color3,
    color4,
    color5,
}
