import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux';
import { Box, InputAdornment, IconButton } from '@mui/material'
import { AccountCircle, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiAlert, MuiLink, MuiButtonLoading, MuiCheckbox, MuiTextField } from '@components/mui'
import { RenderIf } from '@utils/render'
import { errorValidation, validationData } from '@hooks/form'
import { color1, color5 } from '@configs/colors'
import AuthService from '@services/auth.service';
import logo from '@assets/images/login.png'

const Login = ({ theme, changeTheme }) => {
    const dispatch = useDispatch();

    const demo = false
    const register = false
    const darkmode = false

    const [submit, setSubmit] = useState(false);

    const [alertMsg, setAlertMsg] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);

    const [formData, setFormData] = useState({
        username: {
            value: '',
            label: "Username",
            placeholder: "Username",
            required: true,
            fullWidth: true,
            theme: "white",
        },
        password: {
            value: '',
            type: passwordShow ? "text" : "password",
            label: "Password",
            placeholder: "Password",
            required: true,
            fullWidth: true,
            theme: "white",
        },
        remember: {
            value: '',
            theme: 'white',
            // label: {
            //     name: 'Remember',
            //     asterik: false
            // },
            checkbox: {
                0: {
                    checked: false,
                    name: 'remember',
                    value: '1',
                    label: 'Ingatkan Saya'
                },
            }
        }
    })

    const handleSubmitForm = async (event) => {
        event.preventDefault()

        setAlertMsg('')
        validationData(formData)
        .then((res) => {
            if (!submit) {
                setSubmit(true)
                AuthService.login(res)
                .then(
                    (data) => {
                        dispatch({
                            type: "AUTH_LOGIN",
                            data: data
                        })
                    },
                    (error) => errorValidation(error, formData, setFormData, setAlertMsg)
                )
                .finally(() => setSubmit(false));
            }
        })
        .catch((err) => errorValidation(err, formData, setFormData))
    }

    return (
        <div className="min-vh-100 row m-0" style={{ backgroundColor: color1 }}>
            <MuiLink color="info" className="d-none d-lg-flex fw-bold ps-5" sx={{ fontSize: 35, position: 'absolute', top: '2rem' }}>Agent Binpro</MuiLink>

            <div className="d-none d-lg-flex col-lg-8 align-items-center p-5">
                <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <img className='img-fluid' src={logo} alt='Login Cover' />
                </div>
            </div>

            <div className="d-flex col-lg-4 align-items-center px-4 p-lg-5" style={{ backgroundColor: color5 }}>
                <div className="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto text-white">
                    <h2 className="card-title fw-bold mb-4">Selamat Datang di Agent Binpro! 👋</h2>

                    <p className="card-text mb-2">Silakan masuk ke akun anda.</p>

                    <RenderIf isTrue={alertMsg !== ''}>
                        <MuiAlert severity="error">{alertMsg}</MuiAlert>
                    </RenderIf>

                    <Box component="form" onSubmit={handleSubmitForm} autoComplete="off" noValidate sx={{ mt: 1 }}>
                        <MuiTextField
                            id="username"
                            data={formData}
                            setData={setFormData}
                            InputLabelProps={{
                                required: false
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            />

                        <MuiTextField
                            id="password"
                            data={formData}
                            setData={setFormData}
                            InputLabelProps={{
                                required: false
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setPasswordShow(!passwordShow)
                                                setFormData({
                                                    ...formData,
                                                    password: {
                                                        ...formData.password,
                                                        type: !passwordShow ? "text" : "password",
                                                    },
                                                });
                                            }}
                                            edge="end">
                                            {passwordShow ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            sx={{
                                marginBottom: '2rem'
                            }}
                            />

                        <MuiCheckbox id="remember" data={formData} setData={setFormData} />

                        <MuiButtonLoading type="submit" color="login" loading={submit} fullWidth={true} sx={{ mt: 3, mb: 2 }}>Login</MuiButtonLoading>
                    </Box>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    return {
        theme: auth.themeDark,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeTheme: (theme) =>  dispatch({ type: "AUTH_THEME_DARK", themeDark: theme }),
    }
}

export default connect( mapStateToProps, mapDispatchToProps )(Login);
