import React from 'react'
import { matchPath, useLocation } from 'react-router-dom';

const ContentTitle = ({ router }) => {
    let title    = 'Page Not Found'
    let location = useLocation()

    router.map((r) => {
        let match = matchPath(location.pathname, {
            path: r.path,
            exact: r.exact ?? false,
        });

        if (match !== null) {
            title  = r.name
        }
    })

    if (location.pathname == '/account-profile') {
        title = 'Profile'
    }

    if (location.pathname == '/ubah-password') {
        title = 'Ubah Password'
    }

    if (location.pathname == '/ubah-foto') {
        title = 'Ubah Foto'
    }

    return <h4>{title}</h4>
}

export default ContentTitle
