import React, { useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CCol, CContainer, CRow, CSpinner } from '@coreui/react';

const LoadingData = ({ children, data, refresh }) => {
    const [result, setResult] = useState(null)

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (data) {
                setTimeout(() => {
                    setResult(data)
                }, 50);
            }
        }
        return () => mounted = false
    }, [data]);


    if (result) {
        if (result.status === 200) {
            return children;
        } else {

            return (
                <CCard>
                    <CCardBody>
                        <CContainer>
                            <CRow className="justify-content-center">
                                <CCol xs="auto">
                                    <div className="clearfix">
                                        <h1 className="float-start display-3 me-4">{result.status ?? '404'}</h1>
                                        <h4 className="pt-3">Oops! {result.message ?? "You're lost"}.</h4>
                                    </div>
                                    <div className='d-grid'>
                                        <p className="text-medium-emphasis float-start">The page you are looking for was not found.</p>
                                       {refresh && typeof refresh === 'function' && <CButton color='success' onClick={() => {setResult(null); refresh()}} size='sm' className='text-light'>Refresh</CButton>}
                                    </div>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CCardBody>
                </CCard>
            );
        }
    } else {
        return (
            <CCard>
                <CCardBody>
                    <div className="d-flex justify-content-center align-items-center">
                        <CRow>
                            <CCol xs="auto" className='my-5 d-flex align-items-center'>
                                <CSpinner component="span" color="dark" aria-hidden="true" />
                                &nbsp; &nbsp;
                                <span className='text-lg'>Loading ...</span>
                            </CCol>
                        </CRow>
                    </div>
                </CCardBody>
            </CCard>
        )
    }
};

export default LoadingData;
