import routeTo from './routeTo'

const routeName = (permissions, to) => {
    if (to === '/dashboard/:id/show') {
        return 'Detail Listing';
    } else {
        return permissions.filter((permission) => permission.name == routeTo(to)).shift().display;
    }
}

export default routeName
