import React, { useEffect, useState } from 'react'
import ResourceService from '@services/resource.service'
import { RCard, RCardBody } from '@components/card'
import { RLoadingData } from '@components/loading'
import { RDataTable } from '@components/table'
import { rupiahTextHalfConvert } from '@hooks/convert'
import columns from './columns'

const Request = () => {
    const url = "/listing-verifikasi"
    const paginate = 10

    const [data, setData] = useState([]);
    const [result, setResult] = useState(null);
    const [table, setTable] = useState({
        url: url,
        params: {
            paginate: paginate
        },
        attr: {
        },
        default: {
            url: url,
            paginate: paginate,
        }
    });

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            ResourceService.table(table.url, table.params)
            .then(
                ({ data, ...res }) => {
                    if (mounted) {
                        let newData = [];
                        const { data: getData, ...attrs } = data

                        getData.map((nd) => {
                            newData.push({
                                ...nd,
                                harga: "Rp. "+rupiahTextHalfConvert(nd.harga.toString())
                            })
                        })

                        setTable({
                            ...table,
                            attr: {
                                ...table.attr,
                                ...attrs
                            }
                        })
                        setData(newData)
                        setResult(res)
                    }
                },
                (error) => {
                    if (mounted) {
                        setResult(error)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [table.refresh])

    return (
        <RLoadingData result={result}>
            <RCard>
                <RCardBody>
                    <RDataTable columns={columns} data={data} table={table} setTable={setTable} />
                </RCardBody>
            </RCard>
        </RLoadingData>
    )
}

export default Request
