import { createTheme } from '@mui/material/styles';
import { color2, color3, color4 } from './colors'
import { green, purple } from '@mui/material/colors';

const themeLight = createTheme({
    palette: {
        mode: "light",
        login: {
            main: color3,
            light: color2,
            dark: color4,
            contrastText: '#FFF'
        }
    },
});

const themeDark = createTheme({
    palette: {
        mode: "dark",
    },
});

export { themeLight, themeDark }
