import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { CCol, CRow } from '@coreui/react'
import { RAnchorSecondary } from '@components/anchor'
import { RCard, RCardBody, RCardFooter } from '@components/card'
import { RForm, RFormInput, RFormInputFile, RFormSelect, RFormTextarea } from '@components/form'
import { RLoadingData } from '@components/loading'
import { RButtonLoading } from '@components/button'
import { rupiahNumberConvert } from '@hooks/convert'
import { formValidation } from '@hooks/validation'
import { errorResponse, successResponse } from '@hooks/response'
import ResourceService from '@services/resource.service'

const Form = () => {
    let params = useParams();
    let history = useHistory();

    const url = '/listing-saya'
    const [result, setResult] = useState(null);

    const [submit, setSubmit] = useState(false);
    const [invalid, setInvalid] = useState({});

    const [listingJenis, setListingJenis] = useState([])
    const [listingLegalitas, setListingLegalitas] = useState([])
    const [listingAkses, setListingAkses] = useState([])
    const [listingProvinsi, setListingProvinsi] = useState([])
    const [listingKokab, setListingKokab] = useState([])
    const [listingKecamatan, setListingKecamatan] = useState([])
    const [listingKelurahan, setListingKelurahan] = useState([])

    const [editFirstProvinsi, setEditFirstProvinsi] = useState(true)
    const [editFirstKokab, setEditFirstKokab] = useState(true)
    const [editFirstKecamatan, setEditFirstKecamatan] = useState(true)

    const [jenis, setJenis] = useState({
        id: 'jenis',
        label: 'Jenis Properti',
        required: true,
        placeholder: 'Jenis Properti'
    });
    const [judul, setJudul] = useState({
        id: 'judul',
        label: 'Judul',
        required: true,
        placeholder: 'Judul'
    });
    const [luasTanah, setLuasTanah] = useState({
        id: 'luas_tanah',
        label: 'Luas Tanah',
        // labelSymbol: 'm<sup>2</sup>',
        valueType: 'number',
        required: true,
        placeholder: 'Luas Tanah'
    });
    const [luasBangunan, setLuasBangunan] = useState({
        id: 'luas_bangunan',
        label: 'Luas Bangunan',
        // labelSymbol: 'm<sup>2</sup>',
        valueType: 'number',
        required: true,
        placeholder: 'Luas Bangunan'
    });
    const [kamarTidur, setKamarTidur] = useState({
        id: 'kamar_tidur',
        label: 'Jumlah Kamar Tidur',
        valueType: 'number',
        required: true,
        placeholder: 'Jumlah Kamar Tidur'
    });
    const [kamarMandi, setKamarMandi] = useState({
        id: 'kamar_mandi',
        label: 'Jumlah Kamar Mandi',
        valueType: 'number',
        required: true,
        placeholder: 'Jumlah Kamar Mandi'
    });
    const [provinsi, setProvinsi] = useState({
        id: 'provinsi',
        label: 'Provinsi',
        required: true,
        placeholder: 'Provinsi',
    });
    const [kokab, setKokab] = useState({
        id: 'kokab',
        label: 'Kota/Kabupaten',
        required: true,
        placeholder: 'Kota/Kabupaten',
        disabled: params.id ? false : true
    });
    const [kecamatan, setKecamatan] = useState({
        id: 'kecamatan',
        label: 'Kecamatan',
        required: true,
        placeholder: 'Kecamatan',
        disabled: params.id ? false : true
    });
    const [kelurahan, setKelurahan] = useState({
        id: 'kelurahan',
        label: 'Desa/Kelurahan',
        required: true,
        placeholder: 'Desa/Kelurahan',
        disabled: params.id ? false : true
    });
    const [lokasi, setLokasi] = useState({
        id: 'lokasi',
        label: 'Alamat',
        required: true,
        placeholder: 'Alamat',
        style: {
            height: 94,
        }
    });
    const [legalitas, setLegalitas] = useState({
        id: 'legalitas',
        label: 'Legalitas',
        required: true,
        placeholder: 'Legalitas'
    });
    const [harga, setHarga] = useState({
        id: 'harga',
        label: 'Harga',
        // labelSymbol: 'm<sup>2</sup>',
        valueType: 'rupiah',
        required: true,
        placeholder: 'Harga'
    });
    const [noShm, setNoShm] = useState({
        id: 'no_shm',
        label: 'No SHM',
        required: true,
        placeholder: 'No SHM'
    });
    const [noSppt, setNoSppt] = useState({
        id: 'no_sppt',
        label: 'No SPPT',
        required: true,
        placeholder: 'No SPPT'
    });
    const [akses, setAkses] = useState({
        id: 'akses',
        label: 'Akses',
        required: true,
        placeholder: 'Akses'
    });
    const [map, setMap] = useState({
        id: 'map',
        label: 'Map',
        required: true,
        placeholder: 'Map'
    });
    const [deskripsi, setDeskripsi] = useState({
        id: 'deskripsi',
        label: 'Deskripsi',
        required: true,
        placeholder: 'Deskripsi',
        style: {
            height: 94,
        }
    });
    const [form, setForm] = useState({
        id: 'form',
        label: 'Formulir',
        type: 'file',
        accept: 'image',
        required: params.id ? false : true,
        multiple: false,
        size: 120480
    });
    const [berkas, setBerkas] = useState({
        id: 'berkas',
        label: 'Berkas',
        type: 'file',
        accept: 'image',
        required: params.id ? false : true,
        multiple: false,
        size: 120480
    });
    const [video, setVideo] = useState({
        id: 'video',
        label: 'Video',
        type: 'file',
        accept: 'video',
        multiple: false,
        size: 120480
    });

    // form
    useEffect(() => {
        let mounted = true;
        const getData = () => {
            let route = params.id ? `${url}/${params.id}/edit` : url+"/create"
            ResourceService.get(route)
            .then(
                ({data, ...res}) => {
                    if (mounted) {
                        if (params.id && data.data) {
                            setListingJenis(data.jenis)
                            setListingLegalitas(data.legalitas)
                            setListingAkses(data.akses)
                            setListingProvinsi(data.provinsi)

                            setJenis({...jenis, value: {value: data.data.jenis, label: data.jenis.filter((e) => e.value == data.data.jenis).shift().label}})
                            setJudul({...judul, value: data.data.judul})
                            setLuasTanah({...luasTanah, value: data.data.luas_tanah})
                            setLuasBangunan({...luasBangunan, value: data.data.luas_bangunan})
                            setKamarTidur({...kamarTidur, value: data.data.kamar_tidur})
                            setKamarMandi({...kamarMandi, value: data.data.kamar_mandi})
                            setKelurahan({...kelurahan, value: {value: data.data.kelurahan, label: data.kelurahan.filter((e) => e.value == data.data.kelurahan).shift().label}})
                            setKecamatan({...kecamatan, value: {value: data.data.kecamatan, label: data.kecamatan.filter((e) => e.value == data.data.kecamatan).shift().label}})
                            setKokab({...kokab, value: {value: data.data.kokab, label: data.kokab.filter((e) => e.value == data.data.kokab).shift().label}})
                            setProvinsi({...provinsi, value: {value: data.data.provinsi, label: data.provinsi.filter((e) => e.value == data.data.provinsi).shift().label}})
                            setLokasi({...lokasi, value: data.data.lokasi})
                            setLegalitas({...legalitas, value: {value: data.data.legalitas, label: data.legalitas.filter((e) => e.value == data.data.legalitas).shift().label}})
                            setHarga({...harga, value: rupiahNumberConvert(data.data.harga)})
                            setNoShm({...noShm, value: data.data.no_shm})
                            setNoSppt({...noSppt, value: data.data.no_sppt})
                            setAkses({...akses, value: {value: data.data.akses, label: data.akses.filter((e) => e.value == data.data.akses).shift().label}})
                            setMap({...map, value: data.data.map})
                            setDeskripsi({...deskripsi, value: data.data.deskripsi})
                        } else {
                            setListingJenis(data.jenis)
                            setListingLegalitas(data.legalitas)
                            setListingAkses(data.akses)
                            setListingProvinsi(data.provinsi)
                        }
                        setResult(res);
                    }
                },
                (error) => {
                    if (mounted) {
                        setResult(error)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [])

    // provinsi
    useEffect(() => {
        if (provinsi.value) {
            let mounted = true;
            const getData = () => {
                ResourceService.get(url+'/support', {from: 'kokab', provinsi: provinsi.value.value})
                .then(
                    ({data}) => {
                        if (mounted) {
                            setListingKokab(data);
                        }
                    },
                    (error) => setListingKokab([])
                )
            }
            if (params.id) {
                if (editFirstProvinsi) {
                    setEditFirstProvinsi(false)
                    setKokab({
                        ...kokab,
                        disabled: false
                    })
                } else {
                    setKokab({
                        ...kokab,
                        value: '',
                        disabled: false
                    })
                }
            } else {
                setKokab({
                    ...kokab,
                    value: '',
                    disabled: false
                })
            }
            getData()
            return () =>  {
                mounted = false
            }
        } else {
            setKokab({
                ...kokab,
                value: '',
                disabled: true
            })
            setListingKokab([])
        }

    }, [provinsi.value])

    // kokab
    useEffect(() => {
        if (kokab.value) {
            let mounted = true;
            const getData = () => {
                ResourceService.get(url+'/support', {from: 'kecamatan', kokab: kokab.value.value})
                .then(
                    ({data}) => {
                        if (mounted) {
                            setListingKecamatan(data);
                        }
                    },
                    (error) => setListingKecamatan([])
                )
            }
            if (params.id) {
                if (editFirstKokab) {
                    setEditFirstKokab(false)
                    setKecamatan({
                        ...kecamatan,
                        disabled: false
                    })
                } else {
                    setKecamatan({
                        ...kecamatan,
                        value: '',
                        disabled: false
                    })
                }
            } else {
                setKecamatan({
                    ...kecamatan,
                    value: '',
                    disabled: false
                })
            }
            getData()
            return () =>  {
                mounted = false
            }
        } else {
            setKecamatan({
                ...kecamatan,
                value: '',
                disabled: true
            })
            setListingKecamatan([])
        }
    }, [kokab.value])

    // kecamatan
    useEffect(() => {
        if (kecamatan.value) {
            let mounted = true;
            const getData = () => {
                ResourceService.get(url+'/support', {from: 'kelurahan', kecamatan: kecamatan.value.value})
                .then(
                    ({data}) => {
                        if (mounted) {
                            setListingKelurahan(data);
                        }
                    },
                    (error) => setListingKelurahan([])
                )
            }
            if (params.id) {
                if (editFirstKecamatan) {
                    setEditFirstKecamatan(false)
                    setKelurahan({
                        ...kelurahan,
                        disabled: false
                    })
                } else {
                    setKelurahan({
                        ...kelurahan,
                        value: '',
                        disabled: false
                    })
                }
            } else {
                setKelurahan({
                    ...kelurahan,
                    value: '',
                    disabled: false
                })
            }
            getData()
            return () =>  {
                mounted = false
            }
        } else {
            setKelurahan({
                ...kelurahan,
                value: '',
                disabled: true
            })
            setListingKelurahan([])
        }
    }, [kecamatan.value])

    const handleSubmitForm = async (event) => {
        event.preventDefault()

        const el = {
            judul,
            jenis,
            luasTanah,
            luasBangunan,
            kamarTidur,
            kamarMandi,
            provinsi,
            kokab,
            kecamatan,
            kelurahan,
            lokasi,
            legalitas,
            harga,
            noShm,
            noSppt,
            akses,
            map,
            deskripsi,
            form,
            berkas,
            video
        }

        if (!submit) {
            formValidation(el)
            .then((res) => {
                setSubmit(true)
                setInvalid({})

                if (params.id) {
                    ResourceService.put(url+'/'+params.id+'/update', res)
                    .then(
                        (res) => {
                            successResponse(res)
                            history.push(url)
                        },
                        (error) => {
                            errorResponse(error, setInvalid)
                            setSubmit(false)
                        }
                    )
                } else {
                    ResourceService.post(url+'/store', res)
                    .then(
                        (res) => {
                            successResponse(res)
                            history.push(url)
                        },
                        (error) => {
                            errorResponse(error, setInvalid)
                            setSubmit(false)
                        }
                    )
                }
            })
            .catch((err) => setInvalid(err))
        }
    }

    return (
        <RLoadingData result={result}>
            <RCard>
                <RForm onSubmit={handleSubmitForm}>
                    <RCardBody>
                        <CRow>
                            {/* ----- row 1 ----- */}

                            <CCol md={4}>
                                <div className="mb-2">
                                    <RFormSelect options={listingJenis} value={jenis} setValue={setJenis} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={8}>
                                <div className="mb-2">
                                    <RFormInput value={judul} setValue={setJudul} invalid={invalid} />
                                </div>
                            </CCol>

                            {/* ----- row 2 ----- */}

                           <CCol md={3}>
                                <div className="mb-2">
                                    <RFormInput value={luasTanah} setValue={setLuasTanah} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div className="mb-2">
                                    <RFormInput value={luasBangunan} setValue={setLuasBangunan} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div className="mb-2">
                                    <RFormInput value={kamarTidur} setValue={setKamarTidur} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div className="mb-2">
                                    <RFormInput value={kamarMandi} setValue={setKamarMandi} invalid={invalid} />
                                </div>
                            </CCol>

                            {/* ----- line 3 ----- */}

                            <CCol xs={12}>
                                <CRow>
                                    <CCol md={4}>
                                        <div className="mb-2">
                                            <RFormSelect options={listingProvinsi} value={provinsi} setValue={setProvinsi} invalid={invalid} />
                                        </div>

                                        <div className="mb-2">
                                            <RFormSelect options={listingKokab} value={kokab} setValue={setKokab} invalid={invalid} />
                                        </div>
                                    </CCol>

                                    <CCol md={4}>
                                        <div className="mb-2">
                                            <RFormSelect options={listingKecamatan} value={kecamatan} setValue={setKecamatan} invalid={invalid} />
                                        </div>

                                        <div className="mb-2">
                                            <RFormSelect options={listingKelurahan} value={kelurahan} setValue={setKelurahan} invalid={invalid} />
                                        </div>
                                    </CCol>

                                    <CCol md={4}>
                                        <div className="mb-2">
                                            <RFormTextarea value={lokasi} setValue={setLokasi} invalid={invalid} />
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCol>

                            {/* ----- line 4 ----- */}

                            <CCol xs={12}>
                                <CRow>
                                    <CCol md={9}>
                                        <CRow>
                                            <CCol md={4}>
                                                <div className="mb-2">
                                                    <RFormSelect options={listingLegalitas} value={legalitas} setValue={setLegalitas} invalid={invalid} />
                                                </div>

                                                <div className="mb-2">
                                                    <RFormInput value={harga} setValue={setHarga} invalid={invalid} />
                                                </div>
                                            </CCol>

                                            <CCol md={4}>
                                                <div className="mb-2">
                                                    <RFormInput value={noShm} setValue={setNoShm} invalid={invalid} />
                                                </div>

                                                <div className="mb-2">
                                                    <RFormInput value={noSppt} setValue={setNoSppt} invalid={invalid} />
                                                </div>
                                            </CCol>

                                            <CCol md={4}>
                                                <div className="mb-2">
                                                    <RFormSelect options={listingAkses} value={akses} setValue={setAkses} invalid={invalid} />
                                                </div>

                                                <div className="mb-2">
                                                    <RFormInput value={map} setValue={setMap} invalid={invalid} />
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCol>

                                    <CCol md={3}>
                                        <div className="mb-2">
                                            <RFormTextarea value={deskripsi} setValue={setDeskripsi} invalid={invalid} />
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCol>

                            {/* ----- line 5 ----- */}

                            <CCol md={4}>
                                <RFormInputFile value={form} setValue={setForm} invalid={invalid} />
                                {params.id && <p className='fs-8 text-danger'><em>* Abaikan jika formulir tidak ingin diubah.</em></p>}
                            </CCol>

                            <CCol md={4}>
                                <RFormInputFile value={berkas} setValue={setBerkas} invalid={invalid} />
                                {params.id && <p className='fs-8 text-danger'><em>* Abaikan jika berkas tidak ingin diubah.</em></p>}
                            </CCol>

                            <CCol md={4}>
                                <RFormInputFile value={video} setValue={setVideo} invalid={invalid} />
                                {params.id && <p className='fs-8 text-danger'><em>* Abaikan jika video tidak ingin diubah.</em></p>}
                            </CCol>
                        </CRow>
                    </RCardBody>

                    <RCardFooter>
                        <div className='d-flex justify-content-center'>
                            <RAnchorSecondary href='/listing-saya'>Kembali</RAnchorSecondary>
                            &nbsp;
                            <RButtonLoading type='submit' loading={submit}>Simpan</RButtonLoading>
                            &nbsp;
                            {/* // Tambah lagi */}
                        </div>
                    </RCardFooter>
                </RForm>
            </RCard>
        </RLoadingData>
    )
}

export default Form
