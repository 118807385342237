const columns = [
    {
        th: 'Name',
        td: 'name',
        sortBy: true,
        minWidth: 85
    },
    {
        th: 'Email',
        td: 'email',
        sortBy: true,
        minWidth: 80
    },
    {
        th: 'Username',
        td: 'username',
        sortBy: true,
        minWidth: 115
    },
    {
        th: 'Role',
        td: 'role',
        sortBy: true,
        minWidth: 80
    },
    {
        th: 'Status',
        td: 'status',
        sortBy: true,
        minWidth: 90
    },
]

export default columns
