const formValidation = (elements) => {
    let data = {};
    let error = [];

    for (const [key, value] of Object.entries(elements)) {
        let setMsgError = [];

        if (value.required === true && (value.value === '' || value.value === undefined)) {
            const textRequire = value.type == 'radio' ? ' harus dipilih.' : ' harus diisi.'

            setMsgError.push({
                [key]: value.label + textRequire
            })
        }

        if (value.value && value.type === "email") {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!re.test(value.value.trim())) {
                setMsgError.push({
                    [key]: value.label + ' bukan alamat email.'
                })
            }
        }

        if (value.value && value.type == 'file') {
            let setMsgErrorFile = [];
            const sizeFile = value.size ?? 2048

            Object.values(value.value).map((vFile) => {
                if (value.accept === "image") {
                    if (!vFile.type.match('image.*')) {
                        setMsgErrorFile.push({
                            [key]: value.label + ' harus berupa gambar.'
                        })
                    }
                }

                if (value.accept === "video") {
                    if (!vFile.type.match('video.*')) {
                        setMsgErrorFile.push({
                            [key]: value.label + ' harus berupa video.'
                        })
                    }
                }

                if (vFile.type.match('image.*') || vFile.type.match('video.*')) {
                    if (!((parseInt(vFile.size, 10)/1000).toFixed(0) <= sizeFile)) {
                        setMsgErrorFile.push({
                            [key]: value.label + ' maksimal '+ (sizeFile / 1024).toFixed(0) + 'mb.'
                        })
                    }
                }
            })

            if (setMsgErrorFile.length > 0) {
                setMsgError.push(setMsgErrorFile.shift())
            }
        }

        // optional
        if (value.value && value.valueType === "number") {
            if (!/[0-9]/.test(value.value.trim())) {
                setMsgError.push({
                    [key]: value.label + ' harus angka.'
                })
            }
        }

        // set error
        if (setMsgError.length > 0) {
            error.push(setMsgError.shift());
        }

        // ---------------------------------------------------------

        const keyData = key.split(/(?=[A-Z])/).join('_').toLowerCase()

        if (value.value) {
            if (value.value.value) {
                data[keyData] = value.value.value
            } else {
                if (value.type == 'file') {
                    if (value.multiple) {
                        Object.values(value.value).map((vvv, vvi) => {
                            data[`${keyData}[${vvi}]`] = vvv
                        })
                    } else {
                        data[keyData] = value.value[0] ?? ''
                    }
                } else {
                    if (value.valueType == 'rupiah') {
                        data[keyData] = value.value.replace(/\./g, '')
                    } else {
                        data[keyData] = value.value
                    }
                }
            }
        } else {
            data[keyData] = ''
        }
    }

    return new Promise((resolve, reject) => {
        if (error.length > 0) {
            let resError = {}
            error.map((vErr) => {
                const key = Object.keys(vErr).shift().split(/(?=[A-Z])/).join('_').toLowerCase()
                const value = Object.values(vErr).shift()

                resError[key] = value
            })
            reject(resError)
        } else {
            resolve(data)
        }
    });
}

export default formValidation;
