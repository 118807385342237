import React from 'react'

const pushLink = (event, history, data = false) => {
    event.preventDefault();
    if (!data) {
        history.push(event.target.closest("a").getAttribute('href'));
    } else {
        history.push({
            pathname: event.target.closest("a").getAttribute('href'),
            state: data
        })
    }
}

export default pushLink
