import React from 'react'
import { TextField } from '@mui/material';

const MuiTextField = ({ id, data, setData, sx, ...props }) => {
    if (data[id]) {
        const { theme, value, invalid, label, type, size, variant, required, fullWidth, ...propData } = data[id]

        const thisTheme = theme == 'white' ? 'common.white' : 'common.black'

        return (
            <TextField
                value={value ?? ''}
                error={invalid ? true : false}
                helperText={invalid ?? ""}
                margin="normal"
                type={type ?? "text"}
                size={size ?? "small"}
                variant={variant ?? "outlined"}
                required={required ?? false}
                fullWidth={fullWidth ?? false}
                id={id ?? ""}
                label={label ?? "Label"}
                onChange={(e) => {
                    setData({
                        ...data,
                        [id]: {
                            ...data[id],
                            value: e.target.value,
                            invalid: '',
                        },
                    })
                }}
                sx={{
                    ...sx,
                    '& .MuiInputLabel-root': {
                        color: thisTheme.replace('common.', '')+ ' !important',
                        '& .MuiInputLabel-asterisk' : {
                            color: 'error.main',
                        }
                    },
                    '& .MuiInputBase-root': {
                        '& .MuiInputBase-input': {
                            color: thisTheme,
                        },
                        '& .MuiInputAdornment-root': {
                            '& .MuiSvgIcon-root': {
                                color: thisTheme,
                            }
                        },
                        '& fieldset': {
                            borderColor: thisTheme,
                        },
                        '&:hover fieldset': {
                            borderColor: thisTheme,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: thisTheme,
                        },
                        '& legend': {
                            float: 'unset !important'
                        },
                    },
                    '& .MuiFormHelperText-root': {
                        marginLeft: 0
                    }
                }}
                {...props}
                {...propData} />
        )
    } else {
        return ''
    }
}

export default MuiTextField
