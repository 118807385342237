import React from 'react'
import { useHistory } from "react-router-dom";
import { CButton } from '@coreui/react'

const RAnchorPrimary = ({ children, href, data, ...props }) => {
    let history = useHistory();

    const handleOnClick = (event) => {
        event.preventDefault();

        if (!data) {
            history.push(event.target.closest("a").getAttribute('href'));
        } else {
            history.push({
                pathname: event.target.closest("a").getAttribute('href'),
                state: data
            })
        }
    }

    return (
        <CButton component="a" href={href ?? '#'} onClick={handleOnClick} size='sm' color='primary' {...props}>
            {children}
        </CButton>
    )
}

export default RAnchorPrimary
