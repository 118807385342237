import httpStatusCode from '@configs/httpStatusCode'

const errorValidation = (err, formData, setFormData, setMsg = false) => {
    let setError = {}
    if (err.response) {
        if(httpStatusCode(err.response).errors) {
            for (const [key, data] of Object.entries(httpStatusCode(err.response).errors)) {
                setError[key] = {
                    ...formData[key],
                    invalid: data.shift()
                }
            }
        }

        if(setMsg) {
            setMsg(httpStatusCode(err.response).message)
        }
    } else {
        for (const [key, data] of Object.entries(err)) {
            setError[key] = {
                ...formData[key],
                invalid: data
            }
        }
    }

    setFormData({
        ...formData,
        ...setError
    })
}

export default errorValidation
