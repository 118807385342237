import Axios from 'axios'
import store from '@src/store'

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.withCredentials = true;

const api_url = window.location.origin+'/api';

const post = async (url, data = {}) => {
    const { auth } = store.getState();
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
    }

    return await Axios.post(api_url + url, formData, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response)
}

const show = async (url, data = {}) => {
    const { auth } = store.getState();

    return await Axios.post(api_url + url, data, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response);
}

const put = async (url, data = {}) => {
    const { auth } = store.getState();
    const formData = new FormData();

    formData.append("_method", 'PUT')
    for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
    }

    return await Axios.post(api_url + url, formData, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response);
}

const destroy = async (url, data = {}) => {
    const { auth } = store.getState();
    const formData = new FormData();

    formData.append("_method", 'DELETE')
    for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
    }

    return await Axios.post(api_url + url, formData, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response);
}

const view = async (url, params = {}) => {
    const { auth } = store.getState();

    return await Axios.post(api_url + '/view' + url, params, {
        headers: {
            'Authorization': auth.bearerToken
        }
    })
    .then((response) => response.data);
}

export default { post, show, put, destroy, view }
