const columns = [
    {
        th: 'Kode',
        td: 'kode',
        sortBy: true,
        minWidth: 50
    },
    {
        th: 'Judul',
        td: 'judul',
        sortBy: true,
        minWidth: 90
    },
    {
        th: 'Member',
        td: 'created_by',
        sortBy: true,
        minWidth: 130
    }
]

export default columns
