import React from 'react'
import { Button } from '@mui/material'

const MuiButton = ({ children, color, size, type, sx, variant, fullWidth, ...props }) => {
    return (
        <Button
            type={type ?? "button"}
            size={size ?? "medium"}
            color={color ?? "primary"}
            variant={variant ?? "contained"}
            fullWidth={fullWidth ?? false}
            sx={{
                ...sx,
                lineHeight: 'normal',
                padding: '8px 20px',
                "&:disabled": {
                    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                    color: color ? color+'.contrastText' : "primary.contrastText",
                    backgroundColor: color ? color+'.dark' : "primary.dark"
                }
            }}
            {...props}>
            {children}
        </Button>
    )
}

export default MuiButton
