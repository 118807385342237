import React from 'react'
import { CCardBody } from '@coreui/react'

const RCardBody = ({ children, ...props }) => {
    return (
        <CCardBody className="p-2" {...props}>{children}</CCardBody>
    )
}

export default RCardBody
