import React from 'react'
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material'

const MuiCheckbox = ({ id, data, setData }) => {
    if (data[id]) {
        const { theme, invalid, required, label, group, checkbox } = data[id]

        const thisTheme = theme == 'white' ? 'common.white' : 'common.black'

        const handleChange = (event, idx, value) => {
            let setValue

            if (Object.keys(checkbox).length > 1) {
                setValue = data[id].value.length > 0 ? data[id].value : []

                if (event.target.checked) {
                    setValue.push(value.value)
                } else {
                    setValue = data[id].value.filter((dv) => dv != value.value)
                }
            } else {
                if (Object.keys(checkbox).length == 1) {
                    let setValue = ''

                    if (event.target.checked) {
                        setValue = value.value
                    } else {
                        setValue = ''
                    }
                }
            }

            setData({
                ...data,
                [id]: {
                    ...data[id],
                    value: setValue,
                    invalid: '',
                    checkbox: {
                        ...data[id].checkbox,
                        [idx]: {
                            ...value,
                            checked: event.target.checked,
                        },
                    }
                },
            })
        };

        return (
            <FormControl
                required={required ?? false}
                error={invalid ? true : false}
                component="fieldset"
                variant="outlined">
                {label && <FormLabel component="legend" required={label.asterik ?? true} sx={{ color: thisTheme, marginBottom: 0 }}>{label.name ?? ''}</FormLabel>}

                <FormGroup row={group ?? true}>
                    {Object.keys(checkbox).length > 0 && Object.values(checkbox).map((vd, idx) => {
                        return (
                            <FormControlLabel
                                key={idx}
                                control={
                                    <Checkbox
                                        checked={vd.checked ?? false}
                                        name={vd.name ?? ''}
                                        onChange={(e) => handleChange(e, idx, vd)}
                                        value={vd.value ?? ''}
                                        sx={{
                                            padding: '0 3px 0 9px',
                                            color: invalid ? 'error.main' : thisTheme.replace('common.', ''),
                                            '& .Mui-checked': {
                                                color: thisTheme.replace('common.', ''),
                                            }
                                        }}
                                        />
                                }
                                label={vd.label ?? ''}
                                sx={{
                                    userSelect: 'none',
                                    '& .MuiCheckbox-root': {
                                        '&.Mui-checked': {
                                            color: thisTheme.replace('common.', ''),
                                        }
                                    },
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '13px',
                                        color: invalid ? 'error.main' : thisTheme.replace('common.', ''),
                                    },
                                }}
                            />
                        )
                    })}
                </FormGroup>

                {invalid && <FormHelperText style={{ marginLeft: 0 }}>{invalid}</FormHelperText>}
            </FormControl>
        )
    } else {
        return ''
    }
}

export default MuiCheckbox
