import React from 'react'
import { CCardFooter } from '@coreui/react'

const RCardFooter = ({ children, ...props }) => {
    return (
        <CCardFooter className="p-2" {...props}>{children}</CCardFooter>
    )
}

export default RCardFooter
