import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { CBadge, CCardImage, CCarousel, CCarouselCaption, CCarouselItem, CCol, CImage, CRow } from '@coreui/react';
import { RAnchorLink, RAnchorSecondary, RAnchorSuccess } from '@components/anchor'
import { RButtonPrimary, RButtonDanger, RButtonSuccess } from '@components/button'
import { RCard, RCardBody } from '@components/card'
import { RLoadingData } from '@components/loading'
import { dateConvert, rupiahNumberConvert } from '@hooks/convert'
import { fileDownload } from '@hooks/download'
import Swal from 'sweetalert2'
import ResourceService from '@services/resource.service'
import routeName from '@layout/routeName'
import image from '@assets/images/image.png'
import * as FaIcons from 'react-icons/fa';

const Show = ({ permissions }) => {
    let params = useParams();
    let location = useLocation();
    let history = useHistory();
    let url = location.pathname.replace("/"+params.id+'/show', "")

    const imageHeight = 300
    const [data, setData] = useState([]);
    const [foto, setFoto] = useState([]);
    const [status, setStatus] = useState(0);
    const [result, setResult] = useState(null);

    // Saya
    const [urlFile, setUrlFile] = useState({});
    const [pengajuan, setPengajuan] = useState([]);
    const [downloadFormulir, setDownloadFormulir] = useState([]);
    const [downloadBerkas, setDownloadBerkas] = useState([]);
    const [uploadFoto, setUploadFoto] = useState([]);

    // Pengajuan
    const [batal, setBatal] = useState([]);
    const [verifikasi, setVerifikasi] = useState([]);

    // Verifikasi
    const [closing, setClosing] = useState([]);

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            ResourceService.get(`${url}/${params.id}/show`)
            .then(
                ({ data: thiData, ...res }) => {
                    if (mounted) {
                        const { data: showData, foto } = thiData
                        const { ops_action_row, ops_action_show, ...otherData } = showData;

                        if (ops_action_show) {
                            setUrlFile(ops_action_show.url ?? [])
                            setUploadFoto(ops_action_show.action.foto ?? [])
                            setDownloadFormulir(ops_action_show.action.form ?? [])
                            setDownloadBerkas(ops_action_show.action.berkas ?? [])

                            // Saya
                            setPengajuan(ops_action_show.action.pengajuan ?? [])

                            // Pengajuan
                            setBatal(ops_action_show.action.batal ?? [])
                            setVerifikasi(ops_action_show.action.verifikasi ?? [])

                            // Verifikasi
                            setClosing(ops_action_show.action.closing ?? [])
                        }
                        setStatus(otherData.status_id)
                        setFoto(foto)
                        setData(otherData)
                        setResult(res)
                    }
                },
                (error) => {
                    if (mounted) {
                        setResult(error)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [])

    const handleDownload = (urlDownload, file, mutiple = false) => {
        if (file !== null) {
            if (mutiple) {
                file.map((vm) => {
                    fileDownload(window.location.origin+urlDownload+'/'+vm.name, vm.name)
                })
            } else {
                fileDownload(window.location.origin+urlDownload+'/'+file, file)
            }
        }
    }

    const handleAjukan = () => {
        Swal.fire({
            title: 'Ajukan listing ini ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
            confirmButtonColor: '#e55353',
            focusCancel: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return ResourceService.put(url+'/'+params.id+'/update', { ajukan: true })
                .then(
                    (res) => {
                        if (res.status !== 200) {
                            throw new Error(res.statusText)
                        } else {
                            setStatus(2)
                            return "OK";
                        }
                    },
                    (error) => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    }
                )
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Listing berhasil di ajukan.',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const handleBatal = () => {
        Swal.fire({
            title: 'Batalkan listing ini ?',
            icon: 'warning',
            input: 'text',
            inputLabel: 'Alasan',
            inputAttributes: {
                autocapitalize: 'off',
                required: 'true'
            },
            inputValidator: (value) => {
                if (!value) {
                  return 'Silahakn isi alasan dibatalkan.'
                }
            },
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
            confirmButtonColor: '#e55353',
            focusCancel: true,
            showLoaderOnConfirm: true,
            preConfirm: (res) => {
                return ResourceService.put(url+'/'+params.id+'/update', { description: res, batal: true })
                .then(
                    (res) => {
                        if (res.status !== 200) {
                            throw new Error(res.statusText)
                        } else {
                            history.push(url)
                            return "OK";
                        }
                    },
                    (error) => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    }
                )
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Listing berhasil di batalkan.',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const handleVerifikasi = () => {
        Swal.fire({
            title: 'Verifikasi listing ini ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya!',
            cancelButtonText: 'Tidak',
            confirmButtonColor: '#e55353',
            focusCancel: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return ResourceService.put(url+'/'+params.id+'/update', { verifikasi: true })
                .then(
                    (res) => {
                        if (res.status !== 200) {
                            throw new Error(res.statusText)
                        } else {
                            history.push(url)
                            return "OK";
                        }
                    },
                    (error) => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    }
                )
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Listing berhasil di verifikasi.',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const handleClosing = () => {
        Swal.fire({
            title: 'Closing listing ini ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
            confirmButtonColor: '#e55353',
            focusCancel: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return ResourceService.put(url+'/'+params.id+'/update', { closing: true })
                .then(
                    (res) => {
                        if (res.status !== 200) {
                            throw new Error(res.statusText)
                        } else {
                            history.push(url)
                            return "OK";
                        }
                    },
                    (error) => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    }
                )
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Listing berhasil di closing.',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const handleStatus = (value) => {
        if (value == 4) {
            return <CBadge color="success" className='fw-light mb-2'>Tersedia</CBadge>
        } else if (value == 5) {
            return <CBadge color="danger" className='fw-light mb-2'>Closing</CBadge>
        } else {
            return '';
        }
    }

    return (
        <RLoadingData result={result}>
            <RCard>
                <RCardBody>
                    <div className="mx-4 my-3">
                        {foto.length < 1
                        ?
                        <CCardImage orientation="top" className="mb-4" src={image} style={{ height: imageHeight }} />
                        :
                        <CCarousel
                            controls={foto.length > 1 ? true : false}
                            interval={false}
                            className="mb-4">
                            {foto.map((vFoto, idxFoto) => {
                                return (
                                    <CCarouselItem key={idxFoto}>
                                        <div className="d-flex justify-content-center">
                                            <CImage className="d-block" src={window.location.origin+vFoto.image} alt={`slide ${idxFoto}`} style={{ height: imageHeight }} />
                                        </div>
                                        <CCarouselCaption>
                                            <p className='mb-0 user-select-none text-dark rounded-pill' style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', bottom: '0.5rem' }}>Foto {idxFoto+1}</p>
                                        </CCarouselCaption>
                                    </CCarouselItem>
                                )
                            })}
                        </CCarousel>
                        }

                        <CBadge color="info" className='fw-light mb-2 me-1'>{data.jenis}</CBadge>
                        {handleStatus(data.status_id)}
                        <p className="mb-1 fs-5 fw-bold text-primary">Rp. {rupiahNumberConvert(data.harga)}</p>
                        <p className="mb-0 fw-normal">{data.judul}</p>
                        <p className="mb-2 fs-7 fw-lighter">
                            {data.lokasi}{', '}
                            Kel/Desa <span className="text-capitalize">{data.kelurahan && data.kelurahan.toLowerCase()}</span>{', '}
                            Kecamatan <span className="text-capitalize">{data.kecamatan && data.kecamatan.toLowerCase()}</span>{', '}
                            <span className="text-capitalize">{data.kokab && data.kokab.toLowerCase()}</span>{', '}
                            Provinsi <span className="text-capitalize">{data.provinsi && data.provinsi.toLowerCase()}</span>
                        </p>

                        <div className="d-flex justify-content-start fs-8 mb-3">
                            <div className="me-3"><FaIcons.FaBath /> : {data.kamar_mandi}</div>
                            <div className="me-3"><FaIcons.FaBed /> : {data.kamar_tidur}</div>
                            <div className="me-3">LT : {data.luas_tanah} m<sup>2</sup></div>
                            <div>LB : {data.luas_bangunan} m<sup>2</sup></div>
                        </div>

                        <div>
                            {foto.length > 0 && <RButtonPrimary onClick={() => handleDownload(urlFile.image, foto, true)} className='mb-2 me-2'>Unduh Foto</RButtonPrimary>}
                            {data.video && <RButtonPrimary onClick={() => handleDownload(urlFile.video, data.video)} className='mb-2 me-2'>Unduh Video</RButtonPrimary>}
                            {downloadFormulir.length > 0 && downloadFormulir[0] && <RButtonPrimary onClick={() => handleDownload(urlFile.image, data.form)} className='mb-2 me-2'>Unduh Formulir</RButtonPrimary>}
                            {downloadBerkas.length > 0 && downloadBerkas[0] && <RButtonPrimary onClick={() => handleDownload(urlFile.image, data.berkas)} className='mb-2'>Unduh Berkas</RButtonPrimary>}
                        </div>


                        <hr className="mt-0" />

                        <RAnchorSecondary href={url} className='mb-2 text-white me-2'>{routeName(permissions, url)}</RAnchorSecondary>
                        {/* Saya */}
                        {[1, 3].some((s) => s == status) && pengajuan.length > 0 && pengajuan[0] && <RButtonDanger onClick={() => handleAjukan()} className='mb-2 text-white me-2'>Ajukan Listing</RButtonDanger>}
                        {uploadFoto.length > 0 && uploadFoto[0] && <RAnchorSuccess href={url+'/'+params.id+'/foto'} className='mb-2 text-white'>Akses Foto</RAnchorSuccess>}
                        {/* Pengajuan */}
                        {status == 2 && batal.length > 0 && batal[0] && <RButtonDanger onClick={() => handleBatal()} className='mb-2 text-white me-2'>Batalkan Listing</RButtonDanger>}
                        {status == 2 && verifikasi.length > 0 && verifikasi[0] && <RButtonSuccess onClick={() => handleVerifikasi()} className='mb-2 text-white'>Verifikasi Listing</RButtonSuccess>}
                        {/* Verifikasi */}
                        {status == 4 && closing.length > 0 && closing[0] && <RButtonDanger onClick={() => handleClosing()} className='mb-2 text-white me-2'>Closing Listing</RButtonDanger>}

                        <hr className="mt-0" />

                        <div className="d-flex flex-column">
                            <p className="mb-0 fs-7 fw-light">{data.created_by}</p>
                            <p className="mb-0 fs-8 fw-lighter">Tayang Sejak {dateConvert(data.status_created_at)}</p>
                        </div>

                        { status == 3 &&
                            <>
                                <hr className="mt-0" />

                                <div className="d-flex flex-column">
                                    <p className="mb-0 fs-7 fw-light text-danger">Status Batal</p>
                                    <p className="mb-0 fs-8 text-danger">Alasan : {data.status_description}</p>
                                </div>
                            </>
                        }

                        <hr className="mt-2" />

                        <p className="mb-0 fs-7 fw-lighter">Deskripsi</p>
                        <p style={{ textAlign: 'justify', textJustify: 'inter-word', whiteSpace: 'pre-line' }}>{data.deskripsi}</p>

                        <hr className="mt-2" />

                        <CRow>
                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">Kode</p>
                                <p>{data.kode}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">Jenis Properti</p>
                                <p>{data.jenis}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">Luas Tanah</p>
                                <p>{data.luas_tanah}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">Luas Bangunan</p>
                                <p>{data.luas_bangunan}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">Jumlah Kamar Mandi</p>
                                <p>{data.kamar_mandi}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">Jumlah Kamar Tidur</p>
                                <p>{data.kamar_tidur}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">No SHM</p>
                                <p>{data.no_shm}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">No SPPT</p>
                                <p>{data.no_sppt}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">Legalitas</p>
                                <p>{data.legalitas}</p>
                            </CCol>

                            <CCol sm={6}>
                                <p className="mb-0 fs-7 fw-lighter">Akses</p>
                                <p>{data.akses}</p>
                            </CCol>
                        </CRow>

                        <hr className="mt-0" />

                        <p className="mb-0 fs-7 fw-lighter">Map</p>
                        <p>
                            <RAnchorLink href={data.map} target='_blank'>{data.map.toString().replace(/^https?:\/\//, '')}</RAnchorLink>
                        </p>
                    </div>
                </RCardBody>
            </RCard>
        </RLoadingData>
    )
}

const mapStateToProps = ({ auth }) => {
    return {
        permissions: auth.permissions,
    };
};

export default connect( mapStateToProps, null )(Show)
