import React, { useMemo } from 'react';
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CPagination, CPaginationItem, CRow } from '@coreui/react';
import { useTable, useSortBy } from 'react-table'
import LayoutTable from './LayoutTable';

const DataTable = ({ columns, data, show, setShow, search, setSearch, meta, searching, refreshTable, setSort, defaultTable }) => {
    const getData = useMemo(() => data ?? [], [data]);
    const getColumns = useMemo(() => columns, [columns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns: getColumns,
        data: getData,
        manualSortBy: true,
    }, useSortBy)

    const handleSortBy = (columnHeader) => {
        if (!columnHeader.disableSortBy && columnHeader.orderBy) {
            let data = {}
            const newSort = columns.filter((v) => v.accessor == columnHeader.id).shift();

            if (!newSort.orderBy.asc && !newSort.orderBy.desc) {
                data["params"] = {
                    orderByColumn: newSort.accessor,
                    orderByDirection: 'asc'
                }
            } else {
                if (newSort.orderBy.asc) {
                    data["params"] = {
                        orderByColumn: newSort.accessor,
                        orderByDirection: 'desc'
                    }
                } else {
                    data["params"] = {
                        orderByColumn: newSort.accessor,
                        orderByDirection: 'asc'
                    }
                }
            }

            setSort(newSort)
            refreshTable(data)
        }
    }

    const propsTopTable = {
        data,
        meta,
        show,
        setShow,
        search,
        setSearch,
        refreshTable,
        setSort,
        defaultTable
    }

    const propsLayoutTable = {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page: rows,
        prepareRow,
        columns,
        searching,
        sortBy: (data) => handleSortBy(data)
    }

    const propsBottomTable = {
        data,
        meta,
        refreshTable
    }

    return (
        <>
            <TopTable {...propsTopTable} />

            <LayoutTable {...propsLayoutTable} />

            <BottomTable {...propsBottomTable} />
        </>
    );
};

const TopTable = ({ data, meta, show, setShow, search, setSearch, refreshTable, setSort, defaultTable }) => {
    if (data !== null && meta) {
        return (
            <CRow>
                <CCol lg={5} className='mb-2 d-flex justify-content-center justify-content-lg-start'>
                    <div className='d-flex flex-row align-items-center'>
                        <span className='fs-7'>Show</span>
                        <CFormSelect
                            aria-label="Show"
                            id='show'
                            className='w-auto mx-1 shadow-none'
                            size='sm'
                            value={show}
                            onChange={(e) => {
                                let data = {}

                                data["url"] = defaultTable.url
                                data["params"] = {
                                    paginate: e.target.value,
                                }

                                setShow(e.target.value)
                                refreshTable(data)
                            }}
                            options={[
                                { label: '10', value: '10' },
                                { label: '25', value: '25' },
                                { label: '50', value: '50' },
                                { label: '100', value: '100' },
                                { label: 'All', value: meta.total }
                            ]}
                        />
                        <span className='fs-7'>entries</span>
                    </div>
                </CCol>

                <CCol lg={7} className='d-flex justify-content-center flex-column flex-sm-row justify-content-lg-end'>
                    <div className='mb-2 d-flex flex-row me-1 align-items-center justify-content-center'>
                        <CFormLabel htmlFor="search" className='mb-0 fs-7'>Search :</CFormLabel>
                        <CFormInput
                            type="text"
                            id="search"
                            size='sm'
                            className='w-auto ms-1'
                            value={search}
                            onChange={(e) => {
                                let data = {}

                                data["url"] = defaultTable.url
                                data["params"] = {
                                    search: e.target.value,
                                }

                                setSearch(e.target.value)
                                refreshTable(data)
                            }}
                            />
                    </div>

                    <CButton
                        onClick={() => {
                            let data = {}

                            data["url"] = defaultTable.url
                            data["params"] = {
                                search: '',
                                paginate: defaultTable.paginate,
                                orderByColumn: '',
                                orderByDirection: ''
                            }

                            setSort(null)
                            setSearch('')
                            setShow(defaultTable.paginate)
                            refreshTable(data)
                        }}
                        color="success"
                        type="button"
                        size='sm'
                        className='mb-2 text-light'>
                        Refresh
                    </CButton>
                </CCol>
            </CRow>
        )
    }

    return '';
}

const BottomTable = ({ data, meta, refreshTable }) => {
    if (data !== null && meta) {
        const labelPageItem = (value) => {
            const parser = new DOMParser();
            return parser.parseFromString(`<!doctype html><body>${value}`, 'text/html').body.textContent;
        }

        const changeBody = (value) => {
            let data = {}

            data["url"] = value.split("/api")[1]

            refreshTable(data)
        }

        return (
            <CRow className='mt-2'>
                <CCol lg={{ span: 6, order: 'last' }} className="mb-2 d-flex justify-content-center justify-content-lg-end">
                    <CPagination className='mb-0' align="end" size='sm' aria-label="Page navigation table">
                        {meta.links.map((vlink, keylink) => {
                            return <CPaginationItem onClick={() => changeBody(vlink.url)} className='user-select-none' active={vlink.active} disabled={vlink.url ? false : true} key={keylink}>{labelPageItem(vlink.label)}</CPaginationItem>
                        })}
                    </CPagination>
                </CCol>

                <CCol lg={6} className='mb-2 fs-7 d-flex align-items-center justify-content-center justify-content-lg-start'>
                    Showing {meta.from} to {meta.to} of {meta.total} entries
                </CCol>
            </CRow>
        );
    }

    return '';
}

export default DataTable;
