const columns = [
    {
        th: 'Name',
        td: 'name',
        sortBy: true
    },
    {
        th: 'Email',
        td: 'email',
        sortBy: true
    },
    {
        th: 'Username',
        td: 'username',
        sortBy: true
    },
    {
        th: 'Roles',
        td: 'roles'
    },
    {
        th: 'Permissions',
        td: 'permissions'
    },
    {
        th: 'Status',
        td: 'status_name'
    },
    // {
    //     th: 'Creted At',
    //     td: 'created_at'
    // },
    // {
    //     th: 'Created By',
    //     td: 'created_by'
    // }
]

export default columns
