import { Flip, toast } from 'react-toastify';

const customId = "danger-";

const toastDanger = (message) => {
    return toast.error(message ?? "Error.", {
        toastId: customId+message,
        theme: "colored",
        transition: Flip,
        position: "top-right",
        // autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    })
}

const errorResponse = (data, setInvalid) => {
    // if (error.response) {
    //     // The request was made and the server responded with a status code
    //     // that falls out of the range of 2xx
    //     console.log(error.response.data);
    //     console.log(error.response.status);
    //     console.log(error.response.headers);
    //   } else if (error.request) {
    //     // The request was made but no response was received
    //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //     // http.ClientRequest in node.js
    //     console.log(error.request);
    //   } else {
    //     // Something happened in setting up the request that triggered an Error
    //     console.log('Error', error.message);
    //   }
    //   console.log(error.config);

    if (data.response) {
        switch (data.response.status) {
            case 422:
                if (setInvalid) {
                    let errors = {}

                    for (const [key, value] of Object.entries(data.response.data.errors)) {
                        errors[key] = value.shift()
                    }

                    setInvalid(errors)
                } else {
                    if (data.response.data.message) {
                        toastDanger(data.response.data.message);
                    } else {
                        toastDanger(data.response.statusText);
                    }
                }
                break;

            case 401: // not logged in
            case 419: // session expired
            case 503: // maintennce
                // setLogged(false)
                console.log('logout');
                break;

            default:
                if (data.response.data.message) {
                    toastDanger(data.response.data.message);
                } else {
                    toastDanger(data.response.statusText);
                }
                break;
        }
    } else {
        if (data.data.message) {
            toastDanger(data.data.message);
        } else {
            toastDanger(data.statusText);
        }
    }
}

export default errorResponse
