import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { CButton, CCard, CCardBody, CCardFooter, CCol, CForm, CFormCheck, CFormFeedback, CFormInput, CFormLabel, CInputGroup, CInputGroupText, CRow, CSpinner } from '@coreui/react'
import { toastDanger, toastSuccess } from '@actions/toast.alert'
import { dataGet, dataPost, dataPut, dataError, dataLink } from '@actions/data.service'
import { SelectMulti } from '@components/base/select'

const Form = ({ showToastAlert, history }) => {
    let { id } = useParams();

    const url = '/privileges-user'
    const [submit, setSubmit] = useState(false)
    const [roles, setRoles] = useState([])
    const [permissions, setPermissions] = useState([])

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState(1)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState([])
    const [permission, setPermission] = useState([])

    const [invalidName, setInvalidName] = useState('')
    const [invalidEmail, setInvalidEmail] = useState('')
    const [invalidUsername, setInvalidUsername] = useState('')
    const [invalidPassword, setInvalidPassword] = useState('')
    const [invalidRole, setInvalidRole] = useState('')
    const [invalidPermission, setInvalidPermission] = useState('')

    useEffect(() => {
        let isMounted = true;

        if (id !== undefined) {
            const { state } = history.location

            setName(state.name)
            setEmail(state.email)
            setStatus(state.status)
            setUsername(state.username)
            setPassword(state.password)
            setRole(convertSelect(state.roles_src));
            setPermission(convertSelect(state.permissions_src));
        }

        handleRoles()
        handlePermissions()

        return () => isMounted = false
    }, [])

    const convertSelect = (data) => {
        let result = [];
            data.map((vData) => {
                result.push({
                    value: vData.id,
                    label: vData.name
                })
            })
        return result;
    }

    const handleRoles = () => {
        dataGet('privileges-role')
        .then(({ data }) => setRoles(convertSelect(data.data)))
        .catch(err => {
            dataError(err).then((res) => {
                showToastAlert(toastDanger(res.message))
            })
        })
    }

    const handlePermissions = () => {
        dataGet('privileges-permission')
        .then(({ data }) => setPermissions(convertSelect(data.data)))
        .catch(err => {
            dataError(err).then((res) => {
                showToastAlert(toastDanger(res.message))
            })
        })
    }

    const handleOnChange = (value, setValue, setInvalid = false) => {
        setValue(value)
        if (setInvalid) {
            setInvalid('')
        }
    }

    const validationRequire = (value, setInvalid, checkEmail = false) => {
        if (value.length < 1) {
            setInvalid('This field is required.')
            return Promise.reject("reject")
        } else {
            if (checkEmail) {
                const isEmail = value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (!isEmail) {
                    setInvalid('This section fill with email.')
                    return Promise.reject("reject")
                }
            }
        }

        return Promise.resolve("resolve")
    }

    const catchOnSubmit = (err) => {
        dataError(err).then((res) => {
            if (res.errors) {
                for (const [key, value] of Object.entries(res.errors)) {
                    switch (key) {
                        case "name":
                            setInvalidName(value.shift())
                            break;
                        case "email":
                            setInvalidEmail(value.shift())
                            break;
                        case "username":
                            setInvalidUsername(value.shift())
                            break;
                        case "password":
                            setInvalidPassword(value.shift())
                            break;

                        default:
                            break;
                    }
                }
            }

            showToastAlert(toastDanger(res.message))
        }).catch(() => {})
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();

        if (submit) return;

        setInvalidName('')
        setInvalidEmail('')
        setInvalidUsername('')
        setInvalidPassword('')

        Promise.all([
            validationRequire(name, setInvalidName),
            validationRequire(email, setInvalidEmail, true),
            validationRequire(username, setInvalidUsername),
            validationRequire(password, setInvalidPassword),
            validationRequire(role, setInvalidRole)
        ]).then((res) => {
            if (!submit) {
                setSubmit(true)

                if (id === undefined) {
                    dataPost(url+'/store', { name, email, status, username, password, role, permission })
                    .then((res) => {
                        if (res && res.status === 200) {
                            setName('')
                            setEmail('')
                            setStatus(1)
                            setUsername('')
                            setPassword('')
                            setRole([])
                            setPermission([])

                            showToastAlert(toastSuccess("Data berhasil disimpan."))
                        }
                    })
                    .catch((err) => catchOnSubmit(err))
                    .finally(() => setSubmit(false))
                } else {
                    dataPut(url+'/'+id+'/update', { name, email, status, username, password, role, permission })
                    .then((res) => {
                        if (res && res.status === 200) {
                            showToastAlert(toastSuccess("Data berhasil disimpan."))
                            history.push(url)
                        }
                    })
                    .catch((err) => catchOnSubmit(err))
                    .finally(() => setSubmit(false))
                }
            }
        }).catch(error => {
            // reject
        });
    }

    return (
        <CCard>
            <CForm noValidate onSubmit={handleOnSubmit}>
                <CCardBody className='p-2'>
                    <CRow className='g-3'>
                        <CCol>
                            <CFormLabel htmlFor="name" className='mb-0'>Name</CFormLabel>
                            <CInputGroup size='sm' className="mb-3">
                                <CFormInput
                                    type="text"
                                    id="name"
                                    placeholder='Enter name'
                                    invalid={invalidName ? true : false}
                                    value={name}
                                    onChange={(e) => handleOnChange(e.target.value, setName, setInvalidName)}
                                    required
                                    />
                                <CFormFeedback invalid>{invalidName}</CFormFeedback>
                            </CInputGroup>
                        </CCol>

                        <CCol>
                            <CFormLabel htmlFor="email" className='mb-0'>Email</CFormLabel>
                            <CInputGroup size='sm' className="mb-3">
                                <CInputGroupText id="email-addon">@</CInputGroupText>
                                <CFormInput
                                    type="email"
                                    id="email"
                                    placeholder='Enter email'
                                    invalid={invalidEmail ? true : false}
                                    value={email}
                                    onChange={(e) => handleOnChange(e.target.value, setEmail, setInvalidEmail)}
                                    aria-describedby="email-addon"
                                    required
                                    />
                                <CFormFeedback invalid>{invalidEmail}</CFormFeedback>
                            </CInputGroup>
                        </CCol>

                        <CCol md={3}>
                            <CFormLabel htmlFor="status" className='mb-0'>Status</CFormLabel>
                            <CInputGroup className="mb-3" style={{ paddingTop: 4 }}>
                                <CFormCheck
                                    inline
                                    type="radio"
                                    name="status"
                                    id="status1"
                                    className='mb-0'
                                    value="1"
                                    label="Active"
                                    onChange={(e) => handleOnChange(e.target.value, setStatus)}
                                    checked={status == 1 ? true : false}
                                    />
                                <CFormCheck
                                    inline
                                    type="radio"
                                    name="status"
                                    id="status2"
                                    className='mb-0'
                                    value="0"
                                    label="Not active"
                                    onChange={(e) => handleOnChange(e.target.value, setStatus)}
                                    checked={status == 0 ? true : false}
                                    />
                            </CInputGroup>
                        </CCol>
                    </CRow>

                    <CRow className='g-3'>
                        <CCol>
                            <CFormLabel htmlFor="name" className='mb-0'>Username</CFormLabel>
                            <CInputGroup size='sm' className="mb-3">
                                <CFormInput
                                    type="text"
                                    id="username"
                                    placeholder='Enter username'
                                    invalid={invalidUsername ? true : false}
                                    value={username}
                                    onChange={(e) => handleOnChange(e.target.value, setUsername, setInvalidUsername)}
                                    required
                                    />
                                <CFormFeedback invalid>{invalidUsername}</CFormFeedback>
                            </CInputGroup>
                        </CCol>

                        <CCol>
                            <CFormLabel htmlFor="email" className='mb-0'>Password</CFormLabel>
                            <CInputGroup size='sm' className="mb-3">
                                <CFormInput
                                    type="text"
                                    id="password"
                                    placeholder='Enter password'
                                    invalid={invalidPassword ? true : false}
                                    value={password}
                                    onChange={(e) => handleOnChange(e.target.value, setPassword, setInvalidPassword)}
                                    required
                                    />
                                <CFormFeedback invalid>{invalidPassword}</CFormFeedback>
                            </CInputGroup>
                        </CCol>
                    </CRow>

                    <CRow className='g-3'>
                        <CCol>
                            <CFormLabel htmlFor="roles" className='mb-0'>Roles</CFormLabel>
                            <CInputGroup size='sm' className="mb-3" style={{ display: 'inline-block' }}>
                                <SelectMulti
                                    options={roles}
                                    value={role}
                                    onChange={(value) => handleOnChange(value, setRole, setInvalidRole)}
                                    invalid={invalidRole}
                                    placeholder="Please select role"
                                    />
                                <CFormFeedback invalid>{invalidRole}</CFormFeedback>
                            </CInputGroup>
                        </CCol>

                        <CCol>
                            <CFormLabel htmlFor="permissions" className='mb-0'>Permissions</CFormLabel>
                            <CInputGroup size='sm' className="mb-3" style={{ display: 'inline-block' }}>
                                <SelectMulti
                                    options={permissions}
                                    value={permission}
                                    onChange={(value) => handleOnChange(value, setPermission, setInvalidPermission)}
                                    invalid={invalidPermission}
                                    placeholder="Please select permission"
                                    />
                                <CFormFeedback invalid>{invalidPermission}</CFormFeedback>
                            </CInputGroup>
                        </CCol>
                    </CRow>
                </CCardBody>

                <CCardFooter className='p-2'>
                    <CButton component='a' href={url} onClick={(e) => dataLink(e, history)} color="dark" type="button" size='sm' className='me-1 text-light'>Back</CButton>
                    <CButton color="primary" type="submit" size='sm'>
                        <div className="d-flex align-items-center">
                            <span>Save</span>
                            {submit ? <CSpinner color="light" size="sm" className='ms-1' /> : ''}
                        </div>
                    </CButton>
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        showToastAlert: (typeAlert) => dispatch({ type: "LAYOUT_TOAST_ALERT", toastAlert: typeAlert })
    }
}

export default React.memo(connect( null, mapDispatchToProps )(Form))
