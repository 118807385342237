import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import reducerAuth from './reducerAuth'
import reducerLayout from './reducerLayout'

const initialState = {};

const middleware = [thunk];

const rootReducer = combineReducers({
    auth: reducerAuth, // AUTH
    layout: reducerLayout // LAYOUT
});

const store = createStore(rootReducer, initialState, applyMiddleware(...middleware))

export default store
