import React from 'react'
import { CFormFeedback, CFormLabel, CFormTextarea } from '@coreui/react'

const RFormTextarea = ({ value, setValue, invalid }) => {
    const { id, label, value: thisValue, required, requiredLabel, rows, style, ...props } = value

    const setRequiredLabel = requiredLabel ?? true
    const setLabel = label

    return (
        <>
            <CFormLabel className='mb-0' htmlFor={id ?? ''}>{setLabel}{required && setRequiredLabel ? <span className="text-danger"> *</span> : ''}</CFormLabel>
            <CFormTextarea
                id={id ?? ''}
                size='sm'
                rows={rows ?? "3"}
                value={thisValue}
                invalid={invalid[id] ? true : false}
                onChange={(e) => {
                    setValue({
                        ...value,
                        value: e.target.value
                    })
                }}
                style={{ ...style, resize: 'none' }}
                {...props}
                />
            <CFormFeedback invalid>{invalid[id]}</CFormFeedback>
        </>
    )
}

export default RFormTextarea
