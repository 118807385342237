import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './scss/style.scss'

// Import MUI stuff
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { themeLight, themeDark } from '@configs/themeConfig'

import Layout from './layout';
import Login from '@views/auth/Login'


const App = ({ isAuthenticated, theme }) => {
    const [logged, setLogged] = useState(isAuthenticated)

    useEffect(() => {
        let mounted = true
        if (mounted) {
            setLogged(isAuthenticated)
        }
        return () => mounted = false
    }, [isAuthenticated])

    return (
        <ThemeProvider theme={[true, 'true'].some((vt) => vt == theme) ? themeDark : themeLight}>
            <CssBaseline />
            <ToastContainer />
            <BrowserRouter>
                <Switch>
                    <RoutePublic exact path="/" name="Login" component={Login} isAuthenticated={logged} />
                    {logged && <Route path="/" name="Home" render={(props) => <Layout {...props} />} />}
                    {!logged && <Route path="*" render={(props) => <Redirect to={{pathname: '/', state: {from: props.location}}} />} />}
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
};

const RoutePublic = ({ component: Component, isAuthenticated: isAuthenticated, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => !isAuthenticated
                ? <Component {...props}/>
                : <Redirect to={{pathname: '/dashboard', state: {from: props.location}}} />
            }
        />
    )
}

const mapStateToProps = ({ auth }) => {
    return {
        isAuthenticated: auth.isAuthenticated,
        theme: auth.themeDark,
    };
};

export default connect( mapStateToProps, null )(App);
