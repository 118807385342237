import React from 'react'
import Axios from 'axios';
import { connect, useDispatch } from 'react-redux'
import { Header, Content, Footer } from '@components/layout'
import XsrfService from '@services/xsrf.service';
import Sidebar from '@components/layout/Sidebar'
import routes from './routes'

import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ history, roles, permissions, xsrf }) => {
    const dispatch = useDispatch()

    Axios.interceptors.response.use(response => {
        return response;
    }, error => {
        switch (error.response.status) {
            case 401: // not logged in/
            case 419: // session expired
            case 503: // maintennce
                dispatch({
                    type: "AUTH_LOGOUT",
                })

                XsrfService.removeItem(xsrf)
                break;

            default:
                return Promise.reject(error);
        }
    });

    return (
        <>
            <Sidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-alpha">
                <Header history={history} />
                <div className="body flex-grow-1 px-4">
                    <Content router={routes(roles, permissions)} />
                </div>
                <Footer />
            </div>
        </>
    )
}

const mapStateToProps = ({ auth }) => {
    return {
        permissions: auth.permissions,
        roles: auth.roles,
        xsrf: auth.xsrf,
    };
};


export default connect( mapStateToProps, null )(Layout);
