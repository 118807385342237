import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import { DataTable } from '@components/base/table'
import { dataGet, dataError } from '@actions/data.service'
import { toastDanger } from '@actions/toast.alert'
import LoadingData from '@components/loading/LoadingData'
import Form from './Form';
import columns from './columns'

const Role = ({ showToastAlert }) => {
    const [data, setData] = useState(null);
    const [modal, setModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [attr, setAttr] = useState({
        url : '/privileges-role',
        paginate: 10,
        reloadTable: (url, paginate) => handleTable(url, paginate)
    });

    useEffect(() => handleTable(attr.url, attr.paginate), [])

    const handleTable = (url, params) => {
        dataGet(url, params).then(({data}) => {
            setData(data.data);
            setAttr({
                ...attr,
                links: data.links,
                meta: data.meta,
                actions: {
                    ...data.actions,
                    modalForm: (t, df, dr) => handleForm(t, df, dr)
                },
                form: data.form,
            })
        }).catch(err => {
            dataError(err).then((resErr) => {
                setData([])
                showToastAlert(toastDanger(resErr.message))
            })
        })
    }

    const handleForm = (from, dataForm, dataRow) => {
        setModal(!modal);
        setModalData({
            title: 'Privileges Role '+from,
            url: attr.url,
            reloadTable: () => attr.reloadTable(attr.url, attr.paginate),
            showToastAlert: showToastAlert,
            dataForm: dataForm,
            dataRow: dataRow
        })
    }

    return (
        <LoadingData data={data}>
            <CCard>
                {attr.actions && attr.actions.opsCreate ? <CCardHeader className='p-2'>
                    <CButton onClick={() => attr.actions.modalForm('Create', attr.form)} color="primary" type="button" size='sm'>Create</CButton>
                </CCardHeader> : ''}

                <CCardBody className='p-2'>
                    <Form visible={modal} setVisible={setModal} data={modalData} />
                    <DataTable columns={columns} data={data} attr={attr} />
                </CCardBody>
            </CCard>
        </LoadingData>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        showToastAlert: (typeAlert) => dispatch({ type: "LAYOUT_TOAST_ALERT", toastAlert: typeAlert })
    }
}

export default connect( null, mapDispatchToProps )(Role);
