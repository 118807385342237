import CryptoJS from 'crypto-js'

const xsrf = 'xsrf_token_agent_binpro';
const keys = "base64:UdtqIEQNEkGgDUDcZpXtlNXFg4wUOqizjKlSv/sPbiQ=";
const token = localStorage.getItem(xsrf) ?? null

const decodeToken = (token, value) => {
    if (token) {
        let setDecode = '',
            getDecode = CryptoJS.AES.decrypt(token, keys).toString(CryptoJS.enc.Utf8)

            for (const keyDecode in JSON.parse(getDecode)) {
                if (keyDecode == value) {
                    setDecode = JSON.parse(getDecode)[keyDecode]
                }
            }

        return setDecode
    } else {
        return null
    }
};

const initialState = {
    xsrf: xsrf,
    keys : keys,
    token: token,
    themeDark: localStorage.getItem(xsrf+'_darkMode') ?? false,
    isAuthenticated: token ? true : false,
    bearerToken: decodeToken(token, 'token'),
    name: decodeToken(token, 'name'),
    email: decodeToken(token, 'email'),
    username: decodeToken(token, 'username'),
    roles: decodeToken(token, 'roles'),
    permissions: decodeToken(token, 'permissions'),
    foto: decodeToken(token, 'foto')
};

const reducerAuth = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case "AUTH_LOGIN":
            return {
                ...state,
                token: rest.data,
                isAuthenticated: true,
                bearerToken: decodeToken(rest.data, 'token'),
                name: decodeToken(rest.data, 'name'),
                email: decodeToken(rest.data, 'email'),
                username: decodeToken(rest.data, 'username'),
                roles: decodeToken(rest.data, 'roles'),
                permissions: decodeToken(rest.data, 'permissions'),
                foto: decodeToken(rest.data, 'foto'),
            }

        case "AUTH_LOGOUT":
            return {
                ...state,
                token: null,
                isAuthenticated: false,
            }

        case "AUTH_THEME_DARK":
            localStorage.setItem(xsrf+'_darkMode', rest.themeDark)
            return {
                ...state,
                themeDark: rest.themeDark
            }

        case "AUTH_FOTO":
            const data = JSON.parse(CryptoJS.AES.decrypt(token, keys).toString(CryptoJS.enc.Utf8))
            const newToken = CryptoJS.AES.encrypt(JSON.stringify({...data, foto: rest.foto}), keys).toString()

            localStorage.setItem(xsrf, newToken);

            return {
                ...state,
                foto: rest.foto
            }

        default:
            return state
    }
}

export default reducerAuth;
