import routeTo from './routeTo'

const routePermission = (permissions, to) => {
    if (to === '/dashboard/:id/show') {
        return true;
    } else {
        return permissions.some((permission) => permission.name == routeTo(to))
    }
}

export default routePermission
