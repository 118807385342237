import React from 'react'
import { CCard, CCardBody, CCol, CContainer, CRow, CSpinner } from '@coreui/react'

const RCard = ({ children, ...props }) => {
    return (
        <CCard {...props}>{children}</CCard>
    )
}

export default RCard
