import React from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, CardHeader, CardMedia, Chip, Stack, Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { MuiButton } from '@components/mui'
import { date, rupiahTextHalf } from '@utils/conversion';
import image from '@assets/images/image.png';
import * as FaIcons from 'react-icons/fa';

const ListingCard = ({ data }) => {
    let history = useHistory();

    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name.split(' ')[0][0]
        };
    }

    return (
        <Card>
            <CardHeader
                avatar={<Avatar {...stringAvatar(data ? data.created_by : '')}></Avatar>}
                title={data.created_by || ''}
                subheader={date(data.status_created_at)} />

            <CardMedia component="img" height="140" image={window.location.origin+(data.foto ? data.foto.url : image)}  alt={data.judul} />

            <CardContent>
                <Chip color={data.jenis ? "primary" : "default"} label={data.jenis} />

                &nbsp;&nbsp;

                <Chip
                    color={data.status_id == 4 ? "success" : "error"}
                    label={data.status_id == 4 ? "Tersedia" : "Closing"}
                    variant="outlined" />

                <Typography variant="body1" gutterBottom sx={{ my: 3 }}>{data.judul}</Typography>

                <Typography variant="caption" display="block" gutterBottom sx={{ mb: 0, fontWeight: 'bold' }}>Kode :</Typography>
                <Typography variant="caption" display="block" gutterBottom>{data.kode}</Typography>

                <Typography variant="caption" display="block" gutterBottom sx={{ mb: 0, fontWeight: 'bold' }}>Alamat :</Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    {data.lokasi}{', '}
                    Kel/Desa <span style={{ textTransform: 'capitalize' }}>{data.kelurahan && data.kelurahan.toLowerCase()}</span>{', '}
                    Kecamatan <span style={{ textTransform: 'capitalize' }}>{data.kecamatan && data.kecamatan.toLowerCase()}</span>{', '}
                    <span style={{ textTransform: 'capitalize' }}>{data.kokab && data.kokab.toLowerCase()}</span>{', '}
                    Provinsi <span style={{ textTransform: 'capitalize' }}>{data.provinsi && data.provinsi.toLowerCase()}</span>
                </Typography>
            </CardContent>

            <Box sx={{ mb: 0, mt:1, pb: 2, px: 1.5 }}>
                <Typography component="div" variant="h5" color="primary">
                    Rp. {rupiahTextHalf(data.harga)}
                </Typography>

                <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                    <Typography component="div" variant="caption">
                        <FaIcons.FaBath /> : {data.kamar_mandi}
                    </Typography>

                    <Typography component="div" variant="caption">
                        <FaIcons.FaBed /> : {data.kamar_tidur}
                    </Typography>

                    <Typography component="div" variant="caption">
                        LT : {data.luas_tanah} m<sup>2</sup>
                    </Typography>

                    <Typography component="div" variant="caption">
                        LB : {data.luas_bangunan} m<sup>2</sup>
                    </Typography>
                </Stack>
            </Box>

            <hr style={{ margin: 0 }} />

            <CardActions>
                <MuiButton color="success" onClick={() => history.push(`/dashboard/${data.id}/show`)}>Detail</MuiButton>
            </CardActions>
        </Card>
    );
}

export default ListingCard;
