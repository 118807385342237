import React, { useEffect, useState } from 'react'
import { RCard, RCardHeader, RCardBody } from '@components/card'
import { RAnchorPrimary } from '@components/anchor'
import { RLoadingData } from '@components/loading'
import { RDataTable } from '@components/table'
import { rupiahTextHalfConvert } from '@hooks/convert'
import ResourceService from '@services/resource.service'
import columns from './columns'

const My = () => {
    const url = "/listing-saya"
    const paginate = 10
    const [data, setData] = useState([]);
    const [result, setResult] = useState(null);
    const [table, setTable] = useState({
        url: url,
        params: {
            paginate: paginate
        },
        attr: {
            ops_foto: {
                color: 'success'
            }
        },
        default: {
            url: url,
            paginate: paginate,
        }
    });

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            ResourceService.table(table.url, table.params)
            .then(
                ({ data, ...res }) => {
                    if (mounted) {
                        let newData = [];
                        const { data: getData, ...attrs } = data

                        getData.map((nd) => {
                            let classColorCellStatusTitle = ""

                            switch (nd.status_id) {
                                case "1":
                                    classColorCellStatusTitle = "text-white bg-primary"
                                    break;
                                case "2":
                                    classColorCellStatusTitle = "text-white bg-warning"
                                    break;
                                case "3":
                                    classColorCellStatusTitle = "text-white bg-danger"
                                    break;
                                case "4":
                                    classColorCellStatusTitle = "text-white bg-success"
                                    break;
                                case "5":
                                    classColorCellStatusTitle = "text-white bg-secondary"
                                    break;
                            
                                default:
                                    classColorCellStatusTitle = ""
                                    break;
                            }

                            newData.push({
                                ...nd,
                                harga: "Rp. "+rupiahTextHalfConvert(nd.harga.toString()),
                                classBodyRow: "",
                                classBodyCell: {
                                    status_title: classColorCellStatusTitle
                                },
                                styleBodyRow: {
                                    // backgroundColor: 'red'
                                },
                                styleBodyCell: {
                                    status_title: {
                                        // color: colorCellStatusTitle
                                    }
                                },
                            })
                        })

                        setTable({
                            ...table,
                            attr: {
                                ...table.attr,
                                ...attrs
                            }
                        })
                        setData(newData)
                        setResult(res)
                    }
                },
                (error) => {
                    if (mounted) {
                        setResult(error)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [table.refresh])

    return (
        <RLoadingData result={result}>
            <RCard>
                {table.attr && table.attr.ops_create && <RCardHeader>
                    <RAnchorPrimary href={`${url}/create`}>Tambah</RAnchorPrimary>
                </RCardHeader>}

                <RCardBody>
                    <RDataTable columns={columns} data={data} table={table} setTable={setTable} />
                </RCardBody>
            </RCard>
        </RLoadingData>
    )
}

export default React.memo(My)
