const initialState = {
    appName: "AGENT BINPRO",
    sidebarShow: true, // true
    toastAlert: 0,
};

const reducerLayout = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case "LAYOUT_SIDEBAR_SHOW":
            return {
                ...state,
                ...rest
            }
        case "LAYOUT_TOAST_ALERT":
            return {
                ...state,
                toastAlert: rest.toastAlert
            }

        default:
            return state
    }
}

export default reducerLayout;
