import React from 'react'
import { CFormFeedback, CFormInput, CFormLabel } from '@coreui/react'

const RFormInputFile = ({ value, setValue, invalid }) => {
    const { id, label, size, type, value: thisValue, required, requiredLabel, ...props } = value

    const setRequiredLabel = requiredLabel ?? true
    const setLabel = label

    return (
        <>
            <CFormLabel className='mb-0' htmlFor={id ?? ''}>{setLabel}{required && setRequiredLabel ? <span className="text-danger"> *</span> : ''}</CFormLabel>
            <CFormInput
                id={id ?? ''}
                size='sm'
                type='file'
                invalid={invalid[id] ? true : false }
                onChange={(e) => {
                    setValue({
                        ...value,
                        value: e.target.files
                    })
                }}
                {...props}
                />
            <CFormFeedback invalid>{invalid[id]}</CFormFeedback>
        </>
    )
}

export default RFormInputFile
