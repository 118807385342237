import React, { useEffect, useState }  from 'react'
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom";
import { CButton } from '@coreui/react';
import pushLink from '@hooks/pushLink'
import DataService from '@services/data.service';
import SetSimpleTable from "./SimpleTable";
import SetDataTable from "./DataTable"

// -------------- function --------------

const costumColumn = (reloadTable, history, columns, attr, sortActive) => {
    let setColumn = [
        {
            Header: '#',
            accessor: 'no',
            Cell: ({row}) => row.index + (attr.meta ? attr.meta.from : 0),
            disableSortBy: true,
            disableFilters: true,
            minWidth: 2
        },
    ];

    if (columns !== undefined) {
        columns.map((vc) => {
            if (vc.isLink) {
                return (
                    setColumn.push({
                        Header: vc.th,
                        accessor: vc.td,
                        Cell: ({ value }) => {
                            return <CButton component='a' href={value} target={vc.targetLink ?? ''} color="link" className='fs-7 px-1 py-0'>{vc.titleLink ?? value}</CButton>
                        },
                        disableSortBy: true,
                        disableFilters: true,
                        minWidth: vc.minWidth ?? 0,
                    })
                )
            } else {
                if (vc.sortBy) {
                    if (sortActive) {
                        if (vc.td == sortActive.accessor) {
                            if (!sortActive.orderBy.asc && !sortActive.orderBy.desc) {
                                return (
                                    setColumn.push({
                                        Header: vc.th,
                                        accessor: vc.td,
                                        disableSortBy: false,
                                        minWidth: vc.minWidth ?? 0,
                                        orderBy: {
                                            asc: true,
                                            desc: false
                                        }
                                    })
                                )
                            } else {
                                if (sortActive.orderBy.asc) {
                                    return (
                                        setColumn.push({
                                            Header: vc.th,
                                            accessor: vc.td,
                                            disableSortBy: false,
                                            minWidth: vc.minWidth ?? 0,
                                            orderBy: {
                                                asc: false,
                                                desc: true
                                            }
                                        })
                                    )
                                } else {
                                    return (
                                        setColumn.push({
                                            Header: vc.th,
                                            accessor: vc.td,
                                            disableSortBy: false,
                                            minWidth: vc.minWidth ?? 0,
                                            orderBy: {
                                                asc: true,
                                                desc: false
                                            }
                                        })
                                    )
                                }
                            }
                        } else {
                            return (
                                setColumn.push({
                                    Header: vc.th,
                                    accessor: vc.td,
                                    disableSortBy: false,
                                    minWidth: vc.minWidth ?? 0,
                                    orderBy: {
                                        asc: false,
                                        desc: false
                                    }
                                })
                            )
                        }
                    } else {
                        return (
                            setColumn.push({
                                Header: vc.th,
                                accessor: vc.td,
                                disableSortBy: false,
                                minWidth: vc.minWidth ?? 0,
                                orderBy: {
                                    asc: false,
                                    desc: false
                                }
                            })
                        )
                    }
                } else {
                    return (
                        setColumn.push({
                            Header: vc.th,
                            accessor: vc.td,
                            disableSortBy: true,
                            minWidth: vc.minWidth ?? 0,
                        })
                    )
                }
            }
        })
    }

    if (attr.ops_action_row) {
        setColumn.push({
            Header: '',
            accessor: 'actions',
            Cell: ({ row }) => {
                const { original } = row;
                const { ops_action_row, ...dataOriginal } = original;
                const { ops_show, ops_edit, ops_delete, ...otherAction } = ops_action_row;
                const { url, form, paginate, modalForm, modalShow, otherActionAttr } = attr;

                const handleOtherAction = (data) => {
                    if (Object.keys(data).length === 0 && data.constructor === Object) {
                        return '';
                    } else {
                        for (const [key, value] of Object.entries(data)) {
                            return <ButtonOther url={url} dataRow={dataOriginal} dataForm={form} history={history} otherActionAttr={otherActionAttr} keys={key} values={value}  />
                        }
                    }
                }

                return (
                    <div className="text-center">
                        {handleOtherAction(otherAction)}
                        {ops_show && ops_show.length > 0 ? <ButtonShow access={ops_show} url={url} dataRow={dataOriginal} dataForm={form} history={history} modalShow={modalShow} /> : ""}
                        {ops_edit && ops_edit.length > 0 ? <ButtonEdit access={ops_edit} url={url} dataRow={dataOriginal} dataForm={form} history={history} modalForm={modalForm} /> : ""}
                        {ops_delete && ops_delete.length > 0 ? <ButtonDelete access={ops_delete} url={url} paginate={paginate} dataRow={dataOriginal} reloadTable={reloadTable} /> : ""}
                    </div>
                )
            },
            disableSortBy: true,
            disableFilters: true,
            minWidth: 'auto'
        })
    }

    return setColumn;
}

// -------------- component --------------

const ButtonOther = ({ url, dataRow, dataForm, history, otherActionAttr, keys, values }) => {
    if (values.length > 0) {
        let title;
        const attr = otherActionAttr[keys];
        const setAccess = values[0] ?? false;
        const color = setAccess === true ? (attr.color ?? 'secondary') : 'secondary';
        const disabled = setAccess === true ? false : true;

        if (attr.title) {
            title = attr.title;
        } else {
            const splitStr = keys.replace("ops_", '').replace("_", ' ').toLowerCase().split(' ');
            for (let i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            title = splitStr.join(' ');
        }

        const handleClick = (e) => {
            e.preventDefault();

            if (setAccess === true) {
                if (attr.modal && typeof attr.modal === 'function') {
                    modal('Edit', dataForm, dataRow)
                } else {
                    pushLink(e, history, dataRow)
                }
            }
        }


        return <CButton component='a' onClick={(e) => handleClick(e)} href={url+'/'+dataRow.id+'/'+keys.replace("ops_", '').replace("_", '-')} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={disabled}>{title}</CButton>;
    }

    return '';
}

const ButtonShow = ({ access, url, dataRow, history, dataForm , modalShow }) => {
    const setAccess = access[0] ?? false;
    const color = setAccess === true ? 'info' : 'secondary';
    const disabled = setAccess === true ? false : true;

    const handleClick = (e) => {
        e.preventDefault();

        if (setAccess === true) {
            if (modalShow && typeof modalShow === 'function') {
                modalShow('Show', dataForm, dataRow)
            } else {
                pushLink(e, history, dataRow)
            }
        }
    }

    return <CButton component='a' href={url+'/'+dataRow.id+'/show'} onClick={(e) => handleClick(e)} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={disabled}>Show</CButton>
}

const ButtonEdit = ({ access, url, dataRow, history, dataForm , modalForm }) => {
    const setAccess = access[0] ?? false;
    const color = setAccess === true ? 'warning' : 'secondary';
    const disabled = setAccess === true ? false : true;

    const handleClick = (e) => {
        e.preventDefault();

        if (setAccess === true) {
            if (modalForm && typeof modalForm === 'function') {
                modalForm('Edit', dataForm, dataRow)
            } else {
                pushLink(e, history, dataRow)
            }
        }
    }

    return <CButton component='a' href={url+'/'+dataRow.id+'/edit'} onClick={(e) => handleClick(e)} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={disabled}>Edit</CButton>
}

const ButtonDelete = ({ access, url, paginate, dataRow, reloadTable }) => {
    const setAccess = access[0] ?? false;
    const color = setAccess === true ? 'danger' : 'secondary';
    const disabled = setAccess === true ? false : true;

    const whenDelete = (e) => {
        e.preventDefault();

        if (setAccess === true) {
            Swal.fire({
                title: 'Apakah kamu yakin?',
                text: "Kamu akan menghapus data ini!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya!',
                cancelButtonText: 'Batal',
                focusCancel: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return DataService.destroy(e.target.closest("a").getAttribute('href'))
                    .then(
                        (response) => {
                            if (response.status === 200) {
                                reloadTable({
                                    newSort: null,
                                    url: url,
                                    paginate: paginate,
                                    search: '',
                                    orderByColumn:  '',
                                    orderByDirection: '',
                                })
                            }

                            if (response.status !== 200) {
                                throw new Error(response.statusText)
                            }

                            return response
                        },
                        (error) => {
                            Swal.showValidationMessage(
                                `Request failed: ${error}`
                            )
                        }
                    )
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Data yang kamu pilih telah dihapus.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
    }

    return <CButton component='a' href={url+'/'+dataRow.id+'/delete'} onClick={(e) => whenDelete(e)} color={color} size='sm' className='text-light me-1 fs-7 px-1 py-0 my-1' disabled={disabled}>Delete</CButton>
}

// -------------- export --------------

const SimpleTable = (props) => {
    const prop = {...props, columns: costumColumn(props.columns, props.actions)};

    return <SetSimpleTable { ...prop } />
}

const DataTable = ({ columns, data, attr, setAttr }) => {
    let history = useHistory();
    const [search, setSearch] = useState('');
    const [url, setUrl] = useState(attr.url ?? '');
    const [show, setShow] = useState(attr.paginate ?? 10);
    const [sort, setSort] = useState(null)
    const [order, setOrder] = useState(null);

    const [searching, setSearching] = useState(false)
    const [column, setColumn] = useState(costumColumn(reloadTable, history, columns, attr))

    useEffect(() => {
        if (data) {
            setSearching(false)
            if (sort) {
                setColumn(costumColumn(reloadTable, history, columns, attr, sort))
            } else {
                setColumn(costumColumn(reloadTable, history, columns, attr))
            }
        }
    }, [data])

    const reloadTable = ({ newSort, url, paginate, ...params}) => {
        if (typeof setAttr !== 'function') {
            alert('set attr table tidak ada')
        } else {
            setSearching(true)
            setSearch(params.search)
            setUrl(url)
            setShow(paginate)
            setSort(newSort);
            setOrder({
                orderByColumn: params.orderByColumn,
                orderByDirection: params.orderByDirection
            })
            setAttr({ ...attr, url, paginate, params })
        }
    }


    return <SetDataTable
                columns={column}
                data={data}
                attr={attr}
                searching={searching}
                url={url}
                show={show}
                search={search}
                sort={sort}
                order={order}
                reloadTable={reloadTable}
                />
}

export { SimpleTable, DataTable }
