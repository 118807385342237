const colProvinces = [
    {
        th: 'Kode',
        td: 'code'
    },
    {
        th: 'Nama',
        td: 'name',
        sortBy: true
    },
    {
        th: 'Kota / Kabupaten',
        td: 'city'
    },
    {
        th: 'Map',
        td: 'meta',
        isLink: true,
        titleLink: 'View',
        targetLink: '_blank'
    }
]

export default colProvinces
