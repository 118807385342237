import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { RCard, RCardBody, RCardFooter } from '@components/card'
import { RForm, RFormInputFile } from '@components/form'
import { RButtonLoading } from '@components/button'
import { RAnchorSecondary } from '@components/anchor'
import { formValidation } from '@hooks/validation'
import { errorResponse, successResponse } from '@hooks/response'
import ResourceService from '@services/resource.service'

const UbahFoto = () => {
    let history = useHistory();
    const dispatch = useDispatch();

    const [submit, setSubmit] = useState(false);
    const [invalid, setInvalid] = useState({});

    const [foto, setFoto] = useState({
        id: 'foto',
        label: 'Unggah Foto',
        type: 'file',
        accept: 'image',
        required: true,
        size: 120480
    });

    const handleSubmitForm = async (event) => {
        event.preventDefault()

        const el = {
            foto
        }

        if (!submit) {
            formValidation(el)
            .then((res) => {
                let success = false

                setSubmit(true)
                setInvalid({})

                ResourceService.put('/ubah-password', {...res, is_foto: true})
                .then(
                    (res) => {
                        dispatch({
                            type: "AUTH_FOTO",
                            foto: res.data.foto
                        })
                        successResponse(res)
                        success = true
                    },
                    (error) => errorResponse(error, setInvalid)
                )
                .finally(() => {
                    setSubmit(false)
                    if (success) {
                        history.push('/account-profile')
                    }
                })
            })
            .catch((err) => setInvalid(err))
        }
    }

    return (
        <RCard>
            <RForm onSubmit={handleSubmitForm}>
                <RCardBody>
                    <div className="mb-2">
                        <RFormInputFile value={foto} setValue={setFoto} invalid={invalid} />
                    </div>
                </RCardBody>

                <RCardFooter>
                    <div className='d-flex justify-content-center'>
                        <RAnchorSecondary href='/account-profile'>Kembali</RAnchorSecondary>
                        &nbsp;
                        <RButtonLoading type='submit' loading={submit}>Simpan</RButtonLoading>
                    </div>
                </RCardFooter>
            </RForm>
        </RCard>
    )
}

export default UbahFoto
