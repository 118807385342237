const dateConvert = (value, format = 'ddMMyyyy', locale = 'id') => {
    const date = new Date(value);

    const options = (key) => {
        switch (key) {
            case 'ddmmyyyy':
                return { year: 'numeric', month: '2-digit', day: '2-digit' }
            case 'ddMyyyy':
                return { year: 'numeric', month: 'short', day: '2-digit' }

            default:
                return { year: 'numeric', month: 'long', day: '2-digit' }
        }
    }

    switch (locale) {
        case 'en':
            return date.toLocaleDateString("en-US", options(format));

        default:
            return date.toLocaleDateString("id-ID", options(format));
    }
}

export default dateConvert
