import React from 'react'
import { CButton, CSpinner } from '@coreui/react'

const RButtonLoading = ({ children, type, color, loading, ...props }) => {
    return (
        <CButton type={type ?? 'button'} color={color ?? 'primary'} size="sm"  {...props}>
            <div className="d-flex align-items-center justify-content-center">
                {loading ? <CSpinner color="light" size='sm' className='me-2' /> : ''}
                {children}
            </div>
        </CButton>
    )
}

export default RButtonLoading
