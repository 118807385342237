import React from 'react'
import { Alert, AlertTitle, Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, Link, Paper, TextField, Tooltip, Typography } from '@mui/material'

const MuiAlert = ({ children, title, severity, sx }) => {
    const styles = {
        ...sx,
        '&.MuiAlert-root': {
            padding: '0 12px',
            lineHeight: 0,
            '& .MuiAlert-icon': {
                marginRight: '10px'
            },
            '& .MuiAlert-message': {
                lineHeight: 1.43
            }
        }
    }

    return (
        <Alert severity={severity ?? "success"} sx={styles}>
            {title && <AlertTitle>{severity ? severity.charAt(0).toUpperCase() + severity.substr(1).toLowerCase() : "Success"}</AlertTitle>}
            {children}
        </Alert>
    )
}

export default MuiAlert
