const validationData = (elements) => {
    let res = {};
    let err = [];

    for (const [key, data] of Object.entries(elements)) {
        let setError = [];

        // check required
        if (data.required === true && (data.value === '' || data.value === undefined || data.value.length === 0)) {
            setError.push({
                [key]: 'The '+data.label.toLowerCase()+' field is required.'
            })
        }

        // setting error
        if (setError.length > 0) {
            err.push(setError.shift());
        }

        // setting res
        const keyRes = key.split(/(?=[A-Z])/).join('_').toLowerCase()

        // save res
        res[keyRes] = data.value
    }

    return new Promise((resolve, reject) => {
        if (err.length > 0) {
            let resReject = {}
            err.map((vErr) => {
                const key = Object.keys(vErr).shift().split(/(?=[A-Z])/).join('_').toLowerCase()
                const value = Object.values(vErr).shift()

                resReject[key] = value
            })
            reject(resReject)
        } else {
            resolve(res)
        }
    });
}

export default validationData
