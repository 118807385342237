import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { CCol, CFormLabel, CFormTextarea, CRow } from '@coreui/react';
import { RAnchorSecondary } from '@components/anchor'
import { RButtonLoading } from '@components/button'
import { RCard, RCardBody, RCardFooter } from '@components/card'
import { RForm, RFormInput, RFormInputRadio, RFormSelect, RFormInputFile } from '@components/form'
import { RLoadingData } from '@components/loading'
import { formValidation } from '@hooks/validation'
import { errorResponse, successResponse } from '@hooks/response'
import ResourceService from '@services/resource.service'

const Form = () => {
    let params = useParams();
    let history = useHistory();

    const url = '/master-pegawai'
    const [result, setResult] = useState(null);

    const [submit, setSubmit] = useState(false);
    const [invalid, setInvalid] = useState({});

    const [opsRoles, setOpsRoles] = useState([]);

    const [name, setName] = useState({
        id: 'name',
        label: 'Nama',
        required: true,
        placeholder: 'Nama'
    });
    const [email, setEmail] = useState({
        id: 'email',
        label: 'Email',
        type: 'email',
        required: true,
        placeholder: 'Email'
    });
    const [status, setStatus] = useState({
        id: 'status',
        label: 'Status',
        type: 'radio',
        value: 1,
        required: true,
        placeholder: 'Status',
        check: [
            {value: 1, label: 'Active'},
            {value: 0, label: 'Inactive'}
        ]
    });
    const [username, setUsername] = useState({
        id: 'username',
        label: 'Username',
        required: true,
        placeholder: 'Username'
    });
    const [password, setPassword] = useState({
        id: 'password',
        label: 'Password',
        required: true,
        placeholder: 'Password'
    });
    const [role, setRole] = useState({
        id: 'role',
        label: 'Role',
        required: true,
        placeholder: 'Role'
    });
    const [alamat, setAlamat] = useState({
        value: '',
        id: 'alamat',
        label: 'Alamat',
        placeholder: 'Alamat'
    });

    const [noHp, setNoHp] = useState({
        id: 'no_hp',
        label: 'No Hp',
        placeholder: 'No Hp'
    });

    const [noRek, setNoRek] = useState({
        id: 'no_rek',
        label: 'No Rekening',
        placeholder: 'No Rekening'
    });

    const [bank, setBank] = useState({
        id: 'bank',
        label: 'Jenis Bank',
        placeholder: 'Jenis Bank'
    });

    const [berkas, setBerkas] = useState({
        id: 'berkas',
        label: 'Berkas',
        type: 'file',
        accept: 'berkas',
        size: 120480
    });

    useEffect(() => {
        let mounted = true;
        const getData = () => {
            let route = params.id ? `${url}/${params.id}/edit` : url+"/create"
            ResourceService.get(route)
            .then(
                ({data, ...res}) => {
                    if (mounted) {
                        if (params.id && data.data) {
                            setName({...name, value: data.data.name})
                            setEmail({...email, value: data.data.email})
                            setStatus({...status, value: data.data.status})
                            setUsername({...username, value: data.data.username})
                            setPassword({...password, value: data.data.password})
                            setRole({
                                ...role,
                                value: {
                                    value: data.has_roles.value,
                                    label: data.has_roles.label
                                }
                            })
                            setAlamat({...alamat, value: data.biodata.alamat})
                            setNoHp({...noHp, value: data.biodata.no_hp})
                            setNoRek({...noRek, value: data.biodata.no_rek})
                            setBank({...bank, value: data.biodata.bank})
                        }
                        setOpsRoles(data.roles)
                        setResult(res);
                    }
                },
                (error) => {
                    if (mounted) {
                        setResult(error)
                    }
                }
            )
        }
        getData()
        return () =>  {
            mounted = false
        }
    }, [])

    const handleSubmitForm = (e) => {
        e.preventDefault();

        const el = {
            name,
            email,
            status,
            username,
            password,
            role,
            alamat,
            noHp,
            noRek,
            bank,
            berkas
        }

        if (!submit) {
            formValidation(el)
            .then((res) => {
                setSubmit(true)
                setInvalid({})

                if (params.id) {
                    ResourceService.put(url+'/'+params.id+'/update', res)
                    .then(
                        (res) => {
                            successResponse(res)
                            history.push(url)
                        },
                        (error) => {
                            errorResponse(error, setInvalid)
                            setSubmit(false)
                        }
                    )
                } else {
                    ResourceService.post(url+'/store', res)
                    .then(
                        (res) => {
                            successResponse(res)
                            history.push(url)
                        },
                        (error) => {
                            errorResponse(error, setInvalid)
                            setSubmit(false)
                        }
                    )
                }
            })
            .catch((err) => setInvalid(err))
        }
    }

    return (
        <RLoadingData result={result}>
            <RCard>
                <RForm onSubmit={handleSubmitForm}>
                    <RCardBody>
                        <CRow>
                            <CCol md={5}>
                                <div className="mb-2">
                                    <RFormInput value={name} setValue={setName} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={5}>
                                <div className="mb-2">
                                    <RFormInput value={email} setValue={setEmail} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={2}>
                                <div className="mb-2">
                                    <RFormInputRadio value={status} setValue={setStatus} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={5}>
                                <div className="mb-2">
                                    <RFormInput value={username} setValue={setUsername} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={5}>
                                <div className="mb-2">
                                    <RFormInput value={password} setValue={setPassword} invalid={invalid} />
                                    {params.id && <p className='fs-8 text-danger'><em>* Abaikan jika password tidak ingin diubah.</em></p>}
                                </div>
                            </CCol>

                            <CCol md={2}>
                                <div className="mb-2">
                                    <RFormSelect options={opsRoles} value={role} setValue={setRole} invalid={invalid} />
                                </div>
                            </CCol>
                        </CRow>

                        <hr />

                        <CRow>
                            <CCol md={4}>
                                <div className="mb-2">
                                <CFormLabel className='mb-0' htmlFor='alamat'>Alamat</CFormLabel>
                                    <CFormTextarea
                                        id="alamat"
                                        rows="3"
                                        text="Alamat"
                                        value={alamat.value}
                                        onChange={(e) => {
                                            setAlamat({
                                                    ...alamat,
                                                    value: e.target.value
                                                })
                                            }}
                                        />

                                    {/* <RFormInput value={alamat} setValue={setAlamat} invalid={invalid} /> */}
                                </div>
                            </CCol>

                            <CCol md={4}>
                                <div className="mb-2">
                                    <RFormInput value={noHp} setValue={setNoHp} invalid={invalid} />
                                </div>

                                <div className="mb-2">
                                    <RFormInput value={noRek} setValue={setNoRek} invalid={invalid} />
                                </div>
                            </CCol>

                            <CCol md={4}>
                                <div className="mb-2">
                                    <RFormInput value={bank} setValue={setBank} invalid={invalid} />
                                </div>

                                <div className="mb-2">
                                    <RFormInputFile value={berkas} setValue={setBerkas} invalid={invalid} />
                                    {params.id && <p className='fs-8 text-danger'><em>* Abaikan jika berkas tidak ingin diubah.</em></p>}
                                </div>
                            </CCol>
                        </CRow>
                    </RCardBody>

                    <RCardFooter>
                        <div className='d-flex justify-content-center'>
                            <RAnchorSecondary href={url}>Kembali</RAnchorSecondary>
                            &nbsp;
                            <RButtonLoading type='submit' loading={submit}>Simpan</RButtonLoading>
                            &nbsp;
                            {/* // Tambah lagi */}
                        </div>
                    </RCardFooter>
                </RForm>
            </RCard>
        </RLoadingData>
    )
}

export default Form
