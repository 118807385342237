import React from 'react'
import { routeAccount, routePages, routePrivileges } from '@routes'
import routeName from './routeName'
import routePermission from './routePermission'

const routes = (roles, permissions) => {
    let router = [];

    const resultRoute = (route, actions) => {
        if (route.component && routePermission(permissions, route.to)) {
            router.push({
                exact: route.exact ?? false,
                path: route.to,
                name: routeName(permissions, route.to) ?? route.name,
                component: route.component
            })

            if (actions) {
                actions.map((vra) => {
                    if (vra.component && routePermission(permissions, vra.to)) {
                        router.push({
                            exact: vra.exact ?? false,
                            path: vra.to,
                            name: routeName(permissions, vra.to) ?? vra.name,
                            component: vra.component
                        })
                    }
                })
            }
        }
    }

    // Routing Pages
    routePages.map((vRoutePages) => {
        if (vRoutePages.items) {
            vRoutePages.items.map((vRoutePagesItems) => {
                resultRoute(vRoutePagesItems, vRoutePagesItems.actions)
            })
        } else {
            resultRoute(vRoutePages, vRoutePages.actions)
        }
    })

    // Routing Privileges
    if (roles.some((role) => role == 'developer')) {
        routePrivileges.map((vRoutePrivileges) => {
            if (vRoutePrivileges.items) {
                vRoutePrivileges.items.map((vRoutePrivilegesItems) => {
                    resultRoute(vRoutePrivilegesItems, vRoutePrivilegesItems.actions)
                })
            }
        })
    }

    router.sort(function(a, b) {
        return a.path.localeCompare(b.path)
    });

    return router;
}

export default routes
