import React from 'react'
import { CCardHeader } from '@coreui/react'

const RCardHeader = ({ children, ...props }) => {
    return (
        <CCardHeader className='p-2' {...props}>{children}</CCardHeader>
    )
}

export default RCardHeader
