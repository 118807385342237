import React from 'react'
import { CFormCheck, CFormFeedback, CFormLabel, CInputGroup } from '@coreui/react'

const RFormInputRadio = ({ value, setValue, invalid }) => {
    const { id, label, size, type, value: thisValue, required, requiredLabel, check, ...props } = value

    const setRequiredLabel = requiredLabel ?? true
    const setLabel = label

    return (
        <>
            <CFormLabel className='mb-0' htmlFor={id ?? ''}>{setLabel}{required && setRequiredLabel ? <span className="text-danger"> *</span> : ''}</CFormLabel>
            {check && check.length > 0
            ?
            <>
                <div className='d-flex align-items-center' style={{ height: 31 }}>
                    {check.map((vc, ic) => (
                        <CFormCheck
                            key={ic}
                            inline
                            type="radio"
                            value={vc.value}
                            id={id+ic}
                            className='mb-0'
                            invalid={invalid[id] ? true : false }
                            label={vc.label}
                            onChange={(e) => {
                                setValue({
                                    ...value,
                                    value: e.target.value
                                })
                            }}
                            checked={thisValue == vc.value ? true : false}
                            />
                    ))}
                </div>
                <CFormFeedback invalid>{invalid[id]}</CFormFeedback>
            </>
            :
            <div>Pilihan checkbox tidak ada</div>
            }
        </>
    )
}

export default RFormInputRadio
