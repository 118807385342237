import React from 'react'
import { CHeaderNav, CNavItem, CNavLink } from '@coreui/react'
import * as FaIcons from 'react-icons/fa';

const HeaderInfo = () => {
  return (
    <CHeaderNav className='me-3'>
        <CNavItem>
            <CNavLink href="#">
                <FaIcons.FaBell className='icon icon-lg text-danger' />
            </CNavLink>
        </CNavItem>
    </CHeaderNav>
  )
}

export default HeaderInfo
