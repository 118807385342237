import React from 'react'
import { CFooter } from '@coreui/react'

const Footer = () => {
    return (
        <CFooter className="mt-4 px-2 py-1 fs-7">
            <div>
                <span className="ms-1">Copyright &copy; 2022 CV BINPRO BINTANG PROPERTI</span>
            </div>

            <div className="ms-auto">
                <span className="me-1">Powered by Self</span>
            </div>
        </CFooter>
    )
}

export default React.memo(Footer)
